import { useRef, useEffect, useState, useCallback } from "react";
import { useMediaQuery } from "react-responsive";

export const usePromise = (
  promise,
  onSuccess = null,
  onError = null,
  onClear = null
) => {
  const [infos, setInfos] = useState({
    loading: true,
    error: null,
    data: null,
  });
  const isMounted = useRef(true);
  const promiseCallback = useCallback(promise, []);
  const onSuccessCallback = useCallback(onSuccess, []);
  const onErrorCallback = useCallback(onError, []);
  const onClearCallback = useCallback(onClear, []);

  useEffect(() => {
    promiseCallback()
      .then((data) => {
        if (!isMounted.current) {
          return;
        }
        setInfos({ data, loading: false, error: null });
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
      })
      .catch((error) => {
        if (!isMounted.current) {
          return;
        }
        setInfos({ data: null, loading: false, error });
        if (onErrorCallback) {
          onErrorCallback(error);
        }
      });

    return () => {
      isMounted.current = false;
      if (onClearCallback) {
        onClearCallback();
      }
    };
  }, [promiseCallback, onSuccessCallback, onErrorCallback, onClearCallback]);

  return [infos.loading, infos.data, infos.error];
};

function bestTime(sent, received) {
  if (!sent) return 1000;
  const diff = received ? received.getTime() - sent.getTime() : 3000;
  if (received && received < sent) {
    if (diff < -10000) return 10000;
    if (diff < -5000) return 5000;
    return 3000;
  }
  const max = Math.max(1000, diff);
  return Math.min(max, 3000);
}

export const usePolling = (promise, options = {}) => {
  const defaults = {
    stopCondition: null,
    stopOnError: false,
    interval: 1000,
  };
  const params = { ...defaults, ...options };

  const [infos, setInfos] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const requestRef = useRef({
    lastResponse: null,
    lastSent: null,
    lastReceived: null,
    pendingRequests: 0,
  });
  const intervalRef = useRef(0);

  useEffect(() => {
    const fetch = () => {
      if (requestRef.current.pendingRequests > 0) return;
      const timeout = bestTime(
        requestRef.current.lastSent,
        requestRef.current.lastReceived
      );
      requestRef.current.lastSent = new Date();
      requestRef.current.pendingRequests += 1;
      promise(timeout * 1.1)
        .then((data) => {
          if (!intervalRef.current) return;
          requestRef.current.lastReceived = new Date();
          const newInfos = { loading: false, data, error: null };
          requestRef.current.lastResponse = data;

          const hash = JSON.stringify({
            loading: infos.loading,
            error: infos.error,
            data: infos.data?.hash || infos.data,
          });
          const newHash = JSON.stringify({
            loading: newInfos.loading,
            error: newInfos.error,
            data: newInfos.data?.hash || newInfos.data,
          });

          if (intervalRef.current && hash !== newHash) {
            setInfos(newInfos);
          }
        })
        .catch((error) => {
          if (!intervalRef.current) return;
          if (error.name === "AbortError" && error.code === 20) {
            setInfos({
              loading: false,
              data: requestRef.current.lastResponse,
              error: null,
              aborted: true,
            });
            return;
          }
          const newInfos = { loading: false, data: null, error };
          if (JSON.stringify(newInfos) !== JSON.stringify(infos)) {
            setInfos(newInfos);
          }
          if (params.stopOnError) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
        })
        .finally(() => {
          if (!requestRef.current) return;
          requestRef.current.pendingRequests -= 1;

          if (params.stopCondition && params.stopCondition()) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
        });
    };

    fetch();
    intervalRef.current = setInterval(fetch, params.interval);

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, [promise, infos, params]);

  return [infos.loading, infos.data, infos.error];
};

export const usePaginated = (promise) => {
  const [datas, setDatas] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const promiseCallback = useCallback(promise, []);
  const nextPage = () => setPage(page + 1);

  useEffect(() => {
    promiseCallback(page)
      .then((res) => {
        const {
          "hydra:member": newDatas,
          "hydra:totalItems": newTotalCount,
        } = res;
        setDatas((oldDatas) => [...oldDatas, ...newDatas]);
        setTotalCount(newTotalCount);
        setError(null);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  }, [page, promiseCallback]);

  return [[datas, totalCount], loading, error, nextPage];
};

export const useIsLarge = () => {
  return useMediaQuery({ query: "(min-width:960px)" });
};

export const useIsMedium = () => {
  return useMediaQuery({ query: "(min-width:768px)" });
};
