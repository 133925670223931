import React from "react";
import PropTypes from "prop-types";
import Paper from "./Paper";
import styles from "./orderdetails.module.css";
import {
  TOTAL,
  UNKNOWN_PRODUCT,
  PROMOTION_DISCOUNT,
  WITH_TAXES,
  BILLED_AMOUNT,
  BILLED_ON_CARD,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import { money, number, date } from "../../services/formatters";
import { computeTaxes, computeTotal } from "../../services/helpers/order";

const OrderDetails = ({
  translate,
  locale,
  title,
  products,
  paymentInfo,
  showTotal = false,
  data,
}) => {
  const moneyFormatter = money(locale);
  const formatter = number(locale);
  const dateFormatter = date(locale);
  const total = computeTotal(products);
  const taxes = computeTaxes(products);

  const footer =
    paymentInfo && paymentInfo.length ? (
      <div className={styles.paymentInfo}>
        {paymentInfo.map((payment) => (
          <div key={payment.id}>
            <span>{`${translate(BILLED_AMOUNT)} `}</span>
            <strong>{moneyFormatter.format(payment.amount)}</strong>
            <span>{` ${translate(BILLED_ON_CARD)} `}</span>
            <strong>{payment.card.pan}</strong>
          </div>
        ))}
      </div>
    ) : null;

  return (
    <Paper title={title} titleOutside footer={footer} footerOutside>
      <div className={styles.products}>
        {products.map((product) => (
          <div className={styles.product} key={product.id}>
            <strong className={styles["product-quantity"]}>
              {product.quantity}
            </strong>
            <span className={styles["product-name"]}>
              {product.name ? product.name : translate(UNKNOWN_PRODUCT)}
              {product.initialPrice && product.initialPrice > product.price && (
                <>
                  <br />
                  {translate(PROMOTION_DISCOUNT)}
                </>
              )}
            </span>
            {data === "price" &&
              (product.initialPrice && product.initialPrice > product.price ? (
                <strong className={styles["product-price"]}>
                  {moneyFormatter.format(product.initialPrice)}
                  <br />
                  <span className={styles.productPriceReduction}>
                    -
                    {moneyFormatter.format(
                      product.initialPrice - product.price
                    )}
                  </span>
                </strong>
              ) : (
                <strong className={styles["product-price"]}>
                  {moneyFormatter.format(product.price)}
                </strong>
              ))}
            {data === "date" && (
              <strong className={styles["product-price"]}>
                {dateFormatter.format(new Date(product.expireAt))}
              </strong>
            )}
          </div>
        ))}
      </div>
      {showTotal && (
        <div>
          <br />
          <div className={styles.products}>
            {Object.keys(taxes).map((tax) => (
              <div key={tax} className={[styles.product, styles.tax].join(" ")}>
                <span className={styles["product-name"]}>
                  {translate(WITH_TAXES, { tax: formatter.format(tax * 100) })}
                </span>
                <span className={styles["text-align-right"]}>
                  {moneyFormatter.format(taxes[tax])}
                </span>
              </div>
            ))}
            <div className={[styles.product, styles.total].join(" ")}>
              <strong className={styles["product-name"]}>
                {translate(TOTAL)}
              </strong>
              <strong className={styles["text-align-right"]}>
                {moneyFormatter.format(total)}
              </strong>
            </div>
          </div>
        </div>
      )}
    </Paper>
  );
};

OrderDetails.propTypes = {
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOf(["date", "price"]),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      quantity: PropTypes.number.isRequired,
      name: PropTypes.string,
      price: PropTypes.number.isRequired,
      tax: PropTypes.number.isRequired,
    })
  ).isRequired,
  showTotal: PropTypes.bool,
  paymentInfo: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.shape({
        pan: PropTypes.string,
      }),
      amount: PropTypes.number,
    })
  ),
};

OrderDetails.defaultProps = {
  showTotal: false,
  data: "price",
  paymentInfo: null,
};

export default translated(OrderDetails);
