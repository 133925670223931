/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Favorite = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.6098 8.39378L15.2195 12.8497L16.2927 19.171C16.2927 19.2746 16.2927 19.3782 16.2927 19.3782C16.2927 19.6891 16.1951 20 15.8049 20C15.6098 20 15.5122 19.8964 15.3171 19.8964L9.95122 16.8912L4.58537 19.8964C4.39024 20 4.29268 20 4.09756 20C3.70732 20 3.60976 19.6891 3.60976 19.3782C3.60976 19.2746 3.60976 19.171 3.60976 19.171L4.68293 12.8497L0.292683 8.39378C0.195122 8.29016 0 8.0829 0 7.87565C0 7.46114 0.390244 7.25389 0.682927 7.25389L6.73171 6.32124L9.46342 0.518135C9.46342 0.310881 9.65854 0 9.95122 0C10.2439 0 10.439 0.310881 10.5366 0.518135L13.2683 6.32124L19.3171 7.25389C19.6098 7.25389 20 7.46114 20 7.87565C19.9024 8.0829 19.8049 8.29016 19.6098 8.39378ZM17.2683 8.60104L12.1951 7.77202L9.95122 2.90155L7.70732 7.77202L2.63415 8.60104L6.34146 12.3316L5.46341 17.7202L9.95122 15.2332L14.439 17.7202L13.6585 12.3316L17.2683 8.60104Z"
      fill="#2A2A72"
    />
  </svg>
);

Favorite.propTypes = {
  className: PropTypes.string,
};

Favorite.defaultProps = {
  className: null,
};

export default Favorite;
