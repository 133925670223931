import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import {
  Guest,
  Client,
  Deliverer,
  Restaurateur,
  AnyRole,
} from "../common/Role";
import Page, { PageContent } from "../common/Page";
import GuestFridge from "./GuestFridge";
import ClientFridge from "./ClientFridge";
import DelivererFridge from "./DelivererFridge";

const Fridge = ({ fridge, setFridge, restaurateur }) => {
  useEffect(() => {
    setFridge(fridge);
  }, [setFridge, fridge]);

  return (
    <Page>
      <PageContent>
        <Guest>
          <GuestFridge restaurateur={restaurateur} fridge={fridge} />
        </Guest>
        <Client>
          <ClientFridge restaurateur={restaurateur} fridge={fridge} />
        </Client>
        <AnyRole roles={[Deliverer, Restaurateur]}>
          <DelivererFridge restaurateur={restaurateur} fridge={fridge} />
        </AnyRole>
      </PageContent>
    </Page>
  );
};

Fridge.propTypes = {
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setFridge: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setFridge: dispatch.fridge.setData,
});

export default connect(null, mapDispatchToProps)(Fridge);
