import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import NavBar from "../navbar/NavBar";
import styles from "./main.module.css";
import Footer from "../footer/Footer";
import Menu from "../menu/Menu";
import Burger from "../burger/Burger";

const Main = ({ children, restaurateur, theme, logo }) => {
  const navBarLogo = logo || restaurateur?.logo;
  const navBarBgColor = theme?.headerBackgroundColor || restaurateur?.color;
  const navBarTextColor = theme?.headerBackgroundColor || restaurateur?.color;

  const style = {};

  if (theme.bodyBackgroundColor) {
    style.backgroundColor = theme.bodyBackgroundColor;
  }

  return (
    <div className={styles.main} style={style}>
      <NavBar
        logo={navBarLogo}
        bgColor={navBarBgColor}
        color={navBarTextColor}
      />
      <div className={styles.view}>
        {restaurateur && (
          <Burger menu={<Menu restaurateur={restaurateur} />}>
            {children}
          </Burger>
        )}
        {!restaurateur && <>{children}</>}
      </div>
      <Footer />
    </div>
  );
};

Main.defaultProps = {
  restaurateur: null,
  theme: null,
  logo: null,
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    logo: PropTypes.string,
    color: PropTypes.string,
  }),
  theme: PropTypes.shape({
    primaryColor: PropTypes.string,
    headerBackgroundColor: PropTypes.string,
    bodyBackgroundColor: PropTypes.string,
  }),
  logo: PropTypes.string,
};

const mapStateToProps = (state) => ({
  logo: state.fridge.data?.logo,
  theme: state.theme,
});

export default connect(mapStateToProps)(Main);
