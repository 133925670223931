import React from "react";
import * as PropTypes from "prop-types";

const Warning = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
  </svg>
);

Warning.propTypes = {
  className: PropTypes.string,
};

Warning.defaultProps = {
  className: null,
};

export default Warning;
