import React, { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import AdyenCheckout from "@adyen/adyen-web";
import Page, { PageContent } from "../common/Page";
import translated from "../../services/i18n/translated";
import client from "../../services/client";
import "@adyen/adyen-web/dist/adyen.css";
import { ADD, ADD_CARD_ERROR } from "../../services/i18n/messages";
import "./cardsadd.scss";
import redirect from "../../services/routing/redirect";
import { adyenClientKey, adyenEnv } from "../../config";

const CardsAddAdyen = ({ translate, locale, restaurateur: { slug } }) => {
  const dropinContainer = useRef();
  const [paymentMethods, setPaymentMethods] = useState({});
  useEffect(() => {
    (async () =>
      setPaymentMethods(await client.getAvailablePaymentMethods(slug)))();
  }, [setPaymentMethods, slug]);

  useEffect(() => {
    const init = async () => {
      const translations = {
        "fr-FR": {
          payButton: translate(ADD),
        },
      };
      const configuration = {
        paymentMethodsResponse: paymentMethods, // The `/paymentMethods` response from the server.
        clientKey: adyenClientKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
        translations,
        locale,
        environment: adyenEnv,
        removePaymentMethods: ["bcmc"],
        onSubmit: (state, dropin) => {
          // Your function calling your server to make the `/payments` request
          client
            .tokenize(state.data)
            .then((response) => {
              if (response.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.action);
              } else if (
                response.resultCode === "Authorised" &&
                response.additionalData
              ) {
                client
                  .addCard(
                    response.additionalData[
                      "recurring.recurringDetailReference"
                    ]
                  )
                  .then(() => {
                    redirect(`/${slug}/cards/new`);
                  })
                  .catch((err) => {
                    if (err.response.status === 500) {
                      dropin.setStatus("error", {
                        message: translate(ADD_CARD_ERROR),
                      });
                    } else {
                      dropin.setStatus("error", { message: err.message });
                    }
                  });
              } else {
                dropin.setStatus("error", {
                  message: translate(ADD_CARD_ERROR),
                });
              }
            })
            .catch((error) => {
              dropin.setStatus("error", { message: translate(ADD_CARD_ERROR) });
            });
        },
        // A revoir lors de l'implémentation du 3D secure
        onAdditionalDetails: (state, dropin) => {
          // Your function calling your server to make a `/payments/details` request
          client
            .makePaymentDetailsCall(state.data)
            .then((response) => {
              if (response.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.action);
              } else if (response.additionalData) {
                client
                  .addCard(
                    response.additionalData[
                      "recurring.recurringDetailReference"
                    ]
                  )
                  .then(() => {
                    redirect(`/${slug}/cards/new`);
                  })
                  .catch((err) => {
                    if (err.response.status === 500) {
                      dropin.setStatus("error", {
                        message: translate(ADD_CARD_ERROR),
                      });
                    } else {
                      dropin.setStatus("error", { message: err.message });
                    }
                  });
              } else {
                dropin.setStatus("error", {
                  message: translate(ADD_CARD_ERROR),
                });
              }
            })
            .catch((error) => {
              throw Error(error);
            });
        },
        paymentMethodsConfiguration: {
          card: {
            // Example optional configuration for Cards
            hasHolderName: false,
            enableStoreDetails: false,
            hideCVC: false, // Change this to true to hide the CVC field for stored cards
            name: "Credit or debit card",
            showBrandIcon: false,
          },
        },
      };
      if (Object.keys(paymentMethods).length !== 0) {
        const checkout = new AdyenCheckout(configuration);
        checkout.create("dropin").mount(dropinContainer.current);
      }
    };
    init();
  }, [paymentMethods, translate, slug, locale]);

  return (
    <Page>
      <PageContent>
        <div ref={dropinContainer} />
      </PageContent>
    </Page>
  );
};

CardsAddAdyen.propTypes = {
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default translated(CardsAddAdyen);
