/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const PassRestaurant = ({ id, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    width="26"
    height="17"
    viewBox="0 0 350 120"
    xmlSpace="preserve"
    className={className}
  >
    <style>{".prefix__st0{fill:#c6313b}.prefix__st1{fill:#2f2977}"}</style>
    <path
      className="prefix__st0"
      d="M266.7 5c-7.3 2.6-10.4 7.9-10.4 15s3 10.7 10.6 12.7c14.6 3.5 14.4 4 14.4 7 0 2.3-.9 3.3-4.1 4.3-7.2 2-20.3-2.3-20.3-2.3l-.7 10.8c12 2.7 26.1 1.5 30.6.1 6.5-2.2 10.1-7.2 10.1-14.3 0-7.6-3.8-11.6-13.5-14.3-11.9-3.1-12.7-3.5-13-5.8-.3-1.2.5-2.9 1.6-3.7 2.6-1.9 8.1-2 13.6-1.1 3.9.6 7.2 1.7 7.2 1.7l1.4-10.9s-5.5-.9-11.4-1.2c-6.9-.4-13.5 1.1-16.1 2zM313.7 2.9c-8.4 2.9-11.4 7-11.4 15s3.5 12 12.2 14.2c16.7 3.9 16.6 4.4 16.6 7.8 0 2.6-1.1 3.6-4.7 4.8-8.2 2.2-23.3-2.5-23.3-2.5l-.8 12c13.7 3 29.9 1.7 35.1.1 7.5-2.5 11.6-8.1 11.6-16 0-8.5-4.4-13-15.5-16-13.6-3.5-14.6-2.7-15-5.3-.4-1.3.5-3.3 1.9-4.2 2.9-2.1 9.3-2.2 15.6-1.2 4.4.7 8.3 1.9 8.3 1.9l1.5-12.2s-7.4-.8-14.2-.8c-8.9-.1-14.8 1.4-17.9 2.4zM243.1 7.9c-4.5-2.2-15.4-4.2-26.2.5l.7 8.2s3.7-.9 6.7-1.2c8.5-.8 12.1.9 12.1 6.3v2.8h-4.9c-15 0-19.1 5.9-19.1 13.9 0 9.8 5.3 14 17.4 14h20.6V35c.1-20.3-1-24.2-7.3-27.1zm-6.6 36.6h-5.2c-4.3 0-9.1-.8-9.1-6 0-4.7 3.5-5.9 8.1-5.9h6.2v11.9zM185.2 8.2l-6.9 1.4v40.5h10.5v-14h5.2c11.3 0 17.1-5.1 17.1-15C211 8.7 203.2 5 185.2 8.2zm10.5 19.9c-2.2.1-7 0-7 0v-12h7c1.9 0 5.8 1.4 5.8 6 .1 4.9-3.9 5.9-5.8 6z"
    />
    <path
      className="prefix__st1"
      d="M1.3 85.4v34.8l8.5-.3 9-.4.1-22.8h5.2l14.9 21h18.8L41 92.7l4-4.1c9.3-9.7 8.5-21.8-2.1-30.9-5.6-4.8-9.5-7.2-29.2-7.2H1.3v34.9zm30.8-18.7c6.4 7.6 1.4 18.4-8.6 18.4h-4.8V62.8s3.1-.2 5.7 0c3.6.3 5.7 1.4 7.7 3.9zM63 84.4v32.1l32.6-.7v-11.5H78V88l17.7-.3V76.5l-17.8-.2V65l9 .2 8.8.2V53.7L63 52.2v32.2zM112.7 55.6c-5.9 2.9-8.9 8.7-8.9 16 0 8 3 12.6 12.2 18.4 7.7 4.8 9.4 7.9 6.2 11.2-2.1 2.1-4.7 1.7-10.8-1.5-3-1.6-5.8-3.3-5.8-3.3l-1.5 11.4s2.2 2.3 4.6 3.7c3.3 2.2 6.9 3.2 10.3 3.2 9.3 0 17.1-7.2 17.1-18.3 0-7.9-2.6-12.8-11.3-18.1-7.3-4.5-9.3-7.3-7-10.1 2-2.4 5.5-2.2 10.1.7 2.2 1.4 3.8 2.7 3.8 2.7l3.8-9.7s-7.9-8.3-14.7-8.3c-3.7.1-5.9.9-8.1 2zM139.9 66.5h11.6v45.4h11.6V66.5h11.6V56l-34.9-.8v11.3zM208.8 109.6l-.5-19.6c-.3-14.1-1-20.6-2.3-23.5-4.1-9.1-12.3-12.6-19.3-8.2-7 4.3-8.4 9.4-8.4 32.3v20.1h10.5V93.3h9.3v16.3h10.7zm-10.9-25.8h-10v-9s.1-8 5-8 4.9 8 4.9 8v9zM214.4 74.1c0 18 1.4 26.1 5.4 30.7 5.7 6.9 14.7 5 19.1-3.8 2.1-4.3 2.3-7.2 2.3-23.8V58.3h-8l-.4 18.7c-.2 15.6-.7 18.9-2.3 20.5-1.6 1.6-2.3 1.7-3.7.5-1.5-1.2-2.6-4.7-2.6-20.6v-19h-9.8v15.7zM266.9 88s1.5-1.7 2.3-2.7c5-6.2 3.7-17.5-2.6-22.7-3-2.6-4.7-3-11.1-3H248v47.7h8.2V91h2.8l7.4 15h9.2l-8.7-18zm-7.3-4.6h-3.3V67.1h3.3s4.8 1.7 4.8 8.2c0 6.6-4.8 8.1-4.8 8.1zM297.2 66.8c-3.3-7.1-8-8.9-13.2-5.4-4.9 3.5-5.6 8.2-5.6 27.2v17.5h7.2V90.9h6.8v14h7V88.3c0-13.8-.5-17.4-2.2-21.5zm-4.8 16h-6.8v-7.4s.4-6.7 3.6-6.7 3.2 6.7 3.2 6.7v7.4zM304 60.9v42.9h6.4l.1-20.6 9.9 19.9h3.3V61.9H318V82l-9.4-21.1H304zM325.8 69.4h6.3v33.2h6.4V69.4h6.3v-6.9l-19-.5v7.4z"
    />
  </svg>
);

PassRestaurant.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
};

PassRestaurant.defaultProps = {
  className: null,
  id: null,
};

export default PassRestaurant;
