import React from "react";
import * as PropTypes from "prop-types";
import styles from "./linkbutton.module.css";
import Chevron from "../../icons/Chevron";
import Nav from "../../icons/Nav";
import redirect from "../../../../services/routing/redirect";

const LinkButton = ({ href, children, onClicked, icon }) => (
  <button
    type="button"
    onClick={() => {
      redirect(href);
      onClicked();
    }}
    className={styles.button}
  >
    <div className={styles.icon}>{icon}</div>
    <span className={styles.text}>{children}</span>
    <Chevron className={styles.chevron} />
  </button>
);

LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClicked: PropTypes.func,
  icon: PropTypes.node,
};

LinkButton.defaultProps = {
  onClicked: () => {},
  icon: <Nav />,
};

export default LinkButton;
