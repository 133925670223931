import React, { useState } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import linkButtonStyles from "./linkbutton.module.css";
import styles from "./logoutbutton.module.css";
import client from "../../../../services/client";
import Loading from "../../icons/Loading";
import redirect from "../../../../services/routing/redirect";
import Chevron from "../../icons/Chevron";
import Logout from "../../icons/Logout";

const LogoutButton = ({ children, logout, restaurateur: { slug } }) => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    return client
      .logout()
      .then(logout)
      .then(() => redirect(`/${slug}/fridge`));
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={linkButtonStyles.button}
      disabled={loading}
    >
      <div className={linkButtonStyles.icon}>
        {!loading && <Logout />}
        {loading && <Loading className={styles.loading} />}
      </div>
      {children}
      <Chevron className={linkButtonStyles.chevron} />
    </button>
  );
};

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatch = (dispatch) => ({
  logout: dispatch.user.logout,
});

export default connect(null, mapDispatch)(LogoutButton);
