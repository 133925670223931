import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "../services/store";
import Router from "./router/Router";
import ErrorBoundary from "./ErrorBoundary";

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);

export default App;
