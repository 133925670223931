import React from "react";
import * as PropTypes from "prop-types";
import styles from "./radiobutton.module.css";
import RadioChecked from "../../icons/RadioChecked";
import Radio from "../../icons/Radio";

const RadioButton = ({ children, className, checked, ...rest }) => (
  <button
    type="button"
    className={[styles.button, className].join(" ")}
    {...rest}
  >
    {children}
    {checked ? (
      <RadioChecked className={styles.radio} />
    ) : (
      <Radio className={styles.radio} />
    )}
  </button>
);

RadioButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
};

RadioButton.defaultProps = {
  className: "",
  checked: false,
};

export default RadioButton;
