import React from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { changeLocale } from "../../services/i18n/actions";
import ListView from "../common/list/ListView";
import locales from "../../services/i18n/locales";
import RadioButton from "../common/list/buttons/RadioButton";
import * as flags from "./flags";
import styles from "./langs.module.css";
import translated from "../../services/i18n/translated";
import { LANGS } from "../../services/i18n/messages";
import Page, { PageContent } from "../common/Page";

const Langs = ({ translate, locale, onLocaleClick }) => (
  <Page>
    <PageContent>
      <ListView title={translate(LANGS)}>
        {Object.keys(locales).map((lang) => {
          const Flag = flags[lang];
          return (
            <RadioButton
              checked={locale === lang}
              className={styles.button}
              key={lang}
              onClick={onLocaleClick(lang)}
            >
              <Flag className={styles.flag} />
              {translate(lang)}
            </RadioButton>
          );
        })}
      </ListView>
    </PageContent>
  </Page>
);

Langs.propTypes = {
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  onLocaleClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.polyglot.locale,
});

const mapDispatchToProps = (dispatch) => ({
  onLocaleClick: (value) => () => dispatch(changeLocale(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(translated(Langs));
