import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "../common/Paper";
import Form from "../form/Form";
import { PasswordInput } from "../form/Input";
import SubmitButton from "../form/SubmitButton";
import client from "../../services/client";
import {
  FORGOT_PASSWORD_TITLE,
  SUBMIT,
  REGISTER_ACTION,
  PASSWORD,
  LOGIN_ACTION,
  FORGOT_PASSWORD_SUCCESS,
} from "../../services/i18n/messages";
import BigText from "../common/BigText";
import translated from "../../services/i18n/translated";
import BottomContainer from "../common/BottomContainer";
import RegisterButton from "../common/buttons/RegisterButton";
import Page, { PageContent } from "../common/Page";
import styles from "./forgotpassword.module.css";
import LoginButton from "../common/buttons/LoginButton";

const ForgotPasswordStep2 = ({ translate, token, restaurateur }) => {
  const [isSubmitted, setSubmitted] = useState(false);

  if (isSubmitted) {
    return (
      <Page>
        <PageContent>
          <BigText>{translate(FORGOT_PASSWORD_SUCCESS)}</BigText>
        </PageContent>
        <BottomContainer>
          <LoginButton restaurateur={restaurateur} primary>
            {translate(LOGIN_ACTION)}
          </LoginButton>
        </BottomContainer>
      </Page>
    );
  }

  return (
    <Page>
      <PageContent>
        <Form
          initialValues={{ password: "" }}
          validationRules={{ password: ["required"] }}
          sendRequest={({ password }) => client.resetPassword(token, password)}
          onSuccess={() => setSubmitted(true)}
          className={styles.form}
        >
          <Paper
            title={translate(FORGOT_PASSWORD_TITLE)}
            titleOutside
            transparent
          >
            <PasswordInput
              name="password"
              placeholder={translate(PASSWORD)}
              required
            />
            <SubmitButton primary>{translate(SUBMIT)}</SubmitButton>
          </Paper>
        </Form>
      </PageContent>
      <BottomContainer>
        <RegisterButton restaurateur={restaurateur}>
          {translate(REGISTER_ACTION)}
        </RegisterButton>
      </BottomContainer>
    </Page>
  );
};

ForgotPasswordStep2.propTypes = {
  translate: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default translated(ForgotPasswordStep2);
