/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Radio = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

Radio.propTypes = {
  className: PropTypes.string,
};

Radio.defaultProps = {
  className: null,
};

export default Radio;
