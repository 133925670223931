export default {
  state: {
    restaurateurSlug: null,
    slug: null,
  },
  reducers: {
    init: (state, { slug, restaurateurSlug }) => ({
      ...state,
      slug: slug || state.slug,
      restaurateurSlug: restaurateurSlug || state.restaurateurSlug,
    }),
    clear: (state) => ({
      restaurateurSlug: null,
      slug: null,
    }),
  },
};
