const defaultTheme = {
  primaryColor: "#2A2A72",
  bodyBackgroundColor: "#F4F4F7",
  headerBackgroundColor: null,
  enableHomePaymentMethodsHelp: true,
};

export default {
  state: defaultTheme,
  reducers: {
    init: (state, fridge) => {
      if (!fridge) return state;
      return {
        ...defaultTheme,
        ...{
          primaryColor: fridge.themePrimaryColor || defaultTheme.primaryColor,
          headerBackgroundColor:
            fridge.themeHeaderBackgroundColor ||
            defaultTheme.headerBackgroundColor,
          bodyBackgroundColor:
            fridge.themeBodyBackgroundColor || defaultTheme.bodyBackgroundColor,
          enableHomePaymentMethodsHelp:
            fridge.themeEnableHomePaymentMethodsHelp,
        },
      };
    },
  },
};
