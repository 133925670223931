export const INITIALIZED = "initialized";
export const DOOR_OPENED = "door_opened";
export const DOOR_CLOSED = "door_closed";

export const TYPE_BUY = "buy";
export const TYPE_NFC_BUY = "nfc_buy";
export const TYPE_POS_BUY = "pos_buy";
export const TYPE_REMOVE = "remove";
export const TYPE_DELIVER = "deliver";
export const TYPE_MAINTENANCE = "maintenance";

export const BUY_TYPES = [TYPE_BUY, TYPE_NFC_BUY, TYPE_POS_BUY];

export const computeTotal = (products) =>
  products.reduce((agg, product) => agg + parseFloat(product.price), 0);

export const computeTaxes = (products) =>
  products.reduce(
    (agg, product) => ({
      ...agg,
      [product.tax]: (agg[product.tax] || 0) + product.tax * product.price,
    }),
    {}
  );

export const orderLinesToProductList = (lines) =>
  lines.map((line) => ({
    id: line.id,
    name: line.productName,
    price: line.price,
    initialPrice: line.initialPrice,
    quantity: line.quantity,
    tax: line.tax,
  }));
