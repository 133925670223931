import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "../common/Paper";
import Form from "../form/Form";
import SubmitButton from "../form/SubmitButton";
import {
  FRIDGE_FORM_TITLE,
  OPEN_FRIDGE,
  FRIDGE_NAME,
  LOGIN_ACTION,
  REGISTER_ACTION,
  FRIDGE_FORM_DELIVERY_TITLE,
  DELIVER_FRIDGE,
} from "../../services/i18n/messages";
import { TextInput } from "../form/Input";
import translated from "../../services/i18n/translated";
import RegisterButton from "../common/buttons/RegisterButton";
import LoginButton from "../common/buttons/LoginButton";
import BottomContainer from "../common/BottomContainer";
import Page, { PageContent } from "../common/Page";
import styles from "./fridgeform.module.css";
import { Deliverer, Client, Guest, Restaurateur } from "../common/Role";
import redirect from "../../services/routing/redirect";
import RecentFridgeList from "../recent-fridges/RecentFridgeList";

const FridgeForm = ({ translate, user, setFridge, restaurateur }) => {
  useEffect(() => {
    setFridge(null);
  }, [setFridge]);
  return (
    <Page>
      <PageContent>
        <Form
          initialValues={{ fridge: "" }}
          validationRules={{ fridge: ["required"] }}
          sendRequest={(values) =>
            Promise.resolve(values.fridge.toLowerCase().trim())
          }
          onSuccess={(slug) => redirect(`/${restaurateur.slug}/${slug}`)}
          className={styles.form}
        >
          <Guest>
            <Paper
              title={translate(FRIDGE_FORM_TITLE)}
              titleOutside
              transparent
            >
              <TextInput
                name="fridge"
                label={translate(FRIDGE_NAME)}
                autoCapitalize="none"
                required
              />
              <SubmitButton primary>{translate(OPEN_FRIDGE)}</SubmitButton>
            </Paper>
          </Guest>
          <Client>
            <Paper
              title={translate(FRIDGE_FORM_TITLE)}
              titleOutside
              transparent
              className={styles.paper}
            >
              <TextInput
                name="fridge"
                label={translate(FRIDGE_NAME)}
                autoCapitalize="none"
                required
              />
              <SubmitButton primary>{translate(OPEN_FRIDGE)}</SubmitButton>
            </Paper>
          </Client>
          <Deliverer>
            <Paper
              title={translate(FRIDGE_FORM_DELIVERY_TITLE)}
              titleOutside
              transparent
            >
              <TextInput
                name="fridge"
                label={translate(FRIDGE_NAME)}
                autoCapitalize="none"
              />
              <SubmitButton primary>{translate(DELIVER_FRIDGE)}</SubmitButton>
            </Paper>
          </Deliverer>
          <Restaurateur>
            <Paper
              title={translate(FRIDGE_FORM_TITLE)}
              titleOutside
              transparent
            >
              <TextInput
                name="fridge"
                label={translate(FRIDGE_NAME)}
                autoCapitalize="none"
                required
              />
              <SubmitButton primary>{translate(OPEN_FRIDGE)}</SubmitButton>
            </Paper>
          </Restaurateur>
        </Form>
        <Client>
          <RecentFridgeList
            restaurateur={restaurateur}
            className={styles.fridgeList}
            displayEmptyList={false}
          />
        </Client>
      </PageContent>
      {!user && (
        <BottomContainer>
          <RegisterButton restaurateur={restaurateur}>
            {translate(REGISTER_ACTION)}
          </RegisterButton>
          <LoginButton restaurateur={restaurateur}>
            {translate(LOGIN_ACTION)}
          </LoginButton>
        </BottomContainer>
      )}
    </Page>
  );
};

FridgeForm.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.shape(),
  setFridge: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

FridgeForm.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setFridge: dispatch.fridge.setData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translated(FridgeForm));
