import React from "react";
import * as PropTypes from "prop-types";
import translated from "../../services/i18n/translated";

import CardsAddPaygreen from "./CardsAddPaygreen";
import CardsAddAdyen from "./CardsAddAdyen";

const CardsAdd = ({ restaurateur, ...props }) => {
  return restaurateur.appPaymentProviders.includes("paygreen") ? (
    <CardsAddPaygreen restaurateur={restaurateur} {...props} />
  ) : (
    <CardsAddAdyen restaurateur={restaurateur} {...props} />
  );
};

CardsAdd.propTypes = {
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    appPaymentProviders: PropTypes.array.isRequired,
  }).isRequired,
};

export default translated(CardsAdd);
