import React from "react";
import * as PropTypes from "prop-types";
import styles from "./listview.module.scss";

const ListViewInner = ({ children, responsive }) => (
  <div
    className={[styles.inner, responsive ? styles.innerResponsive : null].join(
      " "
    )}
  >
    {Object.keys(children).map(
      (key) =>
        children[key] && (
          <div
            key={key}
            className={[
              styles.cell,
              responsive ? styles.cellResponsive : null,
            ].join(" ")}
          >
            {children[key]}
          </div>
        )
    )}
  </div>
);

ListViewInner.propTypes = {
  children: PropTypes.node.isRequired,
  responsive: PropTypes.bool,
};

ListViewInner.defaultProps = {
  responsive: false,
};

const ListView = ({ title, children, className, responsive }) => {
  const inception =
    children.length === children.filter((child) => Array.isArray(child)).length;
  return (
    <div className={[styles.list, className].join(" ")}>
      {title && <div className={styles.title}>{title}</div>}
      {inception ? (
        children.map((child, index) => (
          <ListViewInner
            key={index}
            title={index === 0 ? title : undefined}
            responsive={responsive}
          >
            {child}
          </ListViewInner>
        ))
      ) : (
        <ListViewInner title={title} responsive={responsive}>
          {children}
        </ListViewInner>
      )}
    </div>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
  responsive: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ListView.defaultProps = {
  title: undefined,
  className: null,
  responsive: false,
};

export default ListView;
