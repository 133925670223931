import React from "react";
import * as PropTypes from "prop-types";
import Button from "./Button";
import redirect from "../../../services/routing/redirect";

const RegisterButton = ({ restaurateur: { slug }, children, ...rest }) => (
  <Button onClick={() => redirect(`/${slug}/register`)} {...rest}>
    {children}
  </Button>
);

RegisterButton.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default RegisterButton;
