import React from "react";
import * as PropTypes from "prop-types";
import ListView from "../common/list/ListView";
import client from "../../services/client";
import BigText from "../common/BigText";
import {
  ERROR,
  LOAD_MORE,
  LOADING,
  MY_PURCHASES,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import LinkButton from "../common/list/buttons/LinkButton";
import { date, money } from "../../services/formatters";
import { computeTotal } from "../../services/helpers/order";
import Page, { PageContent } from "../common/Page";
import Purchase from "../common/icons/Purchase";
import PromiseButton from "../common/buttons/PromiseButton";
import { usePaginated } from "../hooks";

const Purchases = ({ restaurateur, translate, locale }) => {
  const [
    [orders, totalCount],
    loading,
    error,
    nextPage,
  ] = usePaginated((page) => client.getOrders(page));

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  const dateFormatter = date(locale);
  const moneyFormatter = money(locale);
  const actions = orders.map((order) => (
    <LinkButton
      key={order.id}
      href={`/${restaurateur.slug}/purchases/${order.id}/details`}
      icon={<Purchase />}
    >
      {[
        order.fridgeName,
        dateFormatter.format(new Date(order.date)),
        moneyFormatter.format(computeTotal(order.lines)),
      ].join(" - ")}
    </LinkButton>
  ));

  if (orders.length < totalCount) {
    actions.push(
      <PromiseButton
        key="action"
        primaryStyle
        promise={() => Promise.resolve(nextPage())}
      >
        {translate(LOAD_MORE)}
      </PromiseButton>
    );
  }

  return (
    <Page>
      <PageContent>
        <ListView title={translate(MY_PURCHASES)}>{actions}</ListView>
      </PageContent>
    </Page>
  );
};

Purchases.propTypes = {
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default translated(Purchases);
