import React from "react";
import PropTypes from "prop-types";
import Page, { PageContent } from "../common/Page";
import RecentFridgeList from "./RecentFridgeList";

const RecentFridges = ({ restaurateur }) => (
  <Page>
    <PageContent>
      <RecentFridgeList restaurateur={restaurateur} />
    </PageContent>
  </Page>
);

RecentFridges.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default RecentFridges;
