import React from "react";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";
import * as messages from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import customStyles from "./notification.module.scss";
import popupStyles from "./fridgepopup.module.scss";
import ban from "../../assets/ban.png";
import creditcard from "../../assets/credit-card.png";
import Close from "../common/icons/Close";

const styles = { ...popupStyles, ...customStyles };

const Notification = ({ translate, type, onClick, showClose }) => {
  const Values = {
    posWaiting: {
      imgSrc: creditcard,
      text: messages.POS_PAYMENT_WAITING_AUTHORIZATION,
      description: messages.POS_PAYMENT_WAITING_AUTHORIZATION_DESC,
    },
    posRefused: {
      imgSrc: ban,
      text: messages.POS_PAYMENT_AUTHORIZATION_FAILURE,
      description: messages.POS_PAYMENT_AUTHORIZATION_FAILURE_DESC,
    },
    nfcRefused: {
      imgSrc: ban,
      text: messages.NFC_PAYMENT_AUTHORIZATION_FAILURE,
      description: messages.NFC_PAYMENT_AUTHORIZATION_FAILURE_DESC,
    },
    closeFridge: {
      text: messages.NOTIF_CLOSE_FRIDGE_TITLE,
      description: messages.NOTIF_CLOSE_FRIDGE_DESC,
    },
  };

  const values = Values[type];

  return ReactDOM.createPortal(
    <div
      role="button"
      tabIndex={0}
      className={styles.wrapper}
      onClick={() => onClick && onClick()}
      onKeyPress={() => onClick && onClick()}
    >
      <div className={`${styles.popup} ${styles.notificationPopup}`}>
        <div className={styles.popupHeader}>
          <h3>{translate(values.text)}</h3>
        </div>
        <div className={`${styles.popupBody} ${styles.notificationBody}`}>
          {showClose && <Close className={styles.popupCloseIcon} />}
          {values.imgSrc && (
            <div className={styles.notificationPicture}>
              <img src={values.imgSrc} alt="Authorization status" />
            </div>
          )}
          <div className={styles.notificationDescription}>
            {translate(values.description)}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf([
    "posWaiting",
    "posRefused",
    "nfcRefused",
    "closeFridge",
  ]).isRequired,
  showClose: PropTypes.bool,
};

Notification.defaultTypes = {
  showClose: false,
};

export default translated(Notification);
