import { isEqual } from "lodash";
import { EventSourcePolyfill } from "event-source-polyfill";
import client from "../client";
import { mercureEndpoint } from "../../config";
import storage from "../storage";

// if no events since 60 seconds we will force a refresh
const REFRESH_CHECK = 60;

const contextSSE = {
  eventSource: null,
  lastEventAt: null,
  refreshCheckInterval: null,
};

const subscribeSSE = ({ dispatch }, slug) => {
  unsubscribeSSE();
  // eslint-disable-next-line no-console
  console.log("fridge subscribeSSE");

  const url = new URL(mercureEndpoint);
  url.searchParams.append("topic", `https://example.com/fridges/${slug}`);
  url.searchParams.append(
    "topic",
    `https://example.com/fridges/${slug}/medias/{id}`
  );

  const eventSource = new EventSourcePolyfill(url.toString(), {
    lastEventIdQueryParameterName: "Last-Event-Id",
    heartbeatTimeout: 3600 * 1000,
  });

  eventSource.onmessage = (event) => {
    const sseData = JSON.parse(event.data);

    // eslint-disable-next-line no-console
    console.debug("fridge mercure subscription event", sseData);

    if (sseData && sseData["@type"]) {
      if (sseData["@type"] === "Fridge") {
        contextSSE.lastEventAt = new Date();
        dispatch.fridge.setData(sseData);
        dispatch.fridge.setError(null);
      }

      if (sseData["@type"] === "FridgeMedia") {
        dispatch.fridge.refresh();
      }
    }
  };

  eventSource.onerror = (err) => {
    unsubscribeSSE();
    // eslint-disable-next-line no-console
    console.log("fridge mercure subscription error", err);
    setTimeout(() => dispatch.fridge.refresh(), 1000);
  };

  contextSSE.eventSource = eventSource;

  contextSSE.refreshCheckInterval = setInterval(() => {
    const now = new Date();
    if (
      !contextSSE.lastEventAt ||
      now.getTime() - contextSSE.lastEventAt.getTime() > REFRESH_CHECK * 1000
    ) {
      dispatch.fridge.refresh();
    }
  }, 1000 * REFRESH_CHECK);
};

const unsubscribeSSE = () => {
  // eslint-disable-next-line no-console
  console.log("fridge unsubscribeSSE");
  if (contextSSE.refreshCheckInterval) {
    clearInterval(contextSSE.refreshCheckInterval);
  }
  if (contextSSE.eventSource) {
    contextSSE.eventSource.close();
  }
};

export default {
  state: {
    slug: storage.getItem("fridgeSlug"),
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    setData: (state, data) => {
      if (!data) {
        unsubscribeSSE();
      }
      if (isEqual(state.data, data)) return state;
      // eslint-disable-next-line no-console
      console.log("new fridge data", state.data, data);
      return { ...state, data };
    },
    setSlug: (state, slug) => {
      storage.setItem("fridgeSlug", slug);
      return { ...state, slug };
    },
    setLoading: (state, loading) => ({ ...state, loading }),
    setError: (state, error) => {
      if (state.error === error) return state;
      return { ...state, error };
    },
  },
  effects: (dispatch) => ({
    setData(_, rootState) {
      if (!rootState.fridge.data) {
        dispatch.offers.setData(null);
      }
    },
    async load({ restaurateurSlug, slug, refresh }, rootState) {
      if (!refresh) {
        this.setSlug(slug);
        this.setLoading(true);
      }
      this.setError(null);
      try {
        subscribeSSE({ dispatch }, slug);
        const data = await client.fridge(restaurateurSlug, slug);
        this.setData(data);
        if (
          !refresh &&
          (rootState.offers.slug !== slug || !rootState.offers.data)
        ) {
          dispatch.offers.load();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("load fridge failed", error);
        this.setError(error);
      } finally {
        this.setLoading(false);
      }
    },
    refresh(_, rootState) {
      if (!rootState.fridge.data) return;

      dispatch.fridge.load({
        restaurateurSlug: rootState.fridge.data.restaurateur.slug,
        slug: rootState.fridge.data.slug,
        refresh: true,
      });
    },
  }),
};
