import React, { useState } from "react";
import * as PropTypes from "prop-types";
import ListView from "../common/list/ListView";
import CardButton from "../common/list/buttons/CardButton";
import client from "../../services/client";
import { usePromise } from "../hooks";
import translated from "../../services/i18n/translated";
import BigText from "../common/BigText";
import {
  ERROR,
  LOADING,
  ADD_PAYMENT_CARD,
  ADD_SECONDARY_PAYMENT_CARD,
  SECONDARY_CARD_CAN_BE_ADDED_LATER,
  FAVORITE_CARD,
  OTHER_CARDS,
  OPEN_FRIDGE,
} from "../../services/i18n/messages";
import Button from "../common/buttons/Button";
import ArrowUp from "../common/icons/ArrowUp";
import ArrowDown from "../common/icons/ArrowDown";
import styles from "./cardslist.module.css";
import Loading from "../common/icons/Loading";
import Page, { PageContent } from "../common/Page";
import redirect from "../../services/routing/redirect";
import { hasOneValidPrimaryCard } from "../../services/helpers/card";
import Appetiz from "../common/icons/Appetiz";
import PassRestaurant from "../common/icons/PassRestaurant";
import UpDejeuner from "../common/icons/UpDejeuner";
import Edenred from "../common/icons/Edenred";
import RestoFlash from "../common/icons/RestoFlash";
import Swile from "../common/icons/Swile";

const CardListView = ({
  restaurateur,
  buttons,
  translate,
  newCard,
  newCardError,
  primaryCardMissing,
}) => {
  const children = [];
  const [firstButton, ...restButtons] = buttons;
  const firstCardAdded = newCard && buttons.length >= 1;

  switch (buttons.length) {
    case 0:
      break;
    case 1:
      children.push([
        <div className={styles.head} key="favorite_card">
          {translate(FAVORITE_CARD)}
        </div>,
        firstButton,
      ]);
      break;
    default:
      children.push(
        [
          <div className={styles.head} key="favorite_card">
            {translate(FAVORITE_CARD)}
          </div>,
          firstButton,
        ],
        [
          <div className={styles.head} key="other_cards">
            {translate(OTHER_CARDS)}
          </div>,
          ...restButtons,
        ]
      );
      break;
  }

  return (
    <Page>
      <PageContent>
        <div className={styles.container}>
          {newCardError && (
            <div className={styles.block}>
              <BigText>{translate(ERROR)}</BigText>
            </div>
          )}
          {children.map((list, i) => (
            <div className={styles.block} key={i}>
              <ListView>{list}</ListView>
            </div>
          ))}
          {firstCardAdded && (
            <div className={styles.button}>
              <Button
                primary
                onClick={() => redirect(`/${restaurateur.slug}/fridge`)}
              >
                {translate(OPEN_FRIDGE)}
              </Button>
            </div>
          )}
          <div className={styles.button}>
            <Button
              primary={!firstCardAdded}
              key="add"
              onClick={() => redirect(`/${restaurateur.slug}/cards/add`)}
            >
              {primaryCardMissing && translate(ADD_PAYMENT_CARD)}
              {!primaryCardMissing && translate(ADD_SECONDARY_PAYMENT_CARD)}
            </Button>
            {primaryCardMissing && (
              <div className={styles.secondaryPaymentMethodsHint}>
                {translate(SECONDARY_CARD_CAN_BE_ADDED_LATER)}
              </div>
            )}
            {!primaryCardMissing && (
              <div className={styles.secondaryPaymentMethods}>
                <Appetiz />
                <PassRestaurant />
                <UpDejeuner />
                <Edenred />
                <RestoFlash />
                <Swile />
              </div>
            )}
          </div>
        </div>
      </PageContent>
    </Page>
  );
};

CardListView.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  buttons: PropTypes.node.isRequired,
  translate: PropTypes.func.isRequired,
  newCard: PropTypes.bool,
  newCardError: PropTypes.bool,
  primaryCardMissing: PropTypes.bool,
};

CardListView.defaultProps = {
  newCard: false,
  newCardError: false,
  primaryCardMissing: false,
};

const TranslatedCardListView = translated(CardListView);

const CardsList = ({ translate, restaurateur, newCard, newCardError }) => {
  const [cards, setCards] = useState([]);
  const [sorting, setSorting] = useState(false);

  const [loading, , error] = usePromise(
    () => client.cards(restaurateur.appPaymentProviders),
    (data) => setCards(data["hydra:member"])
  );

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  const cardUp = (cardId) => {
    setSorting(true);
    return client
      .cardUp(cardId)
      .then(() => client.cards(restaurateur.appPaymentProviders))
      .then((data) => {
        setCards(data["hydra:member"]);
        setSorting(false);
      });
  };
  const cardDown = (cardId) => {
    setSorting(true);
    return client
      .cardDown(cardId)
      .then(() => client.cards(restaurateur.appPaymentProviders))
      .then((data) => {
        setCards(data["hydra:member"]);
        setSorting(false);
      });
  };

  const primaryCardMissing = !hasOneValidPrimaryCard(cards);

  const buttons = cards
    .sort((card1, card2) => card1.position - card2.position)
    .map((card, index) => (
      <CardButton
        restaurateur={restaurateur}
        card={card}
        key={card.id}
        primaryCardMissing={primaryCardMissing}
      >
        {sorting ? (
          <Loading className={styles.loading} />
        ) : (
          <div className={styles.arrows}>
            {index > 0 && (
              <button
                className={styles.arrow}
                type="button"
                onClick={() => cardUp(card.id)}
              >
                <ArrowUp />
              </button>
            )}
            {index < cards.length - 1 && (
              <button
                className={styles.arrow}
                type="button"
                onClick={() => cardDown(card.id)}
              >
                <ArrowDown />
              </button>
            )}
          </div>
        )}
      </CardButton>
    ));

  return (
    <TranslatedCardListView
      restaurateur={restaurateur}
      buttons={buttons}
      newCard={newCard}
      newCardError={newCardError}
      primaryCardMissing={primaryCardMissing}
    />
  );
};

CardsList.propTypes = {
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    appPaymentProviders: PropTypes.array,
  }).isRequired,
  newCard: PropTypes.bool,
  newCardError: PropTypes.bool,
};

CardsList.defaultProps = {
  newCard: false,
  newCardError: false,
};

export default translated(CardsList);
