// eslint-disable-next-line import/prefer-default-export
export const offersToProductList = (offers) =>
  Object.values(
    offers.reduce((agg, offer) => {
      const key = `${
        offer.product ? offer.product.id.toString() : "undefined"
      }-${offer.initialPrice.toString()}-${offer.tax.toString()}`;
      return {
        ...agg,
        [key]: Object.keys(agg).includes(key)
          ? {
              ...agg[key],
              quantity: agg[key].quantity + 1,
              price:
                agg[key].price < offer.price ? offer.price : agg[key].price,
              timedPromotionPrice:
                agg[key].timedPromotionPrice || offer.timedPromotionPrice,
              noWastePromotionPrice:
                agg[key].noWastePromotionPrice || offer.noWastePromotionPrice,
              timedPromotionTags: [
                ...agg[key].timedPromotionTags,
                ...(offer.timedPromotionPrice ? [offer.rfid] : []),
              ],
              noWastePromotionTags: [
                ...agg[key].noWastePromotionTags,
                ...(offer.noWastePromotionPrice ? [offer.rfid] : []),
              ],
            }
          : {
              id: key,
              quantity: 1,
              name: offer.product ? offer.product.name : null,
              price: offer.price,
              tax: offer.tax,
              timedPromotionPrice: offer.timedPromotionPrice,
              noWastePromotionPrice: offer.noWastePromotionPrice,
              timedPromotionTags:
                offer.timedPromotionPrice !== null ? [offer.rfid] : [],
              noWastePromotionTags:
                offer.noWastePromotionPrice !== null ? [offer.rfid] : [],
              expireAt: offer.expireAt,
              picture: offer.product ? offer.product.picture : null,
              description: offer.product ? offer.product.description : null,
              nutriScore: offer.product ? offer.product.nutriScore : null,
              initialPrice: offer.initialPrice ? offer.initialPrice : null,
              minPrice: offer.product ? offer.product.price : null,
              minKioskPrice: offer.minKioskPrice,
              maxKioskPrice: offer.maxKioskPrice,
            },
      };
    }, {})
  ).map((product) => {
    let promotionTags = [];
    let showTags = false;
    if (
      product.timedPromotionPrice !== null &&
      product.timedPromotionPrice < product.initialPrice &&
      product.timedPromotionPrice === product.noWastePromotionPrice
    ) {
      promotionTags = [
        ...product.timedPromotionTags,
        ...product.noWastePromotionTags,
      ].filter((tag, index, self) => self.indexOf(tag) === index);
      showTags = true;
    } else if (
      product.timedPromotionPrice !== null &&
      product.timedPromotionPrice < product.initialPrice &&
      (product.noWastePromotionPrice === null ||
        product.timedPromotionPrice < product.noWastePromotionPrice)
    ) {
      promotionTags = product.timedPromotionTags;
    } else if (
      product.noWastePromotionPrice !== null &&
      product.noWastePromotionPrice < product.initialPrice
    ) {
      promotionTags = product.noWastePromotionTags;
      showTags = true;
    }

    return { ...product, promotionTags, showTags };
  });
