/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Fridge = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="9"
      y="5"
      width="12"
      height="22"
      rx="1"
      fill="none"
      stroke="#2A2A72"
      strokeWidth="2"
    />
    <rect x="8" y="12" width="14" height="2" fill="inherit" />
    <rect
      x="17"
      y="16"
      width="6"
      height="2"
      transform="rotate(-90 17 16)"
      fill="inherit"
    />
  </svg>
);

Fridge.propTypes = {
  className: PropTypes.string,
};

Fridge.defaultProps = {
  className: null,
};

export default Fridge;
