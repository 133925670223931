import React from "react";
import * as PropTypes from "prop-types";

const Chevron = ({ className, direction }) => {
  let style = null;

  if (direction === "left") {
    style = { transform: "rotate(-180deg)", fill: "currentColor" };
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      style={style}
    >
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

Chevron.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
};

Chevron.defaultProps = {
  className: null,
  direction: "right",
};

export default Chevron;
