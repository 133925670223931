import * as React from "react";

/* eslint-disable max-len */
const SvgComponent = (props) => (
  <svg
    viewBox="0 0 105 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.968 14.931c0-1.1.413-2.202 1.032-3.096V0H0v30h30V18.027c-.688-.894-1.032-1.926-1.032-3.096Z"
      fill="#F48F00"
    />
    <path
      d="M17.959 10.665c2.34-1.514 5.023-.757 6.399.963 1.514 1.927 1.514 4.68 0 6.537-1.445 1.79-4.06 2.546-6.4 1.032-.068.138-.068.207-.068.344v2.89c0 .069.069.207-.138.275l-2.27.55V10.253c0-.206.069-.275.275-.344l1.926-.481c.207-.07.207 0 .207.206.069.275.069.688.069 1.032Zm-3.647.688v4.404c0 2.34-1.583 3.99-3.922 4.266-.963.137-1.927.069-2.821-.344-1.445-.62-2.202-1.72-2.408-3.234-.07-.344-.07-.757-.07-1.101V7.087c0-.206.07-.275.276-.344l1.927-.413c.206-.069.275 0 .275.207v8.6c0 .413 0 .758.137 1.17.207.826.757 1.239 1.72 1.377.345.068.689 0 1.033-.07.688-.206 1.032-.619 1.17-1.238.137-.413.137-.826.137-1.238V7.156c0-.206.069-.275.275-.344l1.996-.413c.206-.069.275 0 .275.206v4.748Z"
      fill="#FEFEFE"
    />
    <path
      d="M17.752 15c0-1.376.895-2.546 2.133-2.821a2.802 2.802 0 0 1 3.372 2.27c.275 1.446-.413 2.753-1.652 3.303-1.651.688-3.44-.344-3.784-2.133-.069-.275-.069-.481-.069-.619ZM50.573 18.784a7.181 7.181 0 0 1-4.128 1.308c-2.959 0-5.298-2.27-5.298-5.23 0-2.89 2.408-5.229 5.298-5.229 1.445 0 2.89.62 3.853 1.651.344.413.62.757.895 1.445l-6.812 4.129c.481.688 1.1 1.032 2.064 1.032.963 0 1.789-.344 3.096-1.17l1.032 2.064Zm-2.34-6.33c-.55-.482-1.031-.62-1.65-.62-1.652 0-3.028 1.377-3.028 3.028v.413l4.679-2.82Z"
      fill="#F48F00"
    />
    <path
      d="M54.702 19.266c0 1.652-.276 2.615-.964 3.303-.688.688-1.376.963-2.614.963h-.688l-.688-2.202h.55c.826 0 1.445-.206 1.789-.619.275-.344.344-.62.344-1.445v-9.22l2.34-.275-.07 9.495Zm10.802-.482a7.181 7.181 0 0 1-4.128 1.308c-2.959 0-5.298-2.27-5.298-5.23 0-2.89 2.408-5.229 5.298-5.229 1.445 0 2.89.62 3.853 1.651.344.413.62.757.895 1.445l-6.812 4.129c.481.688 1.1 1.032 2.064 1.032.963 0 1.789-.344 3.096-1.17l1.032 2.064Zm-2.408-6.33c-.55-.482-1.032-.62-1.651-.62-1.652 0-3.028 1.377-3.028 3.028v.413l4.68-2.82Zm10.253 7.363v-1.239c-.964 1.032-1.652 1.376-2.753 1.376-1.1 0-2.064-.482-2.615-1.238-.412-.55-.55-1.239-.55-2.34V9.977l2.34-.275v5.573l.068 1.445c.138.55.688.963 1.308.963.688 0 1.376-.343 1.72-.825.344-.413.413-.963.413-2.064V9.977l2.34-.275v9.77l-2.272.345Zm10.114 0V13.83c0-.757-.069-1.032-.206-1.307-.207-.413-.757-.688-1.239-.688-.757 0-1.445.413-1.789 1.032-.206.413-.275.963-.275 2.064v4.68l-2.34.274v-9.84l2.271-.274v1.376c.62-1.101 1.445-1.514 2.684-1.514 1.032 0 1.858.413 2.408 1.032.55.688.757 1.308.757 2.546v6.33l-2.27.276ZM96.88 18.784a7.181 7.181 0 0 1-4.128 1.308c-2.959 0-5.298-2.27-5.298-5.23 0-2.89 2.408-5.229 5.298-5.229 1.445 0 2.89.62 3.854 1.651.344.413.619.757.894 1.445l-6.812 4.129c.482.688 1.101 1.032 2.064 1.032.964 0 1.79-.344 3.097-1.17l1.032 2.064Zm-2.339-6.33c-.55-.482-1.032-.62-1.651-.62-1.651 0-3.028 1.377-3.028 3.028v.413l4.68-2.82ZM98.463 19.817v-9.771l2.34-.275v1.582c.894-1.376 1.651-1.789 3.302-1.789h.482l-.895 2.615h-.344c-.825 0-1.513.344-1.926.894-.413.482-.619 1.445-.619 2.684v3.853l-2.34.207ZM46.72 5.917h2.615L46.858 8.67h-2.065l1.927-2.753Zm5.383 1.677 1.605-1.605 1.605 1.605L53.708 9.2l-1.605-1.606ZM39.977 6.468v13.21l-2.064.276v-1.17c-1.101.895-1.996 1.239-3.028 1.239-2.546 0-4.747-2.34-4.747-5.092s2.201-5.092 4.816-5.092c1.032 0 1.789.276 2.821 1.032V6.743l2.202-.275ZM35.16 17.89c1.514 0 2.753-1.307 2.753-2.89 0-1.583-1.308-2.959-2.821-2.959-1.445 0-2.684 1.308-2.684 2.89 0 1.652 1.17 2.959 2.752 2.959Z"
      fill="#F48F00"
    />
  </svg>
);

export default SvgComponent;
