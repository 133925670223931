import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as PropTypes from "prop-types";

const FridgeRedirect = ({ restaurateur, fridgeSlug }) => {
  if (fridgeSlug) {
    return <Redirect to={`/${restaurateur.slug}/${fridgeSlug}`} />;
  }

  return <Redirect to={`/${restaurateur.slug}`} />;
};

const mapStateToProps = (state) => ({
  fridgeSlug: state.fridge.slug,
});

FridgeRedirect.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  fridgeSlug: PropTypes.string,
};

FridgeRedirect.defaultProps = {
  fridgeSlug: null,
};

export default connect(mapStateToProps)(FridgeRedirect);
