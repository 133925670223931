import React from "react";
import * as PropTypes from "prop-types";
import translated from "../../services/i18n/translated";
import {
  FRIDGE_IS_OPENED_BUY_LABEL,
  FRIDGE_IS_OPENED_BUY_LABEL_MORE,
  FRIDGE_IS_OPENED_BUY_TEXT,
} from "../../services/i18n/messages";
import styles from "./fridgeopened.module.scss";
import { ReactComponent as Logo } from "../../assets/fridge.svg";
import DoorSteps from "../common/DoorSteps";

const FridgeOpened = ({ translate, interactions }) => {
  return (
    <div className={styles.container}>
      {interactions && (
        <>
          <div className={styles.icon}>
            <Logo />
          </div>
          <div className={styles.messages}>
            <div className={styles.label}>
              <span>{translate(FRIDGE_IS_OPENED_BUY_LABEL_MORE)}</span>
              <span>{translate(FRIDGE_IS_OPENED_BUY_TEXT)}</span>
            </div>
          </div>
        </>
      )}
      {!interactions && (
        <>
          <DoorSteps step={2}>
            <div className={styles.label}>
              <span>{translate(FRIDGE_IS_OPENED_BUY_LABEL)}</span>
              <span>{translate(FRIDGE_IS_OPENED_BUY_LABEL_MORE)}</span>
              <span>{translate(FRIDGE_IS_OPENED_BUY_TEXT)}</span>
            </div>
          </DoorSteps>
        </>
      )}
    </div>
  );
};

FridgeOpened.propTypes = {
  translate: PropTypes.func.isRequired,
  interactions: PropTypes.bool.isRequired,
};

export default translated(FridgeOpened);
