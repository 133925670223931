import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./footer.module.css";
import translated from "../../services/i18n/translated";
import { FOOTER_LINK, FOOTER_TEXT } from "../../services/i18n/messages";
import stringReplace from "../../services/formatters/string-replace";
import { version, brandUrl } from "../../config";

const Footer = ({ translate, visible, link }) => {
  if (!visible) {
    return null;
  }

  const footerLink = stringReplace(
    translate(FOOTER_TEXT),
    "%{link}",
    link ? (
      <a href={brandUrl} target="_blank" rel="noopener noreferrer">
        {translate(FOOTER_LINK)}
      </a>
    ) : (
      <span>{translate(FOOTER_LINK)}</span>
    )
  );

  return (
    <div className={styles.footer}>
      <div className={styles.text}>{footerLink}</div>
      <span className={styles.version}>{`v${version}`}</span>
    </div>
  );
};

Footer.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  link: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  visible: state.footer.visible,
  link: state.footer.link,
});

export default connect(mapStateToProps)(translated(Footer));
