/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Lang = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.99 6C9.47 6 5 10.48 5 16C5 21.52 9.47 26 14.99 26C20.52 26 25 21.52 25 16C25 10.48 20.52 6 14.99 6ZM21.92 12H18.97C18.657 10.7615 18.1936 9.5659 17.59 8.44C19.4141 9.068 20.9512 10.3317 21.92 12ZM15 8.04C15.83 9.24 16.48 10.57 16.91 12H13.09C13.52 10.57 14.17 9.24 15 8.04ZM7.26 18C7.1 17.36 7 16.69 7 16C7 15.31 7.1 14.64 7.26 14H10.64C10.56 14.66 10.5 15.32 10.5 16C10.5 16.68 10.56 17.34 10.64 18H7.26ZM8.08 20H11.03C11.35 21.25 11.81 22.45 12.41 23.56C10.584 22.9354 9.04583 21.6708 8.08 20ZM11.03 12H8.08C9.04583 10.3292 10.584 9.06457 12.41 8.44C11.8064 9.5659 11.343 10.7615 11.03 12ZM15 23.96C14.17 22.76 13.52 21.43 13.09 20H16.91C16.48 21.43 15.83 22.76 15 23.96ZM17.34 18H12.66C12.57 17.34 12.5 16.68 12.5 16C12.5 15.32 12.57 14.65 12.66 14H17.34C17.43 14.65 17.5 15.32 17.5 16C17.5 16.68 17.43 17.34 17.34 18ZM17.59 23.56C18.19 22.45 18.65 21.25 18.97 20H21.92C20.9512 21.6683 19.4141 22.932 17.59 23.56ZM19.36 18C19.44 17.34 19.5 16.68 19.5 16C19.5 15.32 19.44 14.66 19.36 14H22.74C22.9 14.64 23 15.31 23 16C23 16.69 22.9 17.36 22.74 18H19.36Z"
      fill="inherit"
    />
  </svg>
);

Lang.propTypes = {
  className: PropTypes.string,
};

Lang.defaultProps = {
  className: null,
};

export default Lang;
