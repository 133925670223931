import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "../common/Paper";
import Form from "../form/Form";
import { EmailInput, PasswordInput } from "../form/Input";
import SubmitButton from "../form/SubmitButton";
import client from "../../services/client";
import BigText from "../common/BigText";
import {
  ACCEPT_TERMS,
  EMAIL_ADDRESS,
  LOGIN_ACTION,
  PASSWORD,
  REGISTER_FORM_SUBMITTED,
  REGISTER_TITLE,
  REGISTRATION,
  TERMS,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import BottomContainer from "../common/BottomContainer";
import LoginButton from "../common/buttons/LoginButton";
import Page, { PageContent } from "../common/Page";
import styles from "./registerform.module.css";
import stringReplace from "../../services/formatters/string-replace";
import { termsUrl } from "../../config";

const RegisterForm = ({ translate, restaurateur, fridge }) => {
  const [isSubmitted, setSubmitted] = useState(false);

  if (isSubmitted) {
    return <BigText>{translate(REGISTER_FORM_SUBMITTED)}</BigText>;
  }

  const termsLabel = stringReplace(
    translate(ACCEPT_TERMS),
    "%{link}",
    <a href={termsUrl} target="_blank" rel="noopener noreferrer">
      {translate(TERMS)}
    </a>
  );

  return (
    <Page>
      <PageContent>
        <Form
          initialValues={{ email: "", password: "", terms: false }}
          validationRules={{
            email: ["required", "email"],
            password: ["required"],
          }}
          sendRequest={({ email, password }) =>
            client.register(
              restaurateur.slug,
              email,
              password,
              fridge ? fridge.slug : undefined
            )
          }
          onSuccess={() => setSubmitted(true)}
          className={styles.form}
        >
          <Paper title={translate(REGISTER_TITLE)} titleOutside transparent>
            <EmailInput
              name="email"
              label={translate(EMAIL_ADDRESS)}
              required
            />
            <PasswordInput
              name="password"
              label={translate(PASSWORD)}
              required
            />
            <p className={styles["terms-label"]}>{termsLabel}</p>
            <SubmitButton primary>{translate(REGISTRATION)}</SubmitButton>
          </Paper>
        </Form>
      </PageContent>
      <BottomContainer>
        <LoginButton restaurateur={restaurateur}>
          {translate(LOGIN_ACTION)}
        </LoginButton>
      </BottomContainer>
    </Page>
  );
};

RegisterForm.propTypes = {
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
};

RegisterForm.defaultProps = {
  fridge: null,
};

const mapStateToProps = (state) => ({
  fridge: state.fridge.data,
});

export default connect(mapStateToProps, null)(translated(RegisterForm));
