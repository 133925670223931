import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  TAGS_IN_PROMOTION,
  PRODUCTS_AVAILABLE,
  BACK,
  IN_PROMOTION,
} from "../../services/i18n/messages";
import popupStyles from "./fridgepopup.module.scss";
import customStyles from "./product.module.scss";
import { money } from "../../services/formatters";
import Button from "../common/buttons/Button";
import Close from "../common/icons/Close";
import Chevron from "../common/icons/Chevron";
import translated from "../../services/i18n/translated";
import NutriScore from "../common/NutriScore";

const styles = { ...popupStyles, ...customStyles };

const ProductPopup = (props) => {
  const { product, onClose, locale, translate } = props;
  const moneyFormatter = money(locale);
  const wrapperRef = useRef();

  useEffect(() => {
    wrapperRef.current.focus();
  });

  useEffect(() => {
    const timer = setTimeout(onClose, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div
      ref={wrapperRef}
      className={styles.wrapper}
      role="button"
      tabIndex={0}
      onClick={() => onClose && onClose()}
      onKeyPress={() => onClose && onClose()}
    >
      <Close className={styles.popupCloseIcon} />
      <div className={`${styles.popup} ${styles.productPopup}`}>
        <div className={`${styles.popupHeader} ${styles.productHeader}`}>
          <h3>{product.name}</h3>
          <div className={styles.productInfos}>
            <span>
              {translate(PRODUCTS_AVAILABLE, { smart_count: product.quantity })}
            </span>
            <span>{moneyFormatter.format(product.price)}</span>
          </div>
        </div>
        <div className={`${styles.popupBody} ${styles.productBody}`}>
          <div className={styles.productBodyHeader}>
            <div className={styles.productPicture}>
              <img src={product.picture} alt="product" />
            </div>
            {product.initialPrice && product.initialPrice > product.price && (
              <div className={styles.productPriceContainer}>
                <div className={styles.promotion}>
                  {translate(IN_PROMOTION, {
                    smart_count: product.promotionTags.length,
                  })}
                </div>
                <div className={styles.productPrice}>
                  {moneyFormatter.format(product.price)}
                  <del className={styles.productPriceInitial}>
                    {moneyFormatter.format(product.initialPrice)}
                  </del>
                </div>
              </div>
            )}
          </div>
          <div className={styles.productBody}>
            {product.showTags && product.promotionTags.length > 0 && (
              <>
                <span>{translate(TAGS_IN_PROMOTION)}</span>
                <div className={styles.promotionTags}>
                  {product.promotionTags.map((tag) => (
                    <span key={tag}>
                      {`${tag.substr(0, 5)}...${tag.substr(-10)}`}
                    </span>
                  ))}
                </div>
              </>
            )}
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: product.description }}
              className={styles.productDescription}
            />
            {product.nutriScore && (
              <NutriScore nutriScore={product.nutriScore} />
            )}
          </div>
        </div>
        <div className={styles.popupFooter}>
          <Button className={styles.popupCloseBtn}>
            <Chevron direction="left" />
            <span>{translate(BACK)}</span>
          </Button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default translated(ProductPopup);
