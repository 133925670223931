import { connect } from "react-redux";
import { createGetP, getLocale } from "redux-polyglot";
import curry from "lodash.curry";

export const is = (type) => (x) => typeof x === type; // eslint-disable-line valid-typeof
const isString = is("string");
const isFunction = is("function");
const isObject = is("object");

const getDisplayName = (Component) =>
  Component.displayName || Component.name || "Component";

const mapPolyglotToProps = (options) => () => {
  const getP = createGetP();

  return (state) => ({
    translate: getP(state, options).t,
    locale: getLocale(state),
  });
};

const translateEnhancer = curry((polyglotScope, Component) => {
  const Connected = connect(mapPolyglotToProps(polyglotScope), () => ({}))(
    Component
  );
  Connected.displayName = `Translated(${getDisplayName(
    Connected.WrappedComponent
  )})`;
  return Connected;
});

const translated = (fstArg, sndArg) => {
  if (fstArg === undefined && sndArg === undefined)
    return translateEnhancer({});

  if (isFunction(fstArg)) return translateEnhancer({}, fstArg);

  if (isString(fstArg) && sndArg === undefined)
    return translateEnhancer({ polyglotScope: fstArg });

  if (isObject(fstArg) && sndArg === undefined)
    return translateEnhancer(fstArg);

  return translateEnhancer(fstArg, sndArg);
};

export default translated;
