import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import Page, { PageContent } from "../common/Page";
import translated from "../../services/i18n/translated";
import {
  ERROR,
  FRIDGE_OFFERS,
  LOADING,
  NO_OFFERS,
  OPEN_ACTION,
} from "../../services/i18n/messages";
import { usePromise } from "../hooks";
import client from "../../services/client";
import BigText from "../common/BigText";
import { offersToProductList } from "../../services/helpers/offer";
import Button from "../common/buttons/Button";
import BottomContainer from "../common/BottomContainer";
import redirect from "../../services/routing/redirect";
import OrderDetails from "../common/OrderDetails";
import { TYPE_REMOVE } from "../../services/helpers/order";

const OutdatedOffers = ({ translate, fridge, restaurateur, openDoor }) => {
  const [loading, datas, error] = usePromise(() =>
    client.fridgeOffers(fridge.slug, { outdated: true })
  );

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  if (datas["hydra:member"].length === 0) {
    return <BigText>{translate(NO_OFFERS)}</BigText>;
  }

  const products = offersToProductList(datas["hydra:member"]);

  return (
    <Page>
      <PageContent>
        <OrderDetails
          title={translate(FRIDGE_OFFERS)}
          products={products}
          data="date"
        />
      </PageContent>
      <BottomContainer>
        <Button
          primary
          onClick={() => {
            openDoor({
              type: TYPE_REMOVE,
              restaurateurSlug: restaurateur.slug,
              slug: fridge.slug,
            });
            redirect(`/${restaurateur.slug}/${fridge.slug}/open/remove`);
          }}
        >
          {translate(OPEN_ACTION)}
        </Button>
      </BottomContainer>
    </Page>
  );
};

OutdatedOffers.propTypes = {
  translate: PropTypes.func.isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  openDoor: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openDoor: dispatch.door.open,
});

export default connect(null, mapDispatchToProps)(translated(OutdatedOffers));
