import en from "./en";
import fr from "./fr";

const locales = {
  fr: "Français",
  en: "English",
};

export default {
  fr: { ...fr, ...locales },
  en: { ...en, ...locales },
};
