import React from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import styles from "./burger.module.css";

const Burger = ({ children, menu, opened }) => (
  <div className={styles.container}>
    <div className={[styles.inner, opened ? styles.opened : ""].join(" ")}>
      <div className={styles.left}>{children}</div>
      <div className={styles.right}>{menu}</div>
    </div>
  </div>
);

Burger.propTypes = {
  children: PropTypes.node.isRequired,
  menu: PropTypes.node.isRequired,
  opened: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  opened: state.burger.opened,
});

export default connect(mapStateToProps)(Burger);
