import React from "react";
import QRCode from "react-qr-code";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import translated from "../../../services/i18n/translated";
import styles from "./paymentmethodbutton.module.scss";
import question from "../../../assets/question.svg";
import { ReactComponent as CreditCardIcon } from "../../../assets/credit-card.svg";
import {
  PAY_WITH_CARD_BTN,
  PAY_WITH_APP_BTN,
  PAY_WITH_CARD_BTN_2,
  PAY_WITH_APP_BTN_2,
  PAY_WITH_CARD_BTN_3,
} from "../../../services/i18n/messages";

const PaymentMethodButton = ({
  translate,
  theme,
  type,
  restaurateur,
  fridge,
  onClick,
  single,
}) => {
  const QRCodeUrl = `${window.location.protocol}//${window.location.host}/${restaurateur.slug}/${fridge.slug}`;

  const style = {};
  if (theme && theme.primaryColor) {
    style.backgroundColor = theme.primaryColor;
  }

  return (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      className={styles.container}
      style={style}
    >
      <div className={styles.body}>
        {type === "app" && (
          <QRCode
            fgColor={style.backgroundColor || "#2A2A72"}
            size={80}
            value={QRCodeUrl}
          />
        )}
        {type === "card" && (
          <CreditCardIcon
            className={styles.icon}
            style={theme.primaryColor ? { color: theme.primaryColor } : {}}
          />
        )}
      </div>
      {type === "app" && (
        <div className={styles.label}>
          <span>{translate(PAY_WITH_APP_BTN)}</span>
          <span>{translate(PAY_WITH_APP_BTN_2)}</span>
        </div>
      )}
      {type === "card" && (
        <div className={styles.label}>
          <span>{translate(PAY_WITH_CARD_BTN)}</span>
          <span>
            {translate(single ? PAY_WITH_CARD_BTN_2 : PAY_WITH_CARD_BTN_3)}
          </span>
        </div>
      )}
      <img src={question} className={styles.info} alt="info" />
    </div>
  );
};

PaymentMethodButton.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    primaryColor: PropTypes.string,
  }),
  single: PropTypes.bool,
};

PaymentMethodButton.defaultProps = {
  theme: null,
  single: false,
  onClick: () => {},
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});

export default connect(mapStateToProps)(translated(PaymentMethodButton));
