export const version = process.env.REACT_APP_VERSION;

export const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

export const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

export const termsUrl = process.env.REACT_APP_TERMS_URL;

export const brandUrl = process.env.REACT_APP_BRAND_URL;

export const adyenClientKey = process.env.REACT_APP_ADYEN_CLIENT_KEY;

export const adyenEnv = process.env.REACT_APP_ADYEN_ENV;

export const paygreenEnv = process.env.REACT_APP_PAYGREEN_ENV;

export const paygreenPk = process.env.REACT_APP_PAYGREEN_PK;

export const paygreenSDK = process.env.REACT_APP_PAYGREEN_SDK;

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

export const sentryEnv = process.env.REACT_APP_SENTRY_ENV;

export const mercureEndpoint = process.env.REACT_APP_MERCURE_ENDPOINT;
