import React, { useState } from "react";
import * as PropTypes from "prop-types";
import Page, { PageContent } from "../common/Page";
import Paper from "../common/Paper";
import {
  CARD_DELETE,
  CARD_DELETE_CONFIRM,
  ERROR,
  LOADING,
  NO,
  YES,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import { usePromise } from "../hooks";
import client from "../../services/client";
import BigText from "../common/BigText";
import { formatCardNumber } from "../../services/helpers/card";
import styles from "./cardsdelete.module.css";
import Button from "../common/buttons/Button";
import PromiseButton from "../common/buttons/PromiseButton";
import redirect from "../../services/routing/redirect";

const CardsDelete = ({ translate, cardId, restaurateur: { slug } }) => {
  const [loading, card, error] = usePromise(() => client.card(cardId));

  const [disabled, setDisabled] = useState(false);

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  return (
    <Page>
      <PageContent>
        <Paper title={translate(CARD_DELETE)}>
          <div className={styles.content}>
            {translate(CARD_DELETE_CONFIRM, {
              number: formatCardNumber(card.pan),
            })}
          </div>
          <div className={styles.buttons}>
            <Button
              disabled={disabled}
              onClick={() => redirect(`/${slug}/cards`)}
              primary
            >
              {translate(NO)}
            </Button>
            <PromiseButton
              errorStyle
              promise={() => {
                setDisabled(true);
                return client.deleteCard(cardId);
              }}
              onSuccess={() => {
                setDisabled(false);
                redirect(`/${slug}/cards`);
              }}
              onError={() => setDisabled(false)}
            >
              {translate(YES)}
            </PromiseButton>
          </div>
        </Paper>
      </PageContent>
    </Page>
  );
};

CardsDelete.propTypes = {
  translate: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default translated(CardsDelete);
