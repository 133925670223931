import React from "react";
import * as PropTypes from "prop-types";
import styles from "./cardbutton.module.css";
import Delete from "../../icons/Delete";
import {
  cardIsExpired,
  formatCardNumber,
} from "../../../../services/helpers/card";
import {
  EXPIRED_CARD,
  VALID_CARD,
  PRIMARY_CARD_MISSING,
} from "../../../../services/i18n/messages";
import translated from "../../../../services/i18n/translated";
import Warning from "../../icons/Warning";
import redirect from "../../../../services/routing/redirect";
import VisaCard from "../../icons/VisaCard";
import MasterCard from "../../icons/MasterCard";
import CBCard from "../../icons/CBCard";
import Appetiz from "../../icons/Appetiz";
import UpDejeuner from "../../icons/UpDejeuner";
import PassRestaurant from "../../icons/PassRestaurant";
import Edenred from "../../icons/Edenred";
import Swile from "../../icons/Swile";

const CardButton = ({
  card,
  translate,
  children,
  restaurateur: { slug },
  primaryCardMissing,
}) => {
  const cardExpired = cardIsExpired(card);
  const [expiryYear, expiryMonth] = card.expiryDate.split("-", 2);

  function getCardIcon() {
    switch (card.brand.toLowerCase()) {
      case "visa":
        return <VisaCard id={card.id} />;
      case "mastercard":
        return <MasterCard id={card.id} />;
      case "appetiz":
        return <Appetiz id={card.id} />;
      case "up_dejeuner":
        return <UpDejeuner id={card.id} />;
      case "pass_restaurant":
        return <PassRestaurant id={card.id} />;
      case "edenred":
        return <Edenred id={card.id} />;
      case "swile":
        return <Swile id={card.id} />;
      default:
        return <CBCard id={card.id} />;
    }
  }
  return (
    <div className={styles.button}>
      <div>
        <div className={styles.info}>
          {getCardIcon()}
          {formatCardNumber(card.pan)}
        </div>
        <div className={styles["sub-info"]}>
          {cardExpired && <Warning className={styles.warning} />}
          {`${expiryMonth}/${expiryYear.slice(2, 4)} - ${translate(
            cardExpired ? EXPIRED_CARD : VALID_CARD
          )}`}
        </div>
        {!cardExpired && primaryCardMissing && card.titreRestaurant && (
          <div className={styles["sub-info"]}>
            <Warning className={styles.warning} />
            {translate(PRIMARY_CARD_MISSING)}
          </div>
        )}
      </div>
      {children}
      <button
        type="button"
        className={styles.icon}
        onClick={() => redirect(`/${slug}/cards/delete/${card.id}`)}
      >
        <Delete className={styles.delete} />
      </button>
    </div>
  );
};

CardButton.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.number.isRequired,
    expiryDate: PropTypes.string.isRequired,
    pan: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    titreRestaurant: PropTypes.bool.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  primaryCardMissing: PropTypes.bool.isRequired,
};

export default translated(CardButton);
