/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Contact = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 8.00003H7C5.9 8.00003 5.01 8.90003 5.01 10L5 22C5 23.1 5.9 24 7 24H23C24.1 24 25 23.1 25 22V10C25 8.90003 24.1 8.00003 23 8.00003ZM23 22H7V12L15 17L23 12V22ZM15 15L7 10H23L15 15Z"
      fill="inherit"
    />
  </svg>
);

Contact.propTypes = {
  className: PropTypes.string,
};

Contact.defaultProps = {
  className: null,
};

export default Contact;
