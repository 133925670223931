import React, { useState, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./tutorial.module.scss";
import Button from "../common/buttons/Button";
import Chevron from "../common/icons/Chevron";
import MinimalistButton from "../common/buttons/MinimalistButton";
import { close as closeTutorial } from "./actions";
import translated from "../../services/i18n/translated";
import {
  TUTORIAL_CLOSE_ACTION,
  TUTORIAL_NEXT_STEP_ACTION,
  TUTORIAL_STEP_1_DESCRIPTION,
  TUTORIAL_STEP_1_TITLE,
  TUTORIAL_STEP_2_DESCRIPTION,
  TUTORIAL_STEP_2_TITLE,
  TUTORIAL_STEP_3_DESCRIPTION,
  TUTORIAL_STEP_3_TITLE,
  TUTOTIAL_START_ACTION,
} from "../../services/i18n/messages";
import Step1 from "../../assets/step1.svg";
import Step2 from "../../assets/step2.svg";
import Step3 from "../../assets/fridge.gif";

const Tutorial = ({ close, translate }) => {
  const [activeStep, setActiveStep] = useState(0);
  const refs = [createRef(), createRef(), createRef()];
  const maxStep = 2;
  const nextStep = () => {
    const newActiveStep = Math.min(activeStep + 1, maxStep);
    setActiveStep(newActiveStep);
    refs[newActiveStep].current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  return (
    <div className={styles.tutorial}>
      <div className={styles["step-scroll"]}>
        <div className={styles["step-scroll-inner"]}>
          <div className={styles.steps}>
            <div className={styles.step} ref={refs[0]}>
              <div className={styles["visual-container"]}>
                <button
                  type="button"
                  className={styles.visual}
                  onClick={nextStep}
                >
                  <img src={Step1} alt="tutorial step 1" />
                </button>
              </div>
              <div className={styles.text}>
                <div className={styles.title}>
                  {translate(TUTORIAL_STEP_1_TITLE)}
                </div>
                <div className={styles.description}>
                  {translate(TUTORIAL_STEP_1_DESCRIPTION)}
                </div>
              </div>
            </div>
            <div className={styles.step} ref={refs[1]}>
              <div className={styles["visual-container"]}>
                <button
                  type="button"
                  className={styles.visual}
                  onClick={nextStep}
                >
                  <img src={Step2} alt="tutorial step 2" />
                </button>
              </div>
              <div className={styles.text}>
                <div className={styles.title}>
                  {translate(TUTORIAL_STEP_2_TITLE)}
                </div>
                <div className={styles.description}>
                  {translate(TUTORIAL_STEP_2_DESCRIPTION)}
                </div>
              </div>
            </div>
            <div className={styles.step} ref={refs[2]}>
              <div className={styles["visual-container"]}>
                <button type="button" className={styles.visual} onClick={close}>
                  <img src={Step3} alt="tutorial step 3" />
                </button>
              </div>
              <div className={styles.text}>
                <div className={styles.title}>
                  {translate(TUTORIAL_STEP_3_TITLE)}
                </div>
                <div className={styles.description}>
                  {translate(TUTORIAL_STEP_3_DESCRIPTION)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dots}>
        <div
          className={[
            styles.dot,
            activeStep === 0 ? styles["dot-active"] : undefined,
          ].join(" ")}
        />
        <div
          className={[
            styles.dot,
            activeStep === 1 ? styles["dot-active"] : undefined,
          ].join(" ")}
        />
        <div
          className={[
            styles.dot,
            activeStep === 2 ? styles["dot-active"] : undefined,
          ].join(" ")}
        />
      </div>
      <div className={styles.buttons}>
        {activeStep < maxStep && (
          <>
            <div>
              <MinimalistButton onClick={close}>
                {translate(TUTORIAL_CLOSE_ACTION)}
              </MinimalistButton>
            </div>
            <div>
              <Button
                onClick={nextStep}
                secondary
                className={styles["next-button"]}
              >
                <span>{translate(TUTORIAL_NEXT_STEP_ACTION)}</span>
                <Chevron className={styles.chevron} />
              </Button>
            </div>
          </>
        )}
        {activeStep === maxStep && (
          <div className={styles["button-large"]}>
            <Button onClick={close} primary>
              <span>{translate(TUTOTIAL_START_ACTION)}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Tutorial.propTypes = {
  translate: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeTutorial()),
});

export default connect(null, mapDispatchToProps)(translated(Tutorial));
