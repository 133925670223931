import * as React from "react";

/* eslint-disable max-len */
const SvgComponent = (props) => (
  <svg
    width={235}
    height={280}
    viewBox="0 50 235 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#0C0F0A"
      d="m117.576 161.221-88.201-87.17L44.94 58.666l88.201 87.17z"
    />
    <path
      fill="#0C0F0A"
      d="m205.625 74.195-88.255 87.116-15.575-15.373 88.255-87.116zM117.576 245.574l-88.201-87.171L44.94 143.02l88.201 87.17z"
    />
    <path
      fill="#0C0F0A"
      d="M205.625 158.495 117.37 245.61l-15.575-15.373 88.255-87.117z"
    />
  </svg>
);

export default SvgComponent;
