import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect, ErrorMessage } from "formik";
import Cleave from "cleave.js/react";
import styles from "./input.module.css";
import translated from "../../services/i18n/translated";
import { REQUIRED_LABEL } from "../../services/i18n/messages";

const InputByType = ({
  name,
  type,
  placeholder,
  onFocus,
  onChange,
  onBlur,
  value,
  className,
  autoCapitalize,
}) => {
  switch (type) {
    case "card-number":
      return (
        <Cleave
          {...{
            name,
            placeholder,
            className,
            onFocus,
            onChange,
            onBlur,
            value,
          }}
          options={{ creditCard: true }}
          id={name}
        />
      );
    case "card-expire":
      return (
        <Cleave
          {...{
            name,
            placeholder,
            className,
            onFocus,
            onChange,
            onBlur,
            value,
          }}
          options={{ date: true, datePattern: ["m", "y"] }}
          id={name}
        />
      );
    case "card-cvc":
      return (
        <Cleave
          {...{
            name,
            placeholder,
            className,
            onFocus,
            onChange,
            onBlur,
            value,
          }}
          options={{ numeral: true }}
          id={name}
          maxLength={3}
        />
      );
    default:
      return (
        <input
          {...{
            name,
            type,
            placeholder,
            className,
            onFocus,
            onChange,
            onBlur,
            value,
            autoCapitalize,
          }}
          id={name}
        />
      );
  }
};

InputByType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  autoCapitalize: PropTypes.oneOf(["none", "sentences", "words", "characters"]),
};

InputByType.defaultProps = {
  placeholder: "",
  className: null,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  value: "",
  autoCapitalize: "sentences",
};

const InputView = ({
  label,
  required,
  name,
  type,
  placeholder,
  formik: { handleChange, handleBlur, values, errors, touched },
  translate,
  className,
  onChange,
  onFocus,
  onBlur,
  autoCapitalize,
}) => {
  const [isFocused, setFocused] = useState(false);

  const focusedClass =
    (isFocused || (values[name] && values[name].length > 0)) && styles.focused;

  const inputClassName = [
    styles.input,
    errors[name] && touched[name] && styles["input-error"],
  ].join(" ");

  return (
    <div
      className={[styles["input-container"], focusedClass, className].join(" ")}
    >
      <div className={styles["labels-container"]}>
        {label && (
          <label
            className={[
              styles.label,
              errors[name] && touched[name] && styles["label-error"],
            ].join(" ")}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {required && (
          <label className={styles["required-label"]} htmlFor={name}>
            {translate(REQUIRED_LABEL)}
          </label>
        )}
      </div>
      <InputByType
        {...{
          name,
          type,
          placeholder,
          onFocus,
          autoCapitalize,
        }}
        id={name}
        className={inputClassName}
        onChange={(e) => {
          onChange(e);
          handleChange(e);
        }}
        onFocus={() => setFocused(true)}
        onBlur={(e) => {
          onBlur(e);
          handleBlur(e);
          setFocused(false);
        }}
        value={values[name] || ""}
      />
      <ErrorMessage name={name}>
        {(message) => (
          <ul className={styles.error}>
            {message.split(" / ").map((line, i) => (
              <li key={i.toString()}>{translate(line)}</li>
            ))}
          </ul>
        )}
      </ErrorMessage>
    </div>
  );
};

InputView.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoCapitalize: PropTypes.oneOf(["none", "sentences", "words", "characters"]),
};

InputView.defaultProps = {
  label: null,
  required: false,
  placeholder: "",
  className: null,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  autoCapitalize: "sentences",
};

const Input = connect(translated(InputView));

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  autoCapitalize: PropTypes.oneOf(["none", "sentences", "words", "characters"]),
};

export const EmailInput = (props) => <Input type="email" {...props} />;
EmailInput.propTypes = Input.propTypes;

export const PasswordInput = (props) => <Input type="password" {...props} />;
PasswordInput.propTypes = Input.propTypes;

export const TextInput = (props) => <Input type="text" {...props} />;
TextInput.propTypes = Input.propTypes;

export const CardNumberInput = (props) => (
  <Input type="card-number" {...props} />
);
CardNumberInput.propTypes = Input.propTypes;

export const CardExpiryInput = (props) => (
  <Input type="card-expire" {...props} />
);
CardExpiryInput.propTypes = Input.propTypes;

export const CardCvcInput = (props) => <Input type="card-cvc" {...props} />;
CardCvcInput.propTypes = Input.propTypes;

export default Input;
