import { push } from "connected-react-router";
import client from "../client";

export default {
  state: {
    loading: false,
    error: null,
    command: null,
  },
  reducers: {
    setStatus: (state, status) => status,
  },
  effects: (dispatch) => ({
    async open({ restaurateurSlug, slug, type, product }, rootState) {
      this.setStatus({
        error: null,
        loading: true,
      });

      try {
        const data = await client.openFridge(restaurateurSlug, slug, type, {
          productId: product ? parseInt(product, 10) : product,
        });
        dispatch(
          push(`/${restaurateurSlug}/${slug}/orders/${data.id}/wait_for_door`)
        );
        this.setStatus({ error: null, loading: false, command: null });
      } catch (err) {
        this.setStatus({
          error: err,
          loading: false,
          command: { restaurateurSlug, slug, type, product },
        });
      }
    },
    async retry(_, rootState) {
      if (!rootState.door.command) {
        if (rootState.kiosk.slug) {
          dispatch(
            push(
              `/${rootState.fridge.data.restaurateur.slug}/${rootState.fridge.data.slug}/no-interaction`
            )
          );
        } else {
          dispatch(
            push(
              `/${rootState.fridge.data.restaurateur.slug}/${rootState.fridge.data.slug}`
            )
          );
        }
      } else {
        await dispatch.door.open(rootState.door.command);
      }
    },
  }),
};
