import React from "react";
import * as PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import Main from "../main/Main";
import BigText from "../common/BigText";
import { ERROR, LOADING } from "../../services/i18n/messages";
import NotFound from "../not-found/NotFound";
import RestaurateurRoutes from "./RestaurateurRoutes";
import translated from "../../services/i18n/translated";
import history from "../../services/routing/history";
import Gutter from "../gutter/Gutter";
import Tutorial from "../tutorial/Tutorial";
import store from "../../services/store";

const RestaurateurRouter = ({
  translate,
  match: {
    params: { restaurateurSlug },
  },
  load,
  loading,
  restaurateur,
  error,
  currentSlug,
  showTutorial,
}) => {
  if (
    !loading &&
    (!error || currentSlug !== restaurateurSlug) &&
    (!restaurateur || currentSlug !== restaurateurSlug)
  ) {
    load({ slug: restaurateurSlug });
  }

  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }

    return <BigText>{translate(ERROR)}</BigText>;
  }

  if (loading || !restaurateur) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (showTutorial) {
    store.dispatch.navBar.hideMenuButton();
    store.dispatch.footer.hide();

    return (
      <Main>
        <Tutorial />
      </Main>
    );
  }

  store.dispatch.navBar.showMenuButton();
  store.dispatch.footer.show();

  return (
    <Main
      restaurateur={restaurateur}
      logo={restaurateur ? restaurateur.logo : null}
      color={restaurateur ? restaurateur.color : null}
    >
      <Gutter>
        <ConnectedRouter history={history}>
          <RestaurateurRoutes restaurateur={restaurateur} />
        </ConnectedRouter>
      </Gutter>
    </Main>
  );
};

RestaurateurRouter.propTypes = {
  translate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      restaurateurSlug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  showTutorial: PropTypes.bool.isRequired,
  load: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    logo: PropTypes.string,
    color: PropTypes.string,
  }),
  error: PropTypes.instanceOf(Error),
  currentSlug: PropTypes.string,
};

RestaurateurRouter.defaultProps = {
  loading: false,
  restaurateur: null,
  error: null,
  currentSlug: null,
};

const mapStateToProps = (state) => ({
  showTutorial: !state.tutorial.seen,
  loading: state.restaurateur.loading,
  restaurateur: state.restaurateur.data,
  error: state.restaurateur.error,
  currentSlug: state.restaurateur.slug,
});

const mapDispatchToProps = (dispatch) => ({
  load: dispatch.restaurateur.load,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translated(RestaurateurRouter));
