import React, { forwardRef } from "react";
import * as PropTypes from "prop-types";
import styles from "./gutter.module.scss";

const Gutter = forwardRef(({ children }, ref) => (
  <div ref={ref} className={styles.gutter}>
    {children}
  </div>
));

Gutter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Gutter;
