/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Appetiz = ({ id, className }) => (
  <svg
    id="prefix__Apetiz"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="17"
    x={0}
    y={0}
    viewBox="0 0 350 120"
    xmlSpace="preserve"
    className={className}
  >
    <style>{".prefix__st1{fill:#1d1d1b}"}</style>
    <path id="prefix__Fond" fill="#ffe047" d="M0 0h350v120H0z" />
    <g id="prefix__apetiz">
      <path
        className="prefix__st1"
        d="M64.7 53.7c.2-3.8-1.1-7.5-3.6-10.4-3.2-3.5-8.3-5.6-15.4-5.6-11.5 0-17.8 5.6-17.8 11.2-.1 2.7 1.9 5 4.7 5.2h.3c2.7.1 4.9-1.9 5.1-4.6v-.4c-.1-1-.3-1.9-.8-2.8 2.2-1.4 4.8-2.2 7.4-2 3.9-.3 7.7 1.1 10.5 3.8 1.5 1.9 2.2 4.3 2.1 6.8v6.7c-4.5-1.4-9.2-2.2-13.9-2.3-10.6 0-18.4 5.8-18.4 14.5 0 8.1 6.7 13.7 15.9 13.7 6.2-.2 12.2-2.6 16.7-7v6.1h15v-6.8h-7.7V53.7zm-7.4 20.9c-3.7 4.1-8.9 6.4-14.4 6.7-6.5 0-10-3.4-10-8 0-5 3.8-8.5 11.5-8.5 4.4.1 8.7.7 13 1.9v7.9zM111.1 37.7c-6.3 0-12.2 2.9-16.1 7.8v-6.9H79.8v6.9h7.6v56.4h-7.6v6.8h24.6v-6.8h-9.2V82c4.1 3.5 9.4 5.5 14.9 5.5 14.2 0 23.7-11.6 23.7-25.1-.1-14.8-10.1-24.7-22.7-24.7zm14.5 24.8c-.1 10.5-6.7 18.1-16 18.1-5.3.1-10.4-1.8-14.4-5.3V51.7c3.9-4.1 8.6-7.1 14.7-7.1 9.1 0 15.8 7.1 15.7 17.9zM168.9 37.7c-14.6 0-23.5 11.7-23.5 25 0 14.6 10.2 24.8 25.6 24.8 6.1.1 12-1.5 17.2-4.6l.7-7.5c-5 3.3-10.9 5.2-16.9 5.3-10.4 0-17.4-5.2-18.5-15.8h36.9c.4-1.5.5-3 .5-4.5 0-12-7.7-22.7-22-22.7zm-15.3 20.9c1.1-9.1 7.5-14.2 14.9-14.2 9.1 0 14 5.9 14.5 14.2h-29.4zM215.5 77.8c-.6-1.1-.9-2.4-.8-3.7V45.5h14.7v-6.9h-14.7V24l-7.6 3v11.6h-8.9v6.9h8.9v29.7c-.1 2.3.3 4.6 1.2 6.7 1.9 3.7 5.5 5.6 10.6 5.6 3.6 0 7.1-1 10.2-2.7l1-7.4c-2.7 1.7-5.8 2.7-9 2.9-2.8 0-4.7-.8-5.6-2.5zM251.6 26.3c3.3-.1 6-2.8 6.1-6.1 0-3.4-2.7-6.2-6.1-6.3-3.3.2-6 2.9-6.1 6.2 0 3.4 2.7 6.2 6.1 6.2zM256.6 38.6h-15.9v6.9h8.2v34.3h-8.2v6.8h24v-6.8h-8.1zM318.8 69.8c-.8 3.6-2.7 8.2-7.4 9.5-2.5.7-5-.6-7.5-1.2-2.8-.8-5.8-1.3-8.7-1.3-3.3 0-6.5.5-9.6 1.4l11.4-14 17.7-22v-3.5h-40.1v15.8h6.9V45h22l-30.8 38.1v3.5h6.1l.5-.3c3.7-2.2 7.9-3.3 12.2-3.1 3.3.1 6.5.7 9.7 1.6 3.3 1 6.6 1.5 10 1.7 4.6.2 8.9-2.2 11-6.2 1.2-2.1 2-4.4 2.4-6.8.2-1.3.3-2.5.5-3.8-2 .2-4.2.3-6.3.1z"
      />
    </g>
  </svg>
);

Appetiz.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
};

Appetiz.defaultProps = {
  id: null,
  className: null,
};

export default Appetiz;
