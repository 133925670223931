import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "../common/Paper";
import Form from "../form/Form";
import { EmailInput } from "../form/Input";
import SubmitButton from "../form/SubmitButton";
import client from "../../services/client";
import {
  EMAIL_ADDRESS,
  FORGOT_PASSWORD_TITLE,
  FORGOT_PASSWORD_SENT,
  SUBMIT,
  REGISTER_ACTION,
} from "../../services/i18n/messages";
import BigText from "../common/BigText";
import translated from "../../services/i18n/translated";
import BottomContainer from "../common/BottomContainer";
import RegisterButton from "../common/buttons/RegisterButton";
import Page, { PageContent } from "../common/Page";
import styles from "./forgotpassword.module.css";

const ForgotPasswordStep1 = ({ translate, restaurateur }) => {
  const [isSubmitted, setSubmitted] = useState(false);

  if (isSubmitted) {
    return <BigText>{translate(FORGOT_PASSWORD_SENT)}</BigText>;
  }

  return (
    <Page>
      <PageContent>
        <Form
          initialValues={{ email: "" }}
          validationRules={{ email: ["required", "email"] }}
          sendRequest={({ email }) =>
            client.forgotPassword(email, restaurateur.slug)
          }
          onSuccess={() => setSubmitted(true)}
          className={styles.form}
        >
          <Paper
            title={translate(FORGOT_PASSWORD_TITLE)}
            titleOutside
            transparent
          >
            <EmailInput
              name="email"
              label={translate(EMAIL_ADDRESS)}
              required
            />
            <SubmitButton primary>{translate(SUBMIT)}</SubmitButton>
          </Paper>
        </Form>
      </PageContent>
      <BottomContainer>
        <RegisterButton restaurateur={restaurateur}>
          {translate(REGISTER_ACTION)}
        </RegisterButton>
      </BottomContainer>
    </Page>
  );
};

ForgotPasswordStep1.propTypes = {
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default translated(ForgotPasswordStep1);
