import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import styles from "./PaygreenAddInstrumentForm.module.scss";
import { paygreenPk, paygreenSDK } from "../../config";
import translated from "../../services/i18n/translated";
import useImportExternalScript from "../../services/helpers/importExternalScript";
import SubmitButton from "../form/SubmitButton";
import {
  SAVE_CARD,
  SAVE_CARD_INFO,
  SAVE_CARD_FAILED,
} from "../../services/i18n/messages";
import Form from "../form/Form";
import client from "../../services/client";

const PaygreenAddInstrumentForm = ({
  translate,
  locale,
  buyer,
  selectedPaymentMethod,
  onSuccess,
}) => {
  useImportExternalScript(paygreenSDK, () => {
    initPGJS();
  });

  const style = {
    input: {
      base: {
        color: "black",
        fontSize: "18px",
      },
      hover: {
        color: "grey",
      },
      focus: {
        color: "grey",
      },
      invalid: {
        color: "red",
      },
      placeholder: {
        base: {
          color: "grey",
        },
      },
    },
  };

  const onPanFulfilled = useCallback(() => {
    window.paygreenjs.focus("cvv");
  }, []);

  const onCVVFulfilled = useCallback(() => {
    window.paygreenjs.focus("exp");
  }, []);

  const onTokenFailed = useCallback((event) => {
    console.error(event);
  }, []);

  const onInstrumentReady = useCallback(
    (event) => {
      client
        .paygreenNotificationCard(event.detail.instrument.id)
        .then((response) => {
          onSuccess({
            success: true,
            pid: event.detail.instrument.id,
          });
        })
        .catch((error) => {
          onSuccess({
            success: false,
            message: SAVE_CARD_FAILED,
          });
        });
    },
    [onSuccess]
  );

  const unmountPGJS = useCallback(() => {
    if (!window.paygreenjs.status()) {
      return;
    }

    window.paygreenjs.detachEventListener(
      window.paygreenjs.Events.INSTRUMENT_READY,
      onInstrumentReady
    );
    window.paygreenjs.detachEventListener(
      window.paygreenjs.Events.TOKEN_FAIL,
      onTokenFailed
    );
    window.paygreenjs.detachEventListener(
      window.paygreenjs.Events.PAN_FIELD_FULFILLED,
      onPanFulfilled
    );
    window.paygreenjs.detachEventListener(
      window.paygreenjs.Events.CVV_FIELD_FULFILLED,
      onCVVFulfilled
    );
    window.paygreenjs.unmount();
  }, [onInstrumentReady, onTokenFailed, onPanFulfilled, onCVVFulfilled]);

  useEffect(() => {
    return () => unmountPGJS();
  }, [unmountPGJS]);

  const initPGJS = () => {
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.INSTRUMENT_READY,
      onInstrumentReady
    );
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.TOKEN_FAIL,
      onTokenFailed
    );
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.ERROR,
      onTokenFailed
    );
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.PAN_FIELD_FULFILLED,
      onPanFulfilled
    );
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.CVV_FIELD_FULFILLED,
      onCVVFulfilled
    );
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.PAYMENT_FLOW_ONCHANGE,
      (e) => {
        // console.log(e);
      }
    );

    window.paygreenjs.init({
      publicKey: paygreenPk,
      mode: "instrument",
      buyer: buyer.buyer_id,
      modeOptions: {
        authorizedInstrument:
          ["bank_card", "swile"].indexOf(selectedPaymentMethod.platform) > -1,
        shopId: selectedPaymentMethod.shop_id,
      },
      style,
      paymentMethod: selectedPaymentMethod.platform,
    });
  };

  return (
    <Form
      initialValues={{}}
      validationRules={{}}
      className={styles.paygreenWrapper}
      sendRequest={() => {
        window.paygreenjs.updateConsent(true);
        window.paygreenjs.submitPayment();
      }}
      onSuccess={(data) => {}}
    >
      <div>{translate(SAVE_CARD_INFO)}</div>
      <div id="paygreen-container" />
      <div id="paygreen-methods-container" />
      <div className={styles.paygreenForm}>
        <div className={styles.paygreenRow}>
          <div id="paygreen-pan-frame" className={styles.wFull} />
        </div>

        <div className={styles.paygreenRow}>
          <div className={styles.paygreenCol}>
            <div id="paygreen-cvv-frame" />
          </div>
          <div className={styles.paygreenCol}>
            <div id="paygreen-exp-frame" />
          </div>
        </div>

        <SubmitButton primary>{translate(SAVE_CARD)}</SubmitButton>
      </div>
    </Form>
  );
};

PaygreenAddInstrumentForm.propTypes = {
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  buyer: PropTypes.shape({
    buyer_id: PropTypes.string.isRequired,
  }).isRequired,
  selectedPaymentMethod: PropTypes.shape({
    platform: PropTypes.string.isRequired,
    shop_id: PropTypes.string.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(translated(PaygreenAddInstrumentForm));
