import _ from "lodash";
import { getLocale } from "redux-polyglot";
import { push } from "connected-react-router";
import { entrypoint as baseUrl } from "../config";
import history from "./routing/history";
import {
  EMAIL_ALREADY_EXISTS,
  FRIDGE_NOT_FOUND,
  FRIDGE_UNAVAILABLE,
  SALE_NOT_AUTHORIZED,
} from "./i18n/messages";
import storage from "./storage";

const convertResponseToFieldErrors = (response) => {
  const byPath = _.groupBy(
    response.violations,
    (violation) => violation.propertyPath
  );

  return _.mapValues(byPath, (violations) =>
    violations.map((violation) => violation.message).join(" / ")
  );
};

const checkStatus = async ({ response, store }) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const json = await response.clone().json();

  const error = new Error(
    json["hydra:description"] || json.detail || response.statusText
  );
  error.response = response;

  // Validation errors
  if (response.status === 400) {
    error.violations = convertResponseToFieldErrors(await response.json());
  }

  // eslint-disable-next-line no-console
  console.error("api error", error);

  // Login errors
  if (response.status === 401) {
    storage.removeItem("token");
    const loginPath = `/${history.location.pathname.split("/")[1]}/login`;
    if (history.location.pathname === loginPath && json.message) {
      error.violations = { email: json.message };
    } else {
      store && store.dispatch(push(loginPath));
    }
  }

  throw error;
};

class Client {
  constructor(entrypoint) {
    this.entrypoint = entrypoint;
    this.store = null;
  }

  setStore(store) {
    this.store = store;
  }

  timeoutFetchJSON(url, options = {}, timeout = 30000) {
    const controller = new AbortController();
    const { signal } = controller;
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    return this.fetchJSON(url, { ...options, signal }).then((response) => {
      clearTimeout(timeoutId);
      return response;
    });
  }

  fetchJSON(url, options = {}) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/ld+json",
      "Accept-Language": getLocale(this.store.getState()),
    };

    if (storage.getItem("token")) {
      headers.Authorization = `Bearer ${storage.getItem("token")}`;
    }

    // eslint-disable-next-line no-console
    console.debug("api request", url);

    return fetch(this.entrypoint + url, {
      ...options,
      headers,
    })
      .then((response) => checkStatus({ response, store: this.store }))
      .then((response) =>
        response.text().then((text) => (text ? JSON.parse(text) : ""))
      );
  }

  postJSON(url, body, options = {}) {
    return this.fetchJSON(url, {
      ...options,
      method: "POST",
      body: JSON.stringify(body),
    });
  }

  putJSON(url, body, options = {}) {
    return this.fetchJSON(url, {
      ...options,
      method: "PUT",
      body: JSON.stringify(body),
    });
  }

  deleteJSON(url, options = {}) {
    return this.fetchJSON(url, {
      ...options,
      method: "DELETE",
    });
  }

  register(restaurateurSlug, email, password, fridgeSlug = undefined) {
    return this.postJSON(
      `/restaurateurs/${restaurateurSlug}${
        fridgeSlug ? `/fridges/${fridgeSlug}` : ""
      }/register`,
      {
        email,
        password,
        terms: true,
      }
    ).catch((error) => {
      if (error.violations.email) {
        // eslint-disable-next-line no-param-reassign
        error.violations = { ...error.violations, email: EMAIL_ALREADY_EXISTS };
      }
      throw error;
    });
  }

  login(email, password) {
    return this.postJSON("/login", {
      username: email,
      password,
    }).then((response) => {
      storage.setItem("token", response.token);
      return this.me();
    });
  }

  logout() {
    storage.removeItem("token");
    return this.postJSON("/logout");
  }

  products(restaurateurSlug) {
    return this.fetchJSON(
      `/restaurateurs/${restaurateurSlug}/products?pagination=false`
    );
  }

  product(product) {
    return this.fetchJSON(`/products/${product}`);
  }

  handleToken(token) {
    return this.postJSON(`/handle_token/${token}`).then(
      (response) => response.type
    );
  }

  activateUser(token) {
    return this.postJSON(`/activate_user/${token}`)
      .then((response) => {
        storage.setItem("token", response.token);
      })
      .then(() => {
        return this.me();
      });
  }

  me() {
    return this.fetchJSON("/me");
  }

  restaurateur(slug) {
    return this.fetchJSON(`/restaurateurs/${slug}`);
  }

  forgotPassword(email, restaurateur) {
    return this.postJSON("/reset_password_requests", { email, restaurateur });
  }

  resetPassword(token, password) {
    return this.putJSON(`/reset_password/${token}`, { password });
  }

  timeoutFridge(restaurateurSlug, fridgeSlug, timeout) {
    return this.timeoutFetchJSON(
      `/restaurateurs/${restaurateurSlug}/fridges/${fridgeSlug}`,
      {},
      timeout
    ).catch((error) => {
      if (error.response?.status === 404) {
        throw new Error(FRIDGE_NOT_FOUND);
      }
      throw error;
    });
  }

  fridge(restaurateurSlug, fridgeSlug) {
    return this.fetchJSON(
      `/restaurateurs/${restaurateurSlug}/fridges/${fridgeSlug}`
    )
      .then((fridge) => {
        return fridge;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          throw new Error(FRIDGE_NOT_FOUND);
        }
        throw error;
      });
  }

  openFridge(restaurateurSlug, fridgeSlug, type, params = {}) {
    return this.postJSON(
      `/restaurateurs/${restaurateurSlug}/fridges/${fridgeSlug}/open`,
      {
        type,
        ...params,
      }
    ).catch((error) => {
      if (error.response.status === 404) {
        throw new Error(FRIDGE_NOT_FOUND);
      }
      if (error.message === "sale_is_not_authorized") {
        const authError = new Error(SALE_NOT_AUTHORIZED);
        authError.authorizationFailed = true;
        throw authError;
      }
      if ([403, 409].includes(error.response.status)) {
        throw new Error(FRIDGE_UNAVAILABLE);
      }
      throw error;
    });
  }

  getCurrentOrder(restaurateurSlug, fridgeSlug) {
    return this.fetchJSON(
      `/restaurateurs/${restaurateurSlug}/fridges/${fridgeSlug}/orders/current`
    );
  }

  timeOutGetOrder(orderId, timeout) {
    return this.timeoutFetchJSON(`/orders/${orderId}`, {}, timeout);
  }

  getOrder(orderId) {
    return this.fetchJSON(`/orders/${orderId}`);
  }

  getOrders(page) {
    return this.fetchJSON(`/orders?order[date]=desc&page=${page}`);
  }

  fridgeOffers(fridgeSlug, options = {}) {
    const optionsWithDefaults = { outdated: false, ...options };

    const formatDate = (date) => {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join("-");
    };

    const outdatedParam = optionsWithDefaults.outdated
      ? `&expireAt[strictly_before]=${formatDate(new Date())}`
      : "";
    return this.fetchJSON(
      `/fridges/${fridgeSlug}/offers?pagination=false${outdatedParam}`
    );
  }

  fridgesViews(restaurateurSlug, page) {
    return this.fetchJSON(
      `/fridge_views?fridge.restaurateur.slug=${restaurateurSlug}&order[lastSeenAt]=desc&page=${page}`
    );
  }

  sendOrderByMail(orderId) {
    return this.postJSON(`/orders/${orderId}/mail`);
  }

  addCard(token) {
    return this.postJSON("/cards", { token });
  }

  cards(providers) {
    const params = providers
      ? providers.map((provider) => `provider[]=${provider}`)
      : [];
    params.push("pagination=false");
    return this.fetchJSON(`/cards?${params.join("&")}`);
  }

  tokenize(data) {
    return this.postJSON("/adyen/tokenize", { data });
  }

  getAvailablePaymentMethods(slug) {
    return this.fetchJSON(`/adyen/available-payment-methods/${slug}`);
  }

  paygreenBuyer(data) {
    return this.postJSON(`/paygreen/buyer`, { data });
  }

  saveBillingAddress(buyer, data) {
    return this.postJSON(`/paygreen/buyer/${buyer.id}`, { data });
  }

  paygreenNotificationCard(pid) {
    return this.postJSON(`/paygreen/notifications/card?pid=${pid}`);
  }

  getPaygreenAvailablePaymentMethods(restaurateurSlug) {
    return this.fetchJSON(
      `/paygreen/available-payment-methods/${restaurateurSlug}`
    );
  }

  getEdenredAuthorizationUrl(restaurateurSlug, fridgeSlug) {
    return this.fetchJSON(
      `/edenred/authorization-url/${restaurateurSlug}?fridge=${
        fridgeSlug || ""
      }`
    );
  }

  makePaymentDetailsCall(data) {
    return this.postJSON("/adyen/payment-details", { data });
  }

  card(cardId) {
    return this.fetchJSON(`/cards/${cardId}`);
  }

  deleteCard(cardId) {
    return this.deleteJSON(`/cards/${cardId}`);
  }

  cardUp(cardId) {
    return this.postJSON(`/cards/${cardId}/position/up`);
  }

  cardDown(cardId) {
    return this.postJSON(`/cards/${cardId}/position/down`);
  }

  activateTerminal(fridgeSlug) {
    return this.postJSON(`/fridges/${fridgeSlug}/activate_terminal`);
  }
}

export default new Client(baseUrl);
