/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Card = ({ className }) => (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M24 2.01058V16.9894C24 18.0952 23.0847 19 21.9661 19H2.0339C0.915254 19 0 18.0952 0 16.9894V2.01058C0 0.904762 0.915254 0 2.0339 0H22.0678C23.0847 0 24 0.904762 24 2.01058ZM1.62712 2.01058V4.8254H22.3729V2.01058C22.3729 1.80952 22.1695 1.60847 21.9661 1.60847H2.0339C1.83051 1.60847 1.62712 1.80952 1.62712 2.01058ZM22.3729 16.9894V9.44973H1.62712V16.9894C1.62712 17.1905 1.83051 17.3915 2.0339 17.3915H22.0678C22.1695 17.3915 22.3729 17.1905 22.3729 16.9894ZM3.15254 14.2751H6.30508V15.8836H3.15254V14.2751ZM8.0339 14.2751H12.8136V15.8836H8.0339V14.2751Z"
      fill="#2A2A72"
    />
  </svg>
);

Card.propTypes = {
  className: PropTypes.string,
};

Card.defaultProps = {
  className: null,
};

export default Card;
