import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import Gutter from "../gutter/Gutter";
import translated from "../../services/i18n/translated";
import Page, { PageContent } from "../common/Page";
import ListView from "../common/list/ListView";
import {
  CONTACT,
  LANGS,
  LOGIN_ACTION,
  LOGOUT,
  MY_FRIDGE,
  MY_PAYMENT_CARDS,
  MY_PURCHASES,
  REGISTER_ACTION,
  RECENT_FRIDGE,
} from "../../services/i18n/messages";
import { isClient } from "../../services/helpers/roles";
import LinkButton from "../common/list/buttons/LinkButton";
import EmailButton from "../common/list/buttons/EmailButton";
import { contactEmail } from "../../config";
import LogoutButton from "../common/list/buttons/LogoutButton";
import Card from "../common/icons/Card";
import Favorite from "../common/icons/Favorite";
import Lang from "../common/icons/Lang";
import Fridge from "../common/icons/Fridge";
import Profile from "../common/icons/Profile";
import Login from "../common/icons/Login";
import Purchase from "../common/icons/Purchase";

const Menu = ({ translate, user, restaurateur }) => (
  <Gutter>
    <Page>
      <PageContent>
        <ListView>
          <LinkButton href={`/${restaurateur.slug}/fridge`} icon={<Fridge />}>
            <span>{translate(MY_FRIDGE)}</span>
          </LinkButton>
          {user && isClient(user) && (
            <LinkButton
              href={`/${restaurateur.slug}/recent-fridges`}
              icon={<Favorite />}
            >
              <span>{translate(RECENT_FRIDGE)}</span>
            </LinkButton>
          )}
          {user && isClient(user) && (
            <LinkButton href={`/${restaurateur.slug}/cards`} icon={<Card />}>
              <span>{translate(MY_PAYMENT_CARDS)}</span>
            </LinkButton>
          )}
          {user && isClient(user) && (
            <LinkButton
              href={`/${restaurateur.slug}/purchases`}
              icon={<Purchase />}
            >
              <span>{translate(MY_PURCHASES)}</span>
            </LinkButton>
          )}
          {!user && (
            <LinkButton
              href={`/${restaurateur.slug}/register`}
              icon={<Profile />}
            >
              <span>{translate(REGISTER_ACTION)}</span>
            </LinkButton>
          )}
          {!user && (
            <LinkButton href={`/${restaurateur.slug}/login`} icon={<Login />}>
              <span>{translate(LOGIN_ACTION)}</span>
            </LinkButton>
          )}
          <LinkButton href={`/${restaurateur.slug}/langs`} icon={<Lang />}>
            <span>{translate(LANGS)}</span>
          </LinkButton>
          <EmailButton email={contactEmail}>
            <span>{translate(CONTACT)}</span>
          </EmailButton>
          {user && (
            <LogoutButton restaurateur={restaurateur}>
              <span>{translate(LOGOUT)}</span>
            </LogoutButton>
          )}
        </ListView>
      </PageContent>
    </Page>
  </Gutter>
);

Menu.propTypes = {
  restaurateur: PropTypes.shape({}).isRequired,
};

Menu.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

Menu.defaultProps = {
  user: null,
};

const mapState = (state) => ({
  user: state.user,
});

export default connect(mapState, null)(translated(Menu));
