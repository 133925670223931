import React from "react";
import * as PropTypes from "prop-types";
import styles from "./page.module.css";

const Page = ({ children }) => <div className={styles.page}>{children}</div>;

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PageContent = ({ children, center }) => (
  <div className={[styles["page-content"], center && styles.center].join(" ")}>
    {children}
  </div>
);

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

PageContent.defaultProps = {
  center: true,
};

export default Page;
