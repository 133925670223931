import * as React from "react";

/* eslint-disable max-len */
const SvgComponent = (props) => (
  <svg
    viewBox="0 0 108 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.146 5.757.48 5.403.273 3.83c.41-1.3 1.026-2.599 1.504-3.83L7.73 5.688h2.462l.684 5.335.41 3.967 1.368-3.967 2.052-5.266h3.01L12.243 17.59H9.507l-.684-4.788c-.205-1.3-.342-2.531-.41-3.83L6.84 12.87 4.65 17.658H1.984L0 5.757h3.146Zm16.552 6.771c-.273 2.12 1.3 3.078 3.215 3.078a9.577 9.577 0 0 0 3.352-.547l.136 2.052c-1.368.547-2.735.752-4.24.752-3.83 0-5.814-2.394-5.267-6.019.479-3.283 2.873-6.36 6.703-6.36 3.899 0 4.651 3.145 4.31 5.745 0 .478-.138.889-.274 1.3h-7.935Zm5.472-2.052c.205-1.163 0-2.94-2.052-2.94-1.846 0-2.872 1.64-3.146 2.872h5.267l-.069.068Zm36.456 7.182 1.368-9.644h-1.642l.342-2.257h1.573l.069-.547a6.498 6.498 0 0 1 2.052-4.036 5.472 5.472 0 0 1 5.13-.889l-.547 2.394a2.683 2.683 0 0 0-1.232-.274c-1.504 0-2.188 1.3-2.394 2.736l-.068.616h2.6l-.343 2.257h-2.599l-1.368 9.576h-2.941v.068Zm18.74-6.087c-.615 4.377-3.898 6.292-6.976 6.292-3.351 0-5.608-2.257-5.061-6.156.547-3.83 3.42-6.224 7.045-6.224 3.488 0 5.472 2.463 4.993 6.156v-.068Zm-8.89.136c-.343 2.257.546 3.967 2.325 3.967 1.71 0 3.146-1.641 3.488-4.103.274-1.779-.274-3.9-2.326-3.9-2.051 0-3.283 2.053-3.556 4.036h.068Zm21.613-.136c-.684 4.377-3.967 6.292-7.045 6.292-3.352 0-5.609-2.257-5.062-6.156.548-3.83 3.42-6.224 7.045-6.224 3.557 0 5.54 2.463 5.062 6.156v-.068Zm-9.029.136c-.273 2.257.616 3.967 2.394 3.967 1.71 0 3.147-1.641 3.489-4.103.273-1.779-.274-3.9-2.326-3.9s-3.283 2.053-3.557 4.036ZM107.247.285l-1.984 14.021-.341 3.284h-2.736l.205-1.847h-.069a4.993 4.993 0 0 1-4.172 2.12c-2.873 0-4.788-2.394-4.24-6.019.547-4.035 3.42-6.36 6.155-6.36 1.71 0 2.736.683 3.147 1.572h.068l.958-6.771h3.009Zm-4.446 10.396V9.93a2.196 2.196 0 0 0-.689-1.528 2.193 2.193 0 0 0-1.568-.592c-1.915 0-3.283 1.71-3.557 3.898-.342 2.19.548 3.762 2.463 3.762a3.146 3.146 0 0 0 2.872-2.052l.206-.957.273-1.779ZM39.261 1.79h2.667l-1.642 9.028-2.257-2.052L39.26 1.79Z"
      fill="#1F3360"
    />
    <path
      d="M29.07 12.87a7.524 7.524 0 0 1 6.223-6.976c3.42-.548 6.156 1.71 6.088 5.061a7.523 7.523 0 0 1-6.293 6.908c-3.42.547-6.156-1.71-6.019-5.061v.068Zm9.301-1.436c0-1.71-1.368-2.873-3.078-2.6a3.899 3.899 0 0 0-3.283 3.557c0 1.71 1.368 2.873 3.147 2.6a3.899 3.899 0 0 0 3.214-3.557Zm17.1-5.609-.548 2.941c1.573.137 2.6 1.505 2.326 3.078a3.899 3.899 0 0 1-3.694 3.147 2.53 2.53 0 0 1-2.599-2.942h-2.804l-.205 1.095c.137 2.736 2.188 4.788 5.13 4.788 3.351 0 6.566-2.736 7.113-6.088.616-3.214-1.505-5.882-4.72-6.019Z"
      fill="#1F3360"
    />
    <path
      d="M43.911 17.932c-3.42 0-5.609-2.736-4.993-6.088a7.524 7.524 0 0 1 7.113-6.019c3.42 0 5.609 2.736 4.993 6.02A7.523 7.523 0 0 1 43.911 18v-.068Zm1.642-9.166a3.898 3.898 0 0 0-3.694 3.078c-.342 1.779.82 3.147 2.6 3.147a3.899 3.899 0 0 0 3.624-3.147c.41-1.71-.82-3.078-2.53-3.078Z"
      fill="#1F3360"
    />
    <path
      d="M55.197 5.825c-2.942 0-5.746 2.052-6.84 4.788l-.137.957h2.873a3.899 3.899 0 0 1 3.556-2.804c1.779 0 2.873 1.368 2.6 3.146a3.898 3.898 0 0 1-3.489 3.078l-.547 2.941a7.523 7.523 0 0 0 6.977-6.019c.615-3.42-1.642-6.155-4.993-6.155v.068Z"
      fill="#1F3360"
    />
  </svg>
);

export default SvgComponent;
