export default {
  state: null,
  reducers: {
    login: (state, payload) => payload,
    logout: () => null,
  },
  effects: (dispatch) => ({
    login(_, rootState) {
      dispatch.fridge.refresh();
      dispatch.offers.load();
    },
  }),
};
