import React from "react";
import PropTypes from "prop-types";
import styles from "./bottomcontainer.module.css";

const BottomContainer = ({ children }) => (
  <div className={styles.bottom}>
    <div>{children}</div>
  </div>
);

BottomContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BottomContainer;
