import React from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import Menu from "../icons/Menu";
import { toggle } from "../../burger/actions";
import styles from "./button.module.scss";
import Close from "../icons/Close";

const MenuButton = ({ toggleBurger, opened, color }) => (
  <button
    className={styles["menu-button"]}
    type="button"
    onClick={toggleBurger}
    style={color ? { color } : null}
  >
    {opened ? <Close /> : <Menu />}
  </button>
);

const mapStateToProps = (state) => ({
  opened: state.burger.opened,
});
const mapDispatchToProps = (dispatch) => ({
  toggleBurger: () => dispatch(toggle()),
});

MenuButton.propTypes = {
  opened: PropTypes.bool.isRequired,
  toggleBurger: PropTypes.func.isRequired,
  color: PropTypes.string,
};

MenuButton.defaultProps = {
  color: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
