import React from "react";
import * as PropTypes from "prop-types";
import linkButtonStyles from "./linkbutton.module.css";
import styles from "./emailbutton.module.css";
import Chevron from "../../icons/Chevron";
import Contact from "../../icons/Contact";

const EmailButton = ({ email, children, onClicked }) => (
  <a
    href={`mailto:${email}`}
    onClick={onClicked}
    target="_blank"
    rel="noopener noreferrer"
    className={[styles.button, linkButtonStyles.button].join(" ")}
  >
    <div className={linkButtonStyles.icon}>
      <Contact />
    </div>
    {children}
    <Chevron className={linkButtonStyles.chevron} />
  </a>
);

EmailButton.propTypes = {
  email: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClicked: PropTypes.func,
};

EmailButton.defaultProps = {
  onClicked: () => {},
};

export default EmailButton;
