import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

const getDisplayName = (Component) =>
  Component.displayName || Component.name || "Component";

export default (
  Component,
  {
    displayNavBar = true,
    backButton = false,
    backPath = null,
    menuButton = true,
    displayFooter = true,
    footerLink = true,
    properties = {},
  } = {}
) => {
  const Configured = ({ navBar, footer, ...props }) => {
    const params = useParams();
    useEffect(() => {
      navBar[backButton ? "showBackButton" : "hideBackButton"]();
      navBar[menuButton ? "showMenuButton" : "hideMenuButton"]();
      navBar.setBackPath(backPath);
      navBar[displayNavBar ? "show" : "hide"]();
      footer[displayFooter ? "show" : "hide"]();
      footer[footerLink ? "enableLink" : "disableLink"]();
    });
    return <Component {...properties} {...props} {...params} />;
  };
  Configured.displayName = `Configured${getDisplayName(Component)}`;
  Configured.propTypes = {
    navBar: PropTypes.shape({
      showBackButton: PropTypes.func.isRequired,
      hideBackButton: PropTypes.func.isRequired,
      showMenuButton: PropTypes.func.isRequired,
      hideMenuButton: PropTypes.func.isRequired,
      setBackPath: PropTypes.func.isRequired,
      show: PropTypes.func.isRequired,
      hide: PropTypes.func.isRequired,
    }).isRequired,
    footer: PropTypes.shape({
      show: PropTypes.func.isRequired,
      hide: PropTypes.func.isRequired,
      enableLink: PropTypes.func.isRequired,
      disableLink: PropTypes.func.isRequired,
    }).isRequired,
  };

  const mapDispatchToProps = (dispatch) => ({
    navBar: dispatch.navBar,
    footer: dispatch.footer,
  });

  return connect(null, mapDispatchToProps)(Configured);
};
