/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Available = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 16C3 9.37321 8.37321 4 15 4C18.0429 4 20.8215 5.13296 22.937 7L21.5 8.44841C19.7549 6.94488 17.4833 6.03571 15 6.03571C9.49821 6.03571 5.03571 10.4982 5.03571 16C5.03571 21.5018 9.49821 25.9643 15 25.9643C20.5018 25.9643 24.9643 21.5018 24.9643 16C24.9643 15.2434 24.8799 14.5065 24.72 13.7981L26.3682 12.148C26.7779 13.3571 27 14.6526 27 16C27 22.6268 21.6268 28 15 28C8.37321 28 3 22.6268 3 16Z"
      fill="inherit"
    />
    <rect
      x="12.7574"
      y="20.0711"
      width="16"
      height="2"
      transform="rotate(-45 12.7574 20.0711)"
      fill="inherit"
    />
    <rect
      x="9.92896"
      y="14.4142"
      width="8"
      height="2"
      transform="rotate(45 9.92896 14.4142)"
      fill="inherit"
    />
  </svg>
);

Available.propTypes = {
  className: PropTypes.string,
};

Available.defaultProps = {
  className: null,
};

export default Available;
