/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Profile = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="28"
      height="28"
    >
      <circle cx="15" cy="16" r="14" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <circle cx="15" cy="16" r="14" fill="#2A2A72" />
      <circle cx="15" cy="16" r="14" fill="white" fillOpacity="0.8" />
      <path
        d="M15 7.18182C13.3636 7.18182 12.0852 7.7841 11.1648 8.98864C10.2443 10.1932 9.78409 11.733 9.78409 13.608C9.77273 15.9375 10.5227 17.7841 12.0341 19.1477C12.2273 19.3296 12.2955 19.5625 12.2386 19.8466L11.8466 20.6648C11.7216 20.9375 11.5369 21.1506 11.2926 21.304C11.0483 21.4574 10.5341 21.6761 9.75 21.9602C9.71591 21.9716 8.99716 22.2046 7.59375 22.6591C6.19034 23.1136 5.44318 23.3636 5.35227 23.4091C4.39773 23.8068 3.77273 24.2216 3.47727 24.6534C3.15909 25.3693 3 27.1818 3 30.0909H27C27 27.1818 26.8409 25.3693 26.5227 24.6534C26.2273 24.2216 25.6023 23.8068 24.6477 23.4091C24.5568 23.3636 23.8097 23.1136 22.4062 22.6591C21.0028 22.2046 20.2841 21.9716 20.25 21.9602C19.4659 21.6761 18.9517 21.4574 18.7074 21.304C18.4631 21.1506 18.2784 20.9375 18.1534 20.6648L17.7614 19.8466C17.7045 19.5625 17.7727 19.3296 17.9659 19.1477C19.4773 17.7841 20.2273 15.9375 20.2159 13.608C20.2159 11.733 19.7557 10.1932 18.8352 8.98864C17.9148 7.7841 16.6364 7.18182 15 7.18182Z"
        fill="#2A2A72"
      />
    </g>
  </svg>
);

Profile.propTypes = {
  className: PropTypes.string,
};

Profile.defaultProps = {
  className: null,
};

export default Profile;
