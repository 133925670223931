import React from "react";
import * as PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import configure from "../../services/routing/configure";
import Fridge from "../fridge/Fridge";
import OpenFridge from "../open-fridge/OpenFridge";
import ChooseProduct from "../choose-product/ChooseProduct";
import OutdatedOffers from "../outdated-offers/OutdatedOffers";
import NotFound from "../not-found/NotFound";
import ActivateUser from "../activate-user/ActivateUser";
import NoInteractionFridge from "../fridge/NoInteractionFridge";
import {
  TYPE_BUY,
  TYPE_DELIVER,
  TYPE_MAINTENANCE,
  TYPE_REMOVE,
} from "../../services/helpers/order";

const KioskFridge = configure(NoInteractionFridge, {
  menuButton: false,
  footerLink: false,
});

const FridgeRoutes = ({ restaurateur, fridge }) => (
  <Switch>
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}`}
      component={configure(Fridge, {
        backButton: true,
        backPath: `/${restaurateur.slug}`,
        properties: { restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/open`}
      component={configure(OpenFridge, {
        properties: { type: TYPE_BUY, restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/open/deliver/:product`}
      component={configure(OpenFridge, {
        properties: { type: TYPE_DELIVER, restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/open/remove`}
      component={configure(OpenFridge, {
        properties: { type: TYPE_REMOVE, restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/open/maintenance`}
      component={configure(OpenFridge, {
        properties: { type: TYPE_MAINTENANCE, restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/choose_product`}
      component={configure(ChooseProduct, {
        properties: { restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/outdated_products`}
      component={configure(OutdatedOffers, {
        backButton: true,
        properties: { restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/activate-user/:token`}
      component={configure(ActivateUser, {
        properties: { restaurateur, fridge },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/${fridge.slug}/no-interaction`}
      render={() => <KioskFridge fridge={fridge} restaurateur={restaurateur} />}
    />
    <Route component={NotFound} />
  </Switch>
);

FridgeRoutes.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    opened: PropTypes.bool.isRequired,
    lastOpeningErrorCode: PropTypes.string,
    lastOpeningErrorDate: PropTypes.string,
  }).isRequired,
};

export default FridgeRoutes;
