export default {
  state: {
    visible: true,
    link: true,
  },
  reducers: {
    show: (state) => ({ ...state, visible: true }),
    hide: (state) => ({ ...state, visible: false }),
    enableLink: (state) => ({ ...state, link: true }),
    disableLink: (state) => ({ ...state, link: false }),
  },
};
