import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import {
  PAY_WITH_CARD,
  SCAN_QR_CODE,
  AUTHORIZATION_DISCLAIMER,
  ACCEPTED_PAYMENT_METHODS,
  SWIPE_CARD,
  SCAN_QR_CODE_ALT,
  CANCEL,
  PAY_WITH_APP,
  NAME_OF_FRIDGE,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import { useIsMedium, useIsLarge } from "../hooks";

import danger from "../../assets/danger.png";
import popupStyles from "./fridgepopup.module.scss";
import customStyles from "./paymentmethod.module.scss";
import LogoBimpl from "../common/icons/LogoBimpl";
import LogoRestoFlash from "../common/icons/LogoRestoFlash";
import LogoUpDejeuner from "../common/icons/LogoUpDejeuner";
import LogoPassRestaurant from "../common/icons/LogoPassRestaurant";
import LogoWeDoFood from "../common/icons/LogoWeDoFood";
import LogoSwile from "../common/icons/LogoSwile";
import LogoVisa from "../common/icons/LogoVisa";
import LogoMasterCard from "../common/icons/LogoMasterCard";
import LogoCB from "../common/icons/LogoCB";

import Close from "../common/icons/Close";
import Button from "../common/buttons/Button";
import Swipe from "../common/icons/Swipe";
import Edenred from "../common/icons/Edenred";

const styles = { ...popupStyles, ...customStyles };

export const PaymentMethodPopup = (props) => {
  const { children, onClose, title, footer } = props;

  const wrapperRef = useRef();

  useEffect(() => {
    wrapperRef.current.focus();
  });

  useEffect(() => {
    setTimeout(onClose, 30000);
  }, [onClose]);

  return ReactDOM.createPortal(
    <div
      ref={wrapperRef}
      className={styles.wrapper}
      role="button"
      tabIndex={0}
      onClick={() => onClose && onClose()}
      onKeyPress={() => onClose && onClose()}
    >
      <div className={`${styles.popup} ${styles.paymentMethodPopup}`}>
        <div className={styles.popupHeader}>
          <h3>{title}</h3>
        </div>
        <div className={styles.popupBody}>
          <>{children}</>
        </div>
        {footer && (
          <div className={styles.popupFooter}>
            <>{footer}</>
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};

export const PaymentMethodCard = translated((props) => {
  const { translate } = props;

  const footer = (
    <Button className={styles.popupCloseBtn}>
      <Close />
      <span>{translate(CANCEL)}</span>
    </Button>
  );

  return (
    <PaymentMethodPopup
      title={translate(PAY_WITH_CARD)}
      footer={footer}
      {...props}
    >
      <p className={styles.popupSubtitle}>{translate(SWIPE_CARD)}</p>
      <p>
        <Swipe />
      </p>
      <div className={styles.paymentMethodCards}>
        <p>{translate(ACCEPTED_PAYMENT_METHODS)}</p>
        <ul>
          <li>
            <LogoCB />
          </li>
          <li>
            <LogoVisa />
          </li>
          <li>
            <LogoMasterCard />
          </li>
          <li>
            <Edenred />
          </li>
        </ul>
      </div>
      <div className={styles.authorizationDisclaimer}>
        <img src={danger} alt="Authorization info" />
        <span>{translate(AUTHORIZATION_DISCLAIMER)}</span>
      </div>
    </PaymentMethodPopup>
  );
});

export const PaymentMethodApp = translated((props) => {
  const { translate, url, restaurateur, fridge } = props;
  const isMedium = useIsMedium();
  const isLarge = useIsLarge();

  const footer = (
    <Button className={styles.popupCloseBtn}>
      <Close />
      <span>{translate(CANCEL)}</span>
    </Button>
  );

  return (
    <PaymentMethodPopup
      title={translate(PAY_WITH_APP)}
      footer={footer}
      {...props}
    >
      <p className={styles.popupSubtitle}>{translate(SCAN_QR_CODE)}</p>
      <p>
        <QRCode
          fgColor="#0C0F0A"
          size={isLarge ? 360 : isMedium ? 280 : 150}
          value={url}
        />
      </p>
      <p className={styles.qrcodeAlt}>
        <span>{translate(SCAN_QR_CODE_ALT)}</span>
        <span>{`www.eatmachine.com/${restaurateur.slug}`}</span>
        <span>{translate(NAME_OF_FRIDGE, { name: fridge.name })}</span>
      </p>
      <div className={styles.paymentMethodApp}>
        <p>{translate(ACCEPTED_PAYMENT_METHODS)}</p>
        <ul>
          <li>
            <LogoBimpl />
          </li>
          <li>
            <LogoPassRestaurant />
          </li>
          <li>
            <LogoUpDejeuner />
          </li>
          <li>
            <LogoSwile />
          </li>
          <li>
            <LogoRestoFlash />
          </li>
          <li>
            <LogoWeDoFood />
          </li>
        </ul>
      </div>
    </PaymentMethodPopup>
  );
});
