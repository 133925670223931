/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Logout = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 26H9C7.9 26 7 25.1 7 24V8C7 6.9 7.9 6 9 6H15C16.1 6 17 6.9 17 8V10H15V8H9V24H15V22H17V24C17 25.1 16.1 26 15 26Z"
      fill="inherit"
    />
    <path
      d="M17.8274 19.59L19.1852 21L24 16L19.1852 11L17.8274 12.41L20.3119 15H11V17H20.3119L17.8274 19.59Z"
      fill="inherit"
    />
  </svg>
);

Logout.propTypes = {
  className: PropTypes.string,
};

Logout.defaultProps = {
  className: null,
};

export default Logout;
