import { SET_TUTORIAL_SEEN, SET_TUTORIAL_NOT_SEEN } from "./actionTypes";

const initialState = {
  seen: window.location.href.includes("no-interaction"),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TUTORIAL_SEEN:
      return { ...state, seen: true };
    case SET_TUTORIAL_NOT_SEEN:
      return { ...state, seen: false };
    default:
      return state;
  }
};
