import { EventSourcePolyfill } from "event-source-polyfill";
import client from "../client";
import { mercureEndpoint } from "../../config";
import storage from "../storage";

const contextSSE = {
  eventSource: null,
};

const subscribeSSE = ({ dispatch }, slug) => {
  unsubscribeSSE();

  const url = new URL(mercureEndpoint);
  url.searchParams.append("topic", `https://example.com/restaurateurs/${slug}`);
  url.searchParams.append(
    "topic",
    `https://example.com/_update/client/${slug}`
  );

  const eventSource = new EventSourcePolyfill(url.toString(), {
    lastEventIdQueryParameterName: "Last-Event-Id",
    heartbeatTimeout: 3600 * 1000,
  });

  eventSource.onmessage = (event) => {
    const sseData = JSON.parse(event.data);

    // eslint-disable-next-line no-console
    console.debug("restaurateur mercure subscription event", sseData);

    if (sseData && sseData["@type"]) {
      if (sseData["@type"] === "Restaurateur") {
        dispatch.restaurateur.setData(sseData);
      }
      if (sseData["@type"] === "_Update") {
        window.location.reload();
      }
    }
  };

  eventSource.onerror = (err) => {
    // eslint-disable-next-line no-console
    console.log("restaurateur mercure subscription error", err);
  };

  contextSSE.eventSource = eventSource;
};

const unsubscribeSSE = () => {
  if (contextSSE.eventSource) {
    contextSSE.eventSource.close();
  }
};

const hashData = (data) => JSON.stringify(data);

export default {
  state: {
    slug: null,
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    setData: (state, data) => {
      if (!data) {
        unsubscribeSSE();
      }
      if (hashData(state.data) === hashData(data)) return state;
      return { ...state, data };
    },
    setSlug: (state, slug) => {
      storage.setItem("restaurateurSlug", slug);
      return { ...state, slug };
    },
    setLoading: (state, loading) => ({ ...state, loading }),
    setError: (state, error) => ({ ...state, error }),
  },
  effects: (dispatch) => ({
    async load({ slug, refresh }) {
      if (!refresh) {
        this.setSlug(slug);
        this.setLoading(true);
      }
      this.setError(null);
      try {
        const data = await client.restaurateur(slug);
        this.setData(data);
        subscribeSSE({ dispatch }, slug);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("load restaurateur failed", err);
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },
    refresh(_, rootState) {
      if (!rootState.restaurateur.data) return;

      dispatch.restaurateur.load({
        slug: rootState.restaurateur.data.slug,
        refresh: true,
      });
    },
  }),
};
