import _ from "lodash";
import {
  INVALID_EMAIL_ADRESS_VALUE,
  REQUIRED_VALUE,
} from "../../services/i18n/messages";

const assert = (condition, message) => (condition ? null : message);

const validationsRules = {
  required: (value) => assert(value !== "", REQUIRED_VALUE),
  email: (value) =>
    assert(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value),
      INVALID_EMAIL_ADRESS_VALUE
    ),
  checked: (value) => assert(value === true, REQUIRED_VALUE),
};

const validateValue = (value, rules) =>
  rules
    .map((rule) => validationsRules[rule](value))
    .filter((message) => message != null)
    .join(" / ");

const createValidator = (validationRules) => (values) =>
  _.pickBy(
    _.mapValues(validationRules, (rules, field) =>
      validateValue(values[field], rules)
    ),
    (value) => value !== ""
  );

export default createValidator;
