import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import BigText from "../common/BigText";
import Button from "../common/buttons/Button";
import {
  BACK_ACTION,
  LOADING,
  OPEN_ACTION,
  RETRY,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import Page, { PageContent } from "../common/Page";
import BottomContainer from "../common/BottomContainer";
import redirect from "../../services/routing/redirect";

const OpenFridge = ({
  translate,
  loading,
  error,
  retry,
  restaurateur,
  fridge,
  noInteraction,
}) => {
  // eslint-disable-next-line no-console
  console.log("render OpenFridge");

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  return (
    <Page>
      {error && !noInteraction && (
        <>
          <PageContent>
            <BigText>{translate(error.message)}</BigText>
          </PageContent>
          <BottomContainer>
            {error.authorizationFailed && (
              <Button
                onClick={() => redirect(`/${restaurateur.slug}/${fridge.slug}`)}
                primary
              >
                {translate(BACK_ACTION)}
              </Button>
            )}
            {!error.authorizationFailed && (
              <Button onClick={() => retry()} primary>
                {translate(RETRY)}
              </Button>
            )}
          </BottomContainer>
        </>
      )}
      {error && noInteraction && (
        <>
          <PageContent>
            <BigText>{translate(error.message)}</BigText>
          </PageContent>
          <BottomContainer>
            <Button
              onClick={() =>
                redirect(`/${restaurateur.slug}/${fridge.slug}/no-interaction`)
              }
              primary
            >
              {translate(BACK_ACTION)}
            </Button>
          </BottomContainer>
        </>
      )}
      {!error && (
        <PageContent>
          <BigText>
            <Button onClick={() => retry()} primary>
              {translate(OPEN_ACTION)}
            </Button>
          </BigText>
        </PageContent>
      )}
    </Page>
  );
};

OpenFridge.propTypes = {
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  retry: PropTypes.func.isRequired,
  noInteraction: PropTypes.bool.isRequired,
};

OpenFridge.defaultProps = {
  error: null,
  loading: true,
};

const mapStateToProps = (state) => ({
  loading: state.door.loading,
  error: state.door.error,
  noInteraction: !!state.kiosk.slug,
});

const mapDispatchToProps = (dispatch) => ({
  retry: dispatch.door.retry,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translated(OpenFridge));
