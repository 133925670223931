import React from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import styles from "./navbar.module.scss";
import { ReactComponent as Logo } from "../../assets/eatmachine_white.svg";
import BackButton from "../common/buttons/BackButton";
import MenuButton from "../common/buttons/MenuButton";
import { getHexBrightness } from "../../services/helpers/color";

const NavBar = ({
  backButton,
  backPath,
  visible,
  menuButton,
  logo,
  bgColor,
}) => {
  if (!visible) {
    return null;
  }

  let color = "#FFFFFF";
  try {
    if (bgColor && getHexBrightness(bgColor) > 200) {
      color = "#000000";
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  return (
    <div
      style={bgColor ? { background: bgColor } : null}
      className={styles.navbar}
    >
      {backButton && (
        <div className={styles.backButton}>
          <BackButton color={color} redirectPath={backPath} />
        </div>
      )}
      {logo ? (
        <img src={logo} className={styles.logo} alt="Logo" />
      ) : (
        <Logo className={styles.logo} />
      )}
      {menuButton && (
        <div className={styles.menuButton}>
          <MenuButton color={color} />
        </div>
      )}
    </div>
  );
};

NavBar.propTypes = {
  backButton: PropTypes.bool.isRequired,
  menuButton: PropTypes.bool.isRequired,
  backPath: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  bgColor: PropTypes.string,
};

NavBar.defaultProps = {
  backPath: null,
  logo: null,
  bgColor: null,
};

const mapStateToProps = (state) => ({
  backButton: state.navBar.backButton,
  menuButton: state.navBar.menuButton,
  backPath: state.navBar.backPath,
  visible: state.navBar.visible,
});

export default connect(mapStateToProps)(NavBar);
