import React from "react";
import PropTypes from "prop-types";
import ListView from "../common/list/ListView";
import {
  RECENT_FRIDGE,
  LOADING,
  ERROR,
  LOAD_MORE,
  EMPTY_RECENT_FRIDGE,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import LinkButton from "../common/list/buttons/LinkButton";
import client from "../../services/client";
import BigText from "../common/BigText";
import Fridge from "../common/icons/Fridge";
import PromiseButton from "../common/buttons/PromiseButton";
import { usePaginated } from "../hooks";

const RecentFridgeList = ({
  translate,
  restaurateur,
  className,
  displayEmptyList,
}) => {
  const [
    [fridgeViews, totalCount],
    loading,
    error,
    nextPage,
  ] = usePaginated((page) => client.fridgesViews(restaurateur.slug, page));

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  if (fridgeViews.length === 0) {
    return displayEmptyList ? (
      <BigText>{translate(EMPTY_RECENT_FRIDGE)}</BigText>
    ) : null;
  }

  const actions = fridgeViews.map((fridgeView, index) => (
    <LinkButton
      key={index}
      href={`/${restaurateur.slug}/${fridgeView.fridge.slug}`}
      icon={<Fridge />}
    >
      <span>{fridgeView.fridge.slug}</span>
    </LinkButton>
  ));

  if (fridgeViews.length < totalCount) {
    actions.push(
      <PromiseButton key="action" primaryStyle promise={nextPage}>
        {translate(LOAD_MORE)}
      </PromiseButton>
    );
  }

  return (
    <ListView title={translate(RECENT_FRIDGE)} className={className}>
      {actions}
    </ListView>
  );
};

RecentFridgeList.propTypes = {
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  displayEmptyList: PropTypes.bool,
};

RecentFridgeList.defaultProps = {
  className: null,
  displayEmptyList: true,
};

export default translated(RecentFridgeList);
