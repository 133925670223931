import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./fridge.module.scss";
import {
  ERROR,
  FRIDGE_OFFERS,
  LOADING,
  LOGIN_ACTION,
  REGISTER_ACTION,
} from "../../services/i18n/messages";
import LoginButton from "../common/buttons/LoginButton";
import RegisterButton from "../common/buttons/RegisterButton";
import translated from "../../services/i18n/translated";
import BigText from "../common/BigText";
import { offersToProductList } from "../../services/helpers/offer";
import ProductList from "../common/ProductList";
import ProductPopup from "../fridge-popup/ProductPopup";

const GuestFridge = ({
  translate,
  restaurateur,
  fridge,
  loading,
  error,
  products,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  return (
    <>
      {selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
      <div className={styles.container}>
        <div className={styles.buttons}>
          <LoginButton restaurateur={restaurateur} primary>
            {translate(LOGIN_ACTION)}
          </LoginButton>
          <RegisterButton restaurateur={restaurateur}>
            {translate(REGISTER_ACTION)}
          </RegisterButton>
        </div>
      </div>
      {products.length > 0 && (
        <ProductList
          title={translate(FRIDGE_OFFERS)}
          products={products}
          onProductClick={(product) => {
            setSelectedProduct(product);
          }}
        />
      )}
    </>
  );
};

GuestFridge.propTypes = {
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

GuestFridge.defaultProps = {
  products: [],
  loading: false,
  error: null,
};

const mapStateToProps = (state) => ({
  products: state.offers.data
    ? offersToProductList(state.offers.data["hydra:member"])
    : [],
  loading: state.offers.loading,
  error: state.offers.error,
});

export default connect(mapStateToProps)(translated(GuestFridge));
