import { connectRouter, routerMiddleware } from "connected-react-router";
import storage from "redux-persist/es/storage";
import { polyglotReducer } from "redux-polyglot";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { init } from "@rematch/core";
import polyglotMiddleware from "./i18n/middleware";
import history from "./routing/history";
import * as models from "./models";
import burger from "../components/burger/reducer";
import tutorial from "../components/tutorial/reducer";
import client from "./client";

const store = init({
  models,
  redux: {
    reducers: {
      router: connectRouter(history),
      polyglot: persistReducer(
        { key: "polyglot", storage, blacklist: ["phrases"] },
        polyglotReducer
      ),
      burger,
      tutorial: persistReducer({ key: "tutorial", storage }, tutorial),
    },
    middlewares: [routerMiddleware(history), polyglotMiddleware, thunk],
  },
});

client.setStore(store);

export default store;

export const persistor = persistStore(store);
