import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Paper from "../common/Paper";
import Form from "../form/Form";
import { EmailInput, PasswordInput } from "../form/Input";
import SubmitButton from "../form/SubmitButton";
import client from "../../services/client";
import {
  EMAIL_ADDRESS,
  PASSWORD,
  LOGIN,
  LOGIN_TITLE,
  REGISTER_ACTION,
  FORGOT_PASSWORD_ACTION,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import BottomContainer from "../common/BottomContainer";
import RegisterButton from "../common/buttons/RegisterButton";
import ForgotPasswordButton from "../common/buttons/ForgotPasswordButton";
import Page, { PageContent } from "../common/Page";
import styles from "./loginform.module.css";
import redirect from "../../services/routing/redirect";

const LoginForm = ({ translate, login, restaurateur }) => (
  <Page>
    <PageContent>
      <Form
        initialValues={{ email: "", password: "" }}
        validationRules={{
          email: ["required", "email"],
          password: ["required"],
        }}
        sendRequest={(values) => client.login(values.email, values.password)}
        onSuccess={(user) => {
          login(user);
          redirect(`/${restaurateur.slug}/fridge`);
        }}
        className={styles.form}
      >
        <Paper title={translate(LOGIN_TITLE)} titleOutside transparent>
          <EmailInput name="email" label={translate(EMAIL_ADDRESS)} required />
          <PasswordInput name="password" label={translate(PASSWORD)} required />
          <SubmitButton primary>{translate(LOGIN)}</SubmitButton>
        </Paper>
      </Form>
    </PageContent>
    <BottomContainer>
      <RegisterButton restaurateur={restaurateur}>
        {translate(REGISTER_ACTION)}
      </RegisterButton>
      <ForgotPasswordButton restaurateur={restaurateur}>
        {translate(FORGOT_PASSWORD_ACTION)}
      </ForgotPasswordButton>
    </BottomContainer>
  </Page>
);

LoginForm.propTypes = {
  translate: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatch = (dispatch) => ({
  login: dispatch.user.login,
});

export default compose(connect(null, mapDispatch), translated)(LoginForm);
