import React, { useState } from "react";
import * as PropTypes from "prop-types";
import Autocomplete from "react-autocomplete";
import { connect } from "react-redux";
import translated from "../../services/i18n/translated";
import Paper from "../common/Paper";
import { usePromise } from "../hooks";
import client from "../../services/client";
import {
  ADD_PRODUCT_ACTION,
  ERROR,
  LOADING,
  PRODUCT,
} from "../../services/i18n/messages";
import BigText from "../common/BigText";
import styles from "../form/input.module.css";
import Button from "../common/buttons/Button";
import Page, { PageContent } from "../common/Page";
import redirect from "../../services/routing/redirect";
import { TYPE_DELIVER } from "../../services/helpers/order";

const ChooseProduct = ({ fridge, translate, restaurateur, openDoor }) => {
  const [loading, result, error] = usePromise(() =>
    client.products(restaurateur.slug)
  );
  const products = result && result["hydra:member"];
  const [product, setProduct] = useState("");

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  const productsToDisplay = products.filter((p) =>
    p.name.toLowerCase().startsWith(product.toLowerCase())
  );

  return (
    <Page>
      <PageContent>
        <Paper title={translate(ADD_PRODUCT_ACTION)}>
          <Autocomplete
            getItemValue={(item) => item.name}
            wrapperStyle={{}}
            items={productsToDisplay}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                className={[
                  styles.autocomplete_item,
                  isHighlighted ? styles["autocomplete_item-highlighted"] : "",
                ].join(" ")}
              >
                {item.name}
              </div>
            )}
            renderMenu={(items) =>
              items.length > 0 ? (
                <div className={styles.autocomplete_menu}>{items}</div>
              ) : (
                <div />
              )
            }
            value={product}
            onChange={(event) => setProduct(event.target.value)}
            onSelect={(p) => setProduct(p)}
            renderInput={(props) => (
              <input
                className={styles.input}
                style={{ margin: 0 }}
                placeholder={translate(PRODUCT)}
                {...props}
              />
            )}
          />

          <div>
            <Button
              primary
              style={{ marginTop: 20 }}
              onClick={() => {
                const productToAdd = products.find((p) => p.name === product);

                if (productToAdd) {
                  openDoor({
                    type: TYPE_DELIVER,
                    restaurateurSlug: restaurateur.slug,
                    slug: fridge.slug,
                    product: productToAdd.id,
                  });
                  redirect(
                    `/${restaurateur.slug}/${fridge.slug}/open/deliver/${productToAdd.id}`
                  );
                }
              }}
            >
              {translate(ADD_PRODUCT_ACTION)}
            </Button>
          </div>
        </Paper>
      </PageContent>
    </Page>
  );
};

ChooseProduct.propTypes = {
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  openDoor: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openDoor: dispatch.door.open,
});

export default connect(null, mapDispatchToProps)(translated(ChooseProduct));
