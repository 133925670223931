/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Login = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 6H21C22.1 6 23 6.9 23 8V24C23 25.1 22.1 26 21 26H13C11.9 26 11 25.1 11 24V22H13V24H21V8H13V10H11V8C11 6.9 11.9 6 13 6Z"
      fill="inherit"
    />
    <path
      d="M13.09 19.59L14.5 21L19.5 16L14.5 11L13.09 12.41L15.67 15H6V17H15.67L13.09 19.59Z"
      fill="inherit"
    />
  </svg>
);

Login.propTypes = {
  className: PropTypes.string,
};

Login.defaultProps = {
  className: null,
};

export default Login;
