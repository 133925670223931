// eslint-disable-next-line import/prefer-default-export
export const formatCardNumber = (value) => {
  if (!value.match(/[0-9]{4}/)) return value;
  const parts = [];
  const len = value.length;

  for (let i = 0; i < len; i += 4) {
    parts.push(value.substring(i, i + 4));
  }

  return parts.join(" ");
};

export const cardIsExpired = (card) =>
  new Date().getTime() > new Date(card.expiryDate).getTime();

export const cardIsValid = (card) => !cardIsExpired(card);

export const hasOneValidPrimaryCard = (cards) =>
  cards.filter((card) => !card.titreRestaurant && cardIsValid(card)).length > 0;
