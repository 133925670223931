import React, { useState, useEffect } from "react";
import * as PropTypes from "prop-types";
import BigText from "../common/BigText";
import client from "../../services/client";
import {
  LOADING,
  ACTIVATED_ACCOUNT,
  TOKEN_NOT_RECOGNIZED,
  UNKNOWN_TOKEN_TYPE,
  OPEN_FRIDGE,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import Button from "../common/buttons/Button";
import redirect from "../../services/routing/redirect";

const HandleToken = ({ translate, token, restaurateur }) => {
  const [tokenType, setTokenType] = useState(null);

  useEffect(() => {
    client
      .handleToken(token)
      .then(setTokenType)
      .catch(() => setTokenType("error"));
  }, [token]);

  switch (tokenType) {
    case null:
      return <BigText>{translate(LOADING)}</BigText>;
    case "error":
      return <BigText>{translate(TOKEN_NOT_RECOGNIZED)}</BigText>;
    case "activate_user":
      return (
        <BigText>
          <div>
            <span>{translate(ACTIVATED_ACCOUNT)}</span>
            <Button primary onClick={() => redirect(`/${restaurateur.slug}`)}>
              {translate(OPEN_FRIDGE)}
            </Button>
          </div>
        </BigText>
      );
    default:
      throw new Error(translate(UNKNOWN_TOKEN_TYPE));
  }
};

HandleToken.propTypes = {
  token: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
};

export default translated(HandleToken);
