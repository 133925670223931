import * as constants from "./actionTypes";

export const open = () => ({
  type: constants.OPEN_BURGER,
});

export const close = () => ({
  type: constants.CLOSE_BURGER,
});

export const toggle = () => ({
  type: constants.TOGGLE_BURGER,
});
