/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const UnAvailable = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 4C8.37321 4 3 9.37321 3 16C3 22.6268 8.37321 28 15 28C21.6268 28 27 22.6268 27 16C27 9.37321 21.6268 4 15 4ZM15 25.9643C9.49821 25.9643 5.03571 21.5018 5.03571 16C5.03571 10.4982 9.49821 6.03571 15 6.03571C20.5018 6.03571 24.9643 10.4982 24.9643 16C24.9643 21.5018 20.5018 25.9643 15 25.9643Z"
      fill="inherit"
    />
    <rect
      x="9.54572"
      y="20.5457"
      width="14.1421"
      height="2.0203"
      transform="rotate(-45 9.54572 20.5457)"
      fill="inherit"
    />
    <rect
      x="10.5559"
      y="10.4442"
      width="14.1421"
      height="2.0203"
      transform="rotate(45 10.5559 10.4442)"
      fill="inherit"
    />
  </svg>
);

UnAvailable.propTypes = {
  className: PropTypes.string,
};

UnAvailable.defaultProps = {
  className: null,
};

export default UnAvailable;
