import React from "react";
import { Route, Switch } from "react-router-dom";
import * as PropTypes from "prop-types";
import { usePromise } from "../hooks";
import client from "../../services/client";
import BigText from "../common/BigText";
import { LOADING } from "../../services/i18n/messages";
import store from "../../services/store";
import translated from "../../services/i18n/translated";
import RestaurateurRouter from "./RestaurateurRouter";
import Home from "../home/Home";

const Routes = ({ translate }) => {
  const [loading, user] = usePromise(() => client.me());

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  store.dispatch.user.login(user);

  return (
    <Switch>
      <Route strict exact path="/" component={Home} />
      <Route path="/:restaurateurSlug" component={RestaurateurRouter} />
    </Switch>
  );
};

Routes.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translated(Routes);
