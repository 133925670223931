import React from "react";

export default (str, match, replace) => {
  const result = str.split(match);

  // eslint-disable-next-line prefer-destructuring
  for (let i = 1, length = result.length; i < length; i += 2) {
    result[i] = () => replace;
  }

  return result.map((obj, key) => {
    const Obj = typeof obj === "function" ? obj : () => obj;
    return <Obj key={key} />;
  });
};
