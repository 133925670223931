import React from "react";
import * as PropTypes from "prop-types";
import { intersection } from "lodash";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import client from "../../services/client";
import { usePolling, usePromise } from "../hooks";
import { LOADING } from "../../services/i18n/messages";
import BigText from "../common/BigText";
import translated from "../../services/i18n/translated";
import FridgeDoorStatus from "../fridge-door-status/FridgeDoorStatus";
import {
  DOOR_CLOSED,
  DOOR_OPENED,
  INITIALIZED,
} from "../../services/helpers/order";
import ErrorPage from "../common/ErrorPage";

const WaitForDoorBase = translated(
  ({
    id,
    translate,
    restaurateur,
    slug,
    redirectPath,
    interactions,
    load,
    loading,
    fridge,
    error,
  }) => {
    // eslint-disable-next-line no-console
    console.log("render WaitForDoorBase");
    if (!loading && !error && (!fridge || fridge.slug !== slug)) {
      load({ restaurateurSlug: restaurateur.slug, slug });
    }

    const [loadingOrder, order, errorOrder] = usePolling(
      (timeout = 3000) => client.timeOutGetOrder(id, timeout),
      { stopOnError: !interactions }
    );

    if (errorOrder || error) {
      // eslint-disable-next-line no-console
      console.error("WaitForDoorBase error", error, errorOrder);

      return <ErrorPage />;
    }

    if (loadingOrder) {
      return <BigText>{translate(LOADING)}</BigText>;
    }

    const status = order ? order.status : INITIALIZED;
    console.log("order", order);
    if ([DOOR_CLOSED, DOOR_OPENED, INITIALIZED].indexOf(status) === -1) {
      return <Redirect to={redirectPath} />;
    }

    return (
      <FridgeDoorStatus
        fridge={fridge}
        order={order}
        interactions={interactions}
      />
    );
  }
);

WaitForDoorBase.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  interactions: PropTypes.bool.isRequired,
  load: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
  error: PropTypes.instanceOf(Error),
};

WaitForDoorBase.defaultProps = {
  loading: false,
  fridge: null,
  error: null,
};

const mapStateToProps = (state) => ({
  loading: state.fridge.loading,
  fridge: state.fridge.data,
  error: state.fridge.error,
});

const mapDispatchToProps = (dispatch) => ({
  load: dispatch.fridge.load,
});

const WaitForDoor = connect(
  mapStateToProps,
  mapDispatchToProps
)(WaitForDoorBase);

export const SpecificOrderWaitForDoor = ({
  restaurateur,
  slug,
  id,
  interactions,
}) => {
  return (
    <WaitForDoor
      restaurateur={restaurateur}
      slug={slug}
      id={id}
      redirectPath={`/${restaurateur.slug}/orders/${id}/details`}
      interactions={interactions}
    />
  );
};

SpecificOrderWaitForDoor.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  interactions: PropTypes.bool.isRequired,
};

export const CurrentOrderWaitForDoor = translated(
  ({ translate, restaurateur, slug, interactions }) => {
    // eslint-disable-next-line no-console
    console.log("render CurrentOrderWaitForDoor");
    const [loading, order, error] = usePromise(() =>
      client.getCurrentOrder(restaurateur.slug, slug)
    );

    if (loading) {
      return <BigText>{translate(LOADING)}</BigText>;
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.error("CurrentOrderWaitForDoor error", error);
      return <ErrorPage />;
    }

    return (
      <WaitForDoor
        restaurateur={restaurateur}
        slug={slug}
        id={order.id.toString()}
        redirectPath={`/${restaurateur.slug}/orders/${order.id}/details/no-interaction`}
        interactions={interactions}
      />
    );
  }
);

CurrentOrderWaitForDoor.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
  interactions: PropTypes.bool.isRequired,
};
