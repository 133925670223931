import React from "react";
import * as PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import BigText from "../common/BigText";
import client from "../../services/client";
import {
  LOADING,
  ACTIVATED_ACCOUNT,
  TOKEN_NOT_RECOGNIZED,
  OPEN_FRIDGE,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import Button from "../common/buttons/Button";
import redirect from "../../services/routing/redirect";
import { usePromise } from "../hooks";

const ActivateUser = ({ translate, token, restaurateur, login, fridge }) => {
  const [loading, , error] = usePromise(
    () => client.activateUser(token),
    login
  );

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  const redirectPath = `/${restaurateur.slug}/${
    fridge ? fridge.slug : "fridge"
  }`;

  if (error) {
    return (
      <BigText>
        <div>
          <span>{translate(TOKEN_NOT_RECOGNIZED)}</span>
          <Button primary onClick={() => redirect(redirectPath)}>
            {translate(OPEN_FRIDGE)}
          </Button>
        </div>
      </BigText>
    );
  }

  return (
    <BigText>
      <div>
        <span>{translate(ACTIVATED_ACCOUNT)}</span>
        <Button primary onClick={() => redirect(redirectPath)}>
          {translate(OPEN_FRIDGE)}
        </Button>
      </div>
    </BigText>
  );
};

ActivateUser.propTypes = {
  token: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
};

ActivateUser.defaultProps = {
  fridge: null,
};

const mapDispatch = (dispatch) => ({
  login: dispatch.user.login,
});

export default compose(connect(null, mapDispatch), translated)(ActivateUser);
