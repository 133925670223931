/* eslint-disable max-len */
import * as messages from "../messages";

export default {
  [messages.TXT_OR]: "or",
  [messages.CANCEL]: "Cancel",
  [messages.BACK]: "Return",
  [messages.REGISTER_TITLE]: "Register",
  [messages.EMAIL_ADDRESS]: "E-mail address",
  [messages.PASSWORD]: "Password",
  [messages.FIRSTNAME]: "Firstname",
  [messages.LASTNAME]: "Lastname",
  [messages.REGISTER_FORM_SUBMITTED]:
    "You will receive an e-mail with a confirmation link!",
  [messages.LOADING]: "Loading...",
  [messages.TOKEN_NOT_RECOGNIZED]:
    "Unable to validate the email or email already validated!",
  [messages.ACTIVATED_ACCOUNT]: "Your account has been activated!",
  [messages.UNKNOWN_TOKEN_TYPE]: "Type of token not recognized!",
  [messages.REQUIRED_VALUE]: "This value is required",
  [messages.REQUIRED_LABEL]: "mantadory",
  [messages.INVALID_EMAIL_ADRESS_VALUE]: "This value must be an email address",
  [messages.REGISTRATION]: "Registration",
  [messages.UNSUPPORTED_LOCALE]: "Unsupported locale",
  [messages.PAGE_NOT_FOUND]: "Page not found",
  [messages.LOGIN_TITLE]: "Login",
  [messages.FORGOT_PASSWORD_TITLE]: "Forgot your password?",
  [messages.FORGOT_PASSWORD_SENT]:
    "An email has been sent to you with a link to reset your password.",
  [messages.FORGOT_PASSWORD_ACTION]: "Forgot your password?",
  [messages.FORGOT_PASSWORD_SUCCESS]:
    "Your password has been successfully reset!",
  [messages.LOGIN]: "Log in",
  [messages.LOGIN_ACTION]: "Log in",
  [messages.REGISTER_ACTION]: "Create an account",
  [messages.FRIDGE_FORM_TITLE]: "What is your fridge?",
  [messages.OPEN_FRIDGE]: "Go in my fridge",
  [messages.FRIDGE_NAME]: "Fridge name",
  [messages.FRIDGE_NOT_FOUND]:
    "Ouch, this fridge doesn't exist! Are you sure ?",
  [messages.FRIDGE_UNAVAILABLE]:
    "Ouch, this fridge is not available! Are you sure ?",
  [messages.SALE_NOT_AUTHORIZED]:
    "Your bank refuses to register your card. Please try another payment method.",
  [messages.SAVE_CARD_FAILED]:
    "Authorization failed. Please try another payment method.",
  [messages.FRIDGE_W_NAME]: "%{name} fridge",
  [messages.BACK_ACTION]: "Back",
  [messages.OPEN_ACTION]: "Open the door",
  [messages.WAIT_ACTION]: "Please wait",
  [messages.RETRY]: "Retry",
  [messages.ERROR]: "An error occured!",
  [messages.FRIDGE_IS_OPENING]: "The fridge is opening, please wait...",
  [messages.FRIDGE_IS_OPENED_BUY]: "The fridge is open, take what you need!",
  [messages.FRIDGE_IS_OPENED_DELIVER]:
    "The fridge is open, you can add the products!",
  [messages.FRIDGE_IS_OPENED_REMOVE]:
    "The fridge is open, you can remove the products!",
  [messages.FRIDGE_IS_OPENED_MAINTENANCE]: "The fridge is open!",
  [messages.ORDER]: "Order",
  [messages.SUBMIT]: "Submit",
  [messages.ENJOY_YOUR_MEAL]: "Enjoy your meal!",
  [messages.DELIVERY_FINISHED]: "Delivery finished!",
  [messages.MAINTENANCE_FINISHED]: "Maintenance finished!",
  [messages.FINISHED]: "Finished!",
  [messages.TOTAL]: "Total",
  [messages.WITH_TAXES]: "Taxes: %{tax}%",
  [messages.BILLED_AMOUNT]: "Billed",
  [messages.BILLED_ON_CARD]: "on card",
  [messages.END_ACTION]: "Finish",
  [messages.EMAIL_ALREADY_EXISTS]:
    "A user with this e-mail address already exists",
  [messages.DOOR_TIMED_OUT]: "Oops! An error occurred, please try again later",
  [messages.FRIDGE_FORM_DELIVERY_TITLE]: "What fridge do you deliver to?",
  [messages.DELIVER_FRIDGE]: "Deliver",
  [messages.MY_PAYMENT_CARDS]: "My cards",
  [messages.MY_PURCHASES]: "My purchases",
  [messages.CONTACT]: "Contact",
  [messages.ADD_PRODUCT_ACTION]: "Add a product",
  [messages.OUTDATED_PRODUCTS_ACTION]:
    "%{smart_count} outdated product |||| %{smart_count} outdated products",
  [messages.PRODUCT]: "Product",
  [messages.SEE_OFFERS]: "See the product list",
  [messages.FRIDGE_OFFERS]: "In the fridge",
  [messages.NO_OFFERS]: "No product available",
  [messages.LOGOUT]: "Logout",
  [messages.ACCEPT_TERMS]:
    "by clicking on registration, you're agree with %{link}",
  [messages.TERMS]: "C.G.V.U.S",
  [messages.SEND_BY_MAIL]: "Send by email",
  [messages.MY_PAYMENT_CARD]: "My payment card",
  [messages.ADD_PAYMENT_CARD]: "Add a payment card",
  [messages.ADD_SECONDARY_PAYMENT_CARD]: "Add another payment card",
  [messages.SECONDARY_CARD_CAN_BE_ADDED_LATER]:
    "You can add a secondary payment card later",
  [messages.CARD_NAME]: "Name of the card",
  [messages.ADD]: "Add",
  [messages.OUTDATED_PRODUCTS]: "Outdated products",
  [messages.REMOVING_FINISHED]: "Products removed!",
  [messages.CARD_ADD]: "Add a payment card",
  [messages.CARD_HOLDER]: "Card holder",
  [messages.CARD_HOLDER_SUBMIT]: "Continue",
  [messages.SAVE_CARD_INFO]:
    "We keep your credit card details to avoid you having to enter them again during your future purchases.",
  [messages.SAVE_CARD]: "Save card",
  [messages.NEW_CARD_PENDING]: "Save card",
  [messages.NEW_CARD_PENDING]: "Card verification, please wait ...",
  [messages.CARD_DELETE]: "Delete my card",
  [messages.CARD_DELETE_CONFIRM]:
    "Are you sure you want to delete your card n°%{number}",
  [messages.NO]: "No",
  [messages.YES]: "Yes",
  [messages.EXPIRED_CARD]: "Expired card",
  [messages.VALID_CARD]: "Valid card",
  [messages.PRIMARY_CARD_MISSING]: "A complementary bank card is required",
  [messages.ADD_CARD_ERROR]: "Aw snap, an error occurred! try again later",
  [messages.LANGS]: "Languages",
  [messages.FAVORITE_CARD]: "My favorite card",
  [messages.OTHER_CARDS]: "My other cards",
  [messages.SEE_YOU_SOON]: "See you soon!",
  [messages.THANKS]: "Thanks!",
  [messages.FRIDGE_IS_CLOSED_BUY]: "The bill is coming...",
  [messages.FRIDGE_IS_CLOSED_DELIVER]: "The fridge is closed, please wait...",
  [messages.FRIDGE_IS_CLOSED_REMOVE]: "The fridge is closed, please wait...",
  [messages.FRIDGE_IS_CLOSED_MAINTENANCE]:
    "The fridge is closed, please wait...",
  [messages.MY_FRIDGE]: "My fridge",
  [messages.RECENT_FRIDGE]: "Recent fridges",
  [messages.EMPTY_RECENT_FRIDGE]: "No recent fridges",
  [messages.FRIDGE_STATUS_AVAILABLE]: "Available",
  [messages.FRIDGE_STATUS_BUSY]: "In use",
  [messages.FRIDGE_STATUS_UNAVAILABLE]: "Unavailable",
  [messages.FRIDGE_IS_OPENED_BUY_LABEL]: "Fridge opened!",
  [messages.FRIDGE_IS_OPENED_BUY_LABEL_MORE]: "Everything is for you!",
  [messages.FRIDGE_IS_OPENED_BUY_TEXT]:
    "Take what you want, we’ll charge you automatically when you close the door.",
  [messages.FOOTER_TEXT]: "Powered by %{link}",
  [messages.FOOTER_LINK]: "Eat Machine",
  [messages.LOAD_MORE]: "See more",
  [messages.TUTORIAL_STEP_1_TITLE]: "Open",
  [messages.TUTORIAL_STEP_1_DESCRIPTION]:
    "Use the button on your phone screen to unlock the fridge door",
  [messages.TUTORIAL_STEP_2_TITLE]: "Choose",
  [messages.TUTORIAL_STEP_2_DESCRIPTION]: "Take what you want from the fridge",
  [messages.TUTORIAL_STEP_3_TITLE]: "Billed!",
  [messages.TUTORIAL_STEP_3_DESCRIPTION]:
    "Once the door is closed, the fridge detects what you have taken and sends you the invoice",
  [messages.TUTORIAL_CLOSE_ACTION]: "Close",
  [messages.TUTORIAL_NEXT_STEP_ACTION]: "Next",
  [messages.TUTOTIAL_START_ACTION]: "Start",
  [messages.UNKNOWN_PRODUCT]: "Unknown product",
  [messages.PRODUCTS_AVAILABLE]:
    "%{smart_count} available |||| %{smart_count} available",
  [messages.IN_PROMOTION]:
    "%{smart_count} in promotion |||| %{smart_count} in promotion !",
  [messages.TAGS_IN_PROMOTION]: "Product tags in promotion",
  [messages.PROMOTION_DISCOUNT]: "Promotion discount",
  [messages.SEE_PROMOTION]: "Show",
  [messages.MENU]: "Menu",
  [messages.MENU_LG]: "Today's Menu",
  [messages.OPEN_FRIDGE_WITH]: "Open the fridge",
  [messages.PAY_WITH_CARD_BTN]: "Cards",
  [messages.PAY_WITH_CARD_BTN_2]: "to open",
  [messages.PAY_WITH_CARD_BTN_3]: "Tap to open",
  [messages.PAY_WITH_APP_BTN]: "Scan",
  [messages.PAY_WITH_APP_BTN_2]: "to open",
  [messages.PAY_WITH_CARD]: "Open the fridge with card",
  [messages.PAY_WITH_APP]: "Open the fridge with app",
  [messages.AUTHORIZATION_DISCLAIMER]:
    "Attention ! A deposit is taken when you open the door, it is returned to you when it closes. This amount may appear for a few moments on your bank statement.",
  [messages.SCAN_QR_CODE]: "Scan QR Code",
  [messages.SCAN_QR_CODE_ALT]: " or go to url",
  [messages.NAME_OF_FRIDGE]: "Fridge name: %{name}",
  [messages.ACCEPTED_PAYMENT_METHODS]: "Accepted payment methods",
  [messages.SWIPE_CARD]: "Present your card to the reader",
  [messages.FRIDGE_STATUS_FRIDGE]: "Fridge: %{name}",
  [messages.POS_PAYMENT_WAITING_AUTHORIZATION]: "Authorization",
  [messages.POS_PAYMENT_WAITING_AUTHORIZATION_DESC]:
    "Bank authorization request in progress ... just a few seconds to wait.",
  [messages.POS_PAYMENT_AUTHORIZATION_FAILURE]: "Whoops !",
  [messages.POS_PAYMENT_AUTHORIZATION_FAILURE_DESC]:
    "This card was refused by the bank. Please try another payment method.",
  [messages.NFC_PAYMENT_AUTHORIZATION_FAILURE]: "Whoops !",
  [messages.NFC_PAYMENT_AUTHORIZATION_FAILURE_DESC]:
    "Sorry, this badge is not accepted.",
  [messages.TAKE_A_SCREEN_PICTURE_LINE_1]: "Take a picture of the screen",
  [messages.TAKE_A_SCREEN_PICTURE_LINE_2]: "to keep an invoice",
  [messages.NOTIF_CLOSE_FRIDGE_TITLE]: "Oops the fridge is open",
  [messages.NOTIF_CLOSE_FRIDGE_DESC]: "Please close the door.",
  [messages.BILLING_ADDRESS]: "Billing address",
  [messages.ADDRESS_LINE1]: "Address",
  [messages.ADDRESS_LINE2]: "Additionnal address",
  [messages.ADDRESS_POSTAL_CODE]: "Postal code",
  [messages.ADDRESS_CITY]: "City",
  [messages.ADDRESS_COUNTRY]: "Country",
};
