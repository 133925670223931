import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  isRole,
  ROLE_CLIENT,
  ROLE_DELIVERER,
  ROLE_RESTAURATEUR,
} from "../../services/helpers/roles";

const RoleView = ({ children, hasRole }) => (hasRole ? children : null);

const Role = connect((state, { roleName }) => ({
  hasRole: state.user && isRole(state.user, roleName),
}))(RoleView);
const GuestRole = connect((state) => ({
  hasRole: !state.user,
}))(RoleView);

export const Guest = ({ children }) => <GuestRole>{children}</GuestRole>;
export const Client = ({ children }) => (
  <Role roleName={ROLE_CLIENT}>{children}</Role>
);
export const Deliverer = ({ children }) => (
  <Role roleName={ROLE_DELIVERER}>{children}</Role>
);
export const Restaurateur = ({ children }) => (
  <Role roleName={ROLE_RESTAURATEUR}>{children}</Role>
);

export const AnyRole = ({ roles, children }) => (
  <>
    {roles.map((RoleComponent, i) => (
      <RoleComponent key={i}>{children}</RoleComponent>
    ))}
  </>
);

const props = {
  children: PropTypes.node.isRequired,
};

AnyRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  ...props,
};

Guest.propTypes = props;
Client.propTypes = props;
Deliverer.propTypes = props;
Restaurateur.propTypes = props;
