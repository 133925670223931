import React from "react";
import PropTypes from "prop-types";
import styles from "./paper.module.css";

const Paper = ({
  id,
  title,
  children,
  className,
  titleOutside = false,
  transparent = false,
  footer = null,
  footerOutside = false,
}) => (
  <div id={id} className={[styles["paper-aligner"], className].join(" ")}>
    {title && titleOutside && (
      <h1 className={styles["paper-large-title"]}>{title}</h1>
    )}
    <div
      className={[
        styles.paper,
        transparent ? styles.transparent : styles.white,
      ].join(" ")}
    >
      {title && !titleOutside && (
        <h1 className={styles["paper-title"]}>{title}</h1>
      )}
      {children}
      {footer && !footerOutside && (
        <div className={styles["paper-footer"]}>{footer}</div>
      )}
    </div>
    {footer && footerOutside && (
      <div className={styles["paper-large-footer"]}>{footer}</div>
    )}
  </div>
);

Paper.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  titleOutside: PropTypes.bool,
  transparent: PropTypes.bool,
  className: PropTypes.string,
  footer: PropTypes.node,
  footerOutside: PropTypes.bool,
};

Paper.defaultProps = {
  id: null,
  title: null,
  titleOutside: false,
  transparent: false,
  className: null,
  footer: null,
  footerOutside: false,
};

export default Paper;
