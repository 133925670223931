import React from "react";
import * as PropTypes from "prop-types";
import FridgeStatus from "../fridge-status/FridgeStatus";
import styles from "./fridgeview.module.css";

const FridgeView = ({ children, restaurateur, fridge, noInteraction }) => {
  return (
    <>
      <FridgeStatus
        fridge={fridge}
        restaurateur={restaurateur}
        noInteraction={noInteraction}
      />
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

FridgeView.defaultProps = {
  noInteraction: false,
};

FridgeView.propTypes = {
  children: PropTypes.node.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  noInteraction: PropTypes.bool,
};

export default FridgeView;
