import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from "prop-types";
import styles from "./fridgemedias.module.scss";

const FridgeMedias = ({ fridge }) => {
  const [slide, setSlide] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    // eslint-disable-next-line no-return-assign
    return () => (isMounted.current = false);
  }, []);

  if (!fridge || !fridge.medias || !fridge.medias.length) return null;

  const settings = {
    autoPlay: autoplay,
    showThumbs: false,
    infiniteLoop: true,
    selectedItem: slide,
    showStatus: false,
    showArrows: false,
    interval: 5000,
    renderItem: (item, props) => <item.type {...item.props} {...props} />,
    onChange: (index) => {
      if (isMounted.current && slide !== index) {
        setSlide(index);
      }
    },
  };

  const medias = [...fridge.medias]
    .sort((m1, m2) => (m1.position < m2.position ? -1 : 1))
    .map((fridgeMedia) => (
      <FridgeMedia
        key={fridgeMedia.media.src}
        fridgeMedia={fridgeMedia}
        setAutoplay={setAutoplay}
      />
    ));

  return (
    <div className={styles.container}>
      <Carousel {...settings}>{medias}</Carousel>
    </div>
  );
};

FridgeMedias.propTypes = {
  fridge: PropTypes.shape({
    medias: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.number,
        media: PropTypes.shape({
          src: PropTypes.string,
        }),
      })
    ),
  }),
};

FridgeMedias.defaultProps = {
  fridge: null,
};

const FridgeMedia = ({ fridgeMedia, isSelected, setAutoplay }) => {
  const { src } = fridgeMedia.media;

  const type = src.match(/.(m4v|avi|mpg|mp4)$/i) ? "video" : "image";

  useEffect(() => {
    type !== "video" && isSelected && setAutoplay(true);
  });

  if (type === "video") {
    return (
      <div className={styles.mediaContainer}>
        <ReactPlayer
          width="100%"
          style={{
            width: "100%",
            minHeight: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
          url={src}
          playing={isSelected}
          muted
          loop={false}
          controls={false}
          onReady={(p) => {
            isSelected && setAutoplay(false);
          }}
          onStart={() => {
            isSelected && setAutoplay(false);
          }}
          onEnded={() => {
            isSelected && setAutoplay(true);
          }}
        />
      </div>
    );
  }

  if (type === "image") {
    return (
      <div className={styles.mediaContainer}>
        <div
          className={styles.imgContainer}
          style={{ backgroundImage: `url("${src}")` }}
        />
      </div>
    );
  }

  return null;
};

FridgeMedia.propTypes = {
  fridgeMedia: PropTypes.shape({
    position: PropTypes.number,
    media: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  isSelected: PropTypes.bool,
  setAutoplay: PropTypes.func.isRequired,
};

FridgeMedia.defaultProps = {
  isSelected: false,
};

export default FridgeMedias;
