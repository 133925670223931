import * as React from "react";

/* eslint-disable max-len */
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={71.12}
    height={52.387}
    viewBox="0 0 53.34 39.29"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h53v.016H0Zm0 .016h53V39H0Zm0 0" />
      </clipPath>
      <image
        id="b"
        width={267}
        height={197}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQsAAADFCAIAAADMokB1AAAABmJLR0QA/wD/AP+gvaeTAAACe0lEQVR4nO3cwVUdMRBFQQk6D6fgmMg/Bb6IQHc7s6iKYDb3PLUB738//xdw8fX0B8CrKQSKQqAoBIpCoCgEikKgKASKQqAoBIpCoCgEikKgKASKQqAoBIpCoCgEikKgKASKQqAoBIpCoCgEikKgKASKQqAoBIpCoMxa8/Q3wHvN3gqBq9k2BO68sqB4ZUHxyoLilQXFKwvKrPX99DfAe7lDoNgQKDYEig2BMnsrBK78PATKbK8suLMhUGwIFP+WBcWGQLEhUGb7707gbvyHQBDcIVBsCBR3CBQbAsUdAsWGQHGHQPEzdSizjw2Bq1lrP/0N8F4KgeJSh2JDoCgEilcWFBsCRSFQFALFHQLFhkBRCJTZRyFw5QiB4pUFxYZAUQgUrywoNgSKDYFiQ6DYECg2BIpCoMz2yoI7GwJl1jlPfwO815zP79PfAO816ygErmwIFIVAmfWrELia9fk8/Q3wXl5ZUGYpBO4UAmWOOwTubAgUGwJljt86gTsbAsUdAsWGQHGHQLEhUNwhUGwIFHcIFBsCxR0CxYZAcYdA8XfqUPydOhR3CBR3CBR3CBSvLCgudShzjg2BK791AsUdAsUrC4pCoCgEikKgfD39AfBqCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECgKgaIQKAqBohAoCoGiECh/FW9vvfz0YB8AAAAASUVORK5CYII="
      />
    </defs>
    <g clipPath="url(#a)">
      <use xlinkHref="#b" transform="matrix(.2 0 0 -.2 0 39.29)" />
    </g>
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M16.371 18.77H27.81c-.102-2.262-.68-4.59-2.426-6.086-2.082-1.782-5.727-2.442-8.985-2.442-3.386 0-7.136.723-9.23 2.637-1.805 1.648-2.191 4.297-2.191 6.676 0 2.496.761 5.398 2.718 7.066 2.082 1.781 5.453 2.25 8.703 2.25 3.16 0 6.582-.531 8.653-2.219 2.062-1.687 2.777-4.527 2.777-7.097v-.012H16.371M28.574 19.547v8.91h15.91v-.012a4.398 4.398 0 0 0 4.18-4.39c0-2.356-1.852-4.399-4.18-4.516v.008M48.375 14.684c0-2.27-1.8-4.07-4.07-4.07-.157 0-.352-.028-.504-.012H28.574v8.168h16.043c2.102-.184 3.758-1.938 3.758-4.086"
    />
  </svg>
);

export default SvgComponent;
