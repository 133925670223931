import * as React from "react";

/* eslint-disable max-len */
const SvgComponent = (props) => (
  <svg
    viewBox="0 0 59 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.556 19.917c3.154 0 5.81-1.41 5.81-4.564 0-2.573-2.242-3.652-5.561-4.316-.166 0-.415-.083-.664-.083h-.083c-1.162-.249-2.407-.58-2.407-1.66 0-.995 1.162-1.41 2.241-1.41 1.494 0 2.656.664 2.988 2.157l2.987-.83c-.747-2.738-3.153-3.983-5.975-3.983-2.904 0-5.477 1.245-5.477 4.233 0 2.987 2.407 3.651 4.896 4.149h.166l.25.083c1.742.415 3.319.913 3.319 1.992 0 1.245-1.328 1.66-2.49 1.66-1.66 0-2.905-.747-3.402-2.324L0 15.85c.747 2.821 3.734 4.066 6.556 4.066Zm30.207-16.1c1.162 0 2.075-.746 2.075-1.908C38.838.829 37.925 0 36.763 0 35.52 0 34.69.83 34.69 1.909c0 1.162.913 1.908 2.075 1.908Zm-8.547 16.1c.913 0 2.738-.332 3.568-2.822.415-1.244.498-2.323.581-4.398V12.282c.083-1.826.332-3.485.996-3.485v-3.32c-.996 0-2.822.415-3.651 2.905-.415 1.245-.498 2.821-.581 4.398 0 .415-.083.996-.083 1.577v.083c-.083 1.162-.332 2.24-.747 2.24-.664 0-.913-2.572-.913-3.9-.083-1.494-.166-3.153-.581-4.398-.83-2.49-2.573-2.905-3.569-2.905-.995 0-2.738.415-3.568 2.905-.415 1.245-.498 2.904-.58 4.398 0 .415-.084.996-.167 1.577v.083c-.166 1.162-.332 2.24-.83 2.24-.747 0-.996-2.821-1.079-3.9l-.082-7.303h-3.32v5.062l.083 2.241c.053 1.46.276 2.908.664 4.315.664 1.91 1.909 2.905 3.651 2.905 1.743 0 3.154-1.079 3.652-2.905.332-1.327.498-2.821.58-4.315 0-.415.084-.996.167-1.577v-.332c.083-1.078.332-1.991.747-1.991.663 0 .83 2.738.912 3.983.083 1.494.166 3.07.581 4.315.747 2.324 2.573 2.739 3.569 2.739Zm10.207-.166V5.394h-3.402v14.357h3.402Zm6.722-1.909c-1.162-1.66-1.66-3.07-1.66-5.394V.332h-3.402v12.116c0 3.07.664 5.228 2.324 7.386 0 .083 2.738-1.992 2.738-1.992Zm6.971 2.075c3.237 0 5.394-1.909 6.224-4.232l-3.07-.83c-.498 1.245-1.577 1.991-3.07 1.991-1.91 0-3.57-1.493-3.652-3.734h10.29c.415-4.315-2.075-8.05-6.722-8.05-3.983 0-6.888 2.988-6.888 7.386-.083 4.15 2.739 7.469 6.888 7.469Zm3.486-9.295H48.63c.332-1.576 1.742-2.738 3.485-2.738 1.826 0 3.237 1.245 3.486 2.738Z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
