/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Swile = ({ id, className }) => (
  <svg
    width="26"
    height="17"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72.5 25.5"
    className={className}
  >
    <path d="M8.7 24.7c3.8 0 7-1.7 7-5.5 0-3.1-2.7-4.4-6.7-5.2-.2 0-.5-.1-.8-.1h-.1c-1.4-.3-2.9-.7-2.9-2 0-1.2 1.4-1.7 2.7-1.7 1.8 0 3.2.8 3.6 2.6l3.6-1C14.2 8.5 11.3 7 7.9 7c-3.5 0-6.6 1.5-6.6 5.1s2.9 4.4 5.9 5h.2l.3.1c2.1.5 4 1.1 4 2.4 0 1.5-1.6 2-3 2-2 0-3.5-.9-4.1-2.8l-3.8 1c.9 3.4 4.5 4.9 7.9 4.9zM45.1 5.3c1.4 0 2.5-.9 2.5-2.3 0-1.3-1.1-2.3-2.5-2.3-1.5 0-2.5 1-2.5 2.3 0 1.4 1.1 2.3 2.5 2.3zM34.8 24.7c1.1 0 3.3-.4 4.3-3.4.5-1.5.6-2.8.7-5.3v-.5c.1-2.2.4-4.2 1.2-4.2v-4c-1.2 0-3.4.5-4.4 3.5-.5 1.5-.6 3.4-.7 5.3 0 .5-.1 1.2-.1 1.9v.1c-.1 1.4-.4 2.7-.9 2.7-.8 0-1.1-3.1-1.1-4.7-.1-1.8-.2-3.8-.7-5.3-1-3-3.1-3.5-4.3-3.5s-3.3.5-4.3 3.5c-.5 1.5-.6 3.5-.7 5.3 0 .5-.1 1.2-.2 1.9v.1c-.2 1.4-.4 2.7-1 2.7-.9 0-1.2-3.4-1.3-4.7l-.1-8.8h-4v6.1l.1 2.7a22.67 22.67 0 00.8 5.2c.8 2.3 2.3 3.5 4.4 3.5s3.8-1.3 4.4-3.5c.4-1.6.6-3.4.7-5.2 0-.5.1-1.2.2-1.9v-.4c.1-1.3.4-2.4.9-2.4.8 0 1 3.3 1.1 4.8.1 1.8.2 3.7.7 5.2.9 2.8 3.1 3.3 4.3 3.3zm12.3-.2V7.2H43v17.3h4.1zm8.1-2.3c-1.4-2-2-3.7-2-6.5V1.1h-4.1v14.6c0 3.7.8 6.3 2.8 8.9 0 .1 3.3-2.4 3.3-2.4zm8.4 2.5c3.9 0 6.5-2.3 7.5-5.1l-3.7-1c-.6 1.5-1.9 2.4-3.7 2.4-2.3 0-4.3-1.8-4.4-4.5h12.4c.5-5.2-2.5-9.7-8.1-9.7-4.8 0-8.3 3.6-8.3 8.9-.1 5 3.3 9 8.3 9zm4.2-11.2h-8.4c.4-1.9 2.1-3.3 4.2-3.3 2.2 0 3.9 1.5 4.2 3.3z" />
  </svg>
);

Swile.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
};

Swile.defaultProps = {
  className: null,
  id: null,
};

export default Swile;
