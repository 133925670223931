import React from "react";
import { ReactComponent as Logo } from "../../assets/eatmachine_white.svg";
import styles from "./home.module.css";

const Home = () => (
  <div className={styles["logo-container"]}>
    <Logo className={styles.logo} />
  </div>
);

export default Home;
