// eslint-disable-next-line import/no-mutable-exports
let storage = null;

try {
  window.localStorage.setItem("TEST_STORAGE", "1");
  window.localStorage.removeItem("TEST_STORAGE");
  storage = window.localStorage;
} catch (e) {
  storage = window.sessionStorage;
}

export default storage;
