import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Check from "../icons/Check";
import styles from "./button.module.scss";
import Close from "../icons/Close";
import Loading from "../icons/Loading";

const PromiseButton = ({
  children,
  promise,
  onSuccess,
  onError,
  disabled,
  primaryStyle,
  secondaryStyle,
  errorStyle,
}) => {
  const [infos, setInfos] = useState({
    loading: false,
    error: null,
    data: null,
  });

  const action = () => {
    setInfos({ data: null, loading: true, error: null });
    promise()
      .then((data) => {
        setInfos({ data, loading: false, error: null });
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch((error) => {
        setInfos({ data: null, loading: false, error });
        if (onError) {
          onError(error);
        }
      });
  };

  const { loading, error, data } = infos;
  const success = data !== null && error === null && !loading;
  const failure = data === null && error !== null && !loading;

  return (
    <Button
      disabled={disabled || loading || error !== null || data !== null}
      primary={primaryStyle}
      secondary={secondaryStyle}
      error={errorStyle}
      onClick={action}
    >
      {children}
      {loading && (
        <Loading className={[styles.chevron, styles.loading].join(" ")} />
      )}
      {success && <Check className={styles.chevron} />}
      {failure && <Close className={styles.chevron} />}
    </Button>
  );
};

PromiseButton.propTypes = {
  children: PropTypes.node.isRequired,
  promise: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  disabled: PropTypes.bool,
  primaryStyle: PropTypes.bool,
  secondaryStyle: PropTypes.bool,
  errorStyle: PropTypes.bool,
};

PromiseButton.defaultProps = {
  onSuccess: undefined,
  onError: undefined,
  disabled: false,
  primaryStyle: false,
  secondaryStyle: false,
  errorStyle: false,
};

export default PromiseButton;
