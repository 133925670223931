import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./button.module.scss";

const Button = ({
  innerRef,
  dispatch,
  children,
  primary,
  secondary,
  error,
  disabled,
  className,
  theme,
  themable,
  ...rest
}) => {
  const classNames = [styles.button, className];
  const style = {};
  if (primary) {
    classNames.push(styles.button_primary);
    if (themable && theme) {
      style.backgroundColor = theme.primaryColor;
    }
  }
  if (secondary) {
    classNames.push(styles.button_secondary);
  }
  if (error) {
    classNames.push(styles.button_error);
  }
  return (
    <button
      style={style}
      ref={innerRef}
      type="button"
      className={classNames.join(" ")}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  dispatch: PropTypes.func,
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.shape({
    primaryColor: PropTypes.string,
  }),
  themable: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

Button.defaultProps = {
  dispatch: null,
  primary: false,
  secondary: false,
  error: false,
  disabled: false,
  className: undefined,
  theme: null,
  themable: true,
  innerRef: null,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});

const ConnectedButton = connect(mapStateToProps)(Button);

export default React.forwardRef((props, ref) => (
  <ConnectedButton {...props} innerRef={ref} />
));
