/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const UpDejeuner = ({ id, className }) => (
  <svg
    width="50"
    height="17"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 159.8 51.3"
    className={className}
  >
    <path
      d="M46.3 25.7c0-1.6.6-3.2 1.5-4.5V4H4.2v43.6h43.6V30.2c-1-1.3-1.5-2.8-1.5-4.5z"
      fill="#f48f00"
    />
    <path
      d="M30.3 19.5c3.4-2.2 7.3-1.1 9.3 1.4 2.2 2.8 2.2 6.8 0 9.5-2.1 2.6-5.9 3.7-9.3 1.5-.1.2-.1.3-.1.5v4.2c0 .1.1.3-.2.4l-3.3.8V18.9c0-.3.1-.4.4-.5l2.8-.7c.3-.1.3 0 .3.3.1.4.1 1 .1 1.5zm-5.3 1v6.4c0 3.4-2.3 5.8-5.7 6.2-1.4.2-2.8.1-4.1-.5-2.1-.9-3.2-2.5-3.5-4.7-.1-.5-.1-1.1-.1-1.6v-12c0-.3.1-.4.4-.5l2.8-.6c.3-.1.4 0 .4.3V26c0 .6 0 1.1.2 1.7.3 1.2 1.1 1.8 2.5 2 .5.1 1 0 1.5-.1 1-.3 1.5-.9 1.7-1.8.2-.6.2-1.2.2-1.8V14.4c0-.3.1-.4.4-.5l2.9-.6c.3-.1.4 0 .4.3v6.9z"
      fill="#fefefe"
    />
    <g fill="#f48f00">
      <path d="M30 25.8c0-2 1.3-3.7 3.1-4.1 2.3-.5 4.5 1 4.9 3.3.4 2.1-.6 4-2.4 4.8-2.4 1-5-.5-5.5-3.1-.1-.4-.1-.7-.1-.9z" />
      <use xlinkHref="#prefix__B" />
      <path d="M83.7 32c0 2.4-.4 3.8-1.4 4.8s-2 1.4-3.8 1.4h-1l-1-3.2h.8c1.2 0 2.1-.3 2.6-.9.4-.5.5-.9.5-2.1V18.6l3.4-.4-.1 13.8zm15.7-.7c-1.7 1.2-3.8 1.9-6 1.9-4.3 0-7.7-3.3-7.7-7.6 0-4.2 3.5-7.6 7.7-7.6 2.1 0 4.2.9 5.6 2.4.5.6.9 1.1 1.3 2.1l-9.9 6c.7 1 1.6 1.5 3 1.5s2.6-.5 4.5-1.7l1.5 3zm-3.5-9.2c-.8-.7-1.5-.9-2.4-.9-2.4 0-4.4 2-4.4 4.4v.6l6.8-4.1zm14.9 10.7V31c-1.4 1.5-2.4 2-4 2s-3-.7-3.8-1.8c-.6-.8-.8-1.8-.8-3.4v-9.3l3.4-.4v8.1l.1 2.1c.2.8 1 1.4 1.9 1.4 1 0 2-.5 2.5-1.2.5-.6.6-1.4.6-3v-7l3.4-.4v14.2l-3.3.5zm14.7 0v-8.7c0-1.1-.1-1.5-.3-1.9-.3-.6-1.1-1-1.8-1-1.1 0-2.1.6-2.6 1.5-.3.6-.4 1.4-.4 3v6.8l-3.4.4V18.6l3.3-.4v2c.9-1.6 2.1-2.2 3.9-2.2 1.5 0 2.7.6 3.5 1.5.8 1 1.1 1.9 1.1 3.7v9.2l-3.3.4z" />
      <use xlinkHref="#prefix__B" x={67.3} />
      <path d="M147.3 32.8V18.6l3.4-.4v2.3c1.3-2 2.4-2.6 4.8-2.6h.7l-1.3 3.8h-.5c-1.2 0-2.2.5-2.8 1.3-.6.7-.9 2.1-.9 3.9v5.6l-3.4.3zM72.1 12.6h3.8l-3.6 4h-3zm7.823 2.437l2.333-2.333 2.333 2.333-2.333 2.333zM62.3 13.4v19.2l-3 .4v-1.7c-1.6 1.3-2.9 1.8-4.4 1.8-3.7 0-6.9-3.4-6.9-7.4s3.2-7.4 7-7.4c1.5 0 2.6.4 4.1 1.5v-6l3.2-.4zm-7 16.6c2.2 0 4-1.9 4-4.2s-1.9-4.3-4.1-4.3c-2.1 0-3.9 1.9-3.9 4.2 0 2.4 1.7 4.3 4 4.3z" />
    </g>
    <defs>
      <path
        id="prefix__B"
        d="M77.7 31.3c-1.7 1.2-3.8 1.9-6 1.9-4.3 0-7.7-3.3-7.7-7.6 0-4.2 3.5-7.6 7.7-7.6 2.1 0 4.2.9 5.6 2.4.5.6.9 1.1 1.3 2.1l-9.9 6c.7 1 1.6 1.5 3 1.5s2.6-.5 4.5-1.7l1.5 3zm-3.4-9.2c-.8-.7-1.5-.9-2.4-.9-2.4 0-4.4 2-4.4 4.4v.6l6.8-4.1z"
      />
    </defs>
  </svg>
);

UpDejeuner.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
};

UpDejeuner.defaultProps = {
  className: null,
  id: null,
};

export default UpDejeuner;
