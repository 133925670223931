import React from "react";
import { Redirect } from "react-router-dom";
import * as PropTypes from "prop-types";
import { usePolling } from "../hooks";
import client from "../../services/client";
import BigText from "../common/BigText";
import { ERROR, LOADING, NEW_CARD_PENDING } from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";

const WaitForCard = ({
  translate,
  restaurateur,
  redirectPath,
  match: {
    params: { newCardToken },
  },
}) => {
  const [loading, cards, error] = usePolling(
    () => client.cards(restaurateur.appPaymentProviders),
    {
      interval: 3000,
    }
  );

  const newCardConfirmed =
    cards && cards["hydra:member"].some((card) => card.token === newCardToken);

  if (newCardConfirmed) {
    return <Redirect to={redirectPath} />;
  }

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  return <BigText>{translate(NEW_CARD_PENDING)}</BigText>;
};

WaitForCard.propTypes = {
  translate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      newCardToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  restaurateur: PropTypes.shape({
    appPaymentProviders: PropTypes.array,
  }).isRequired,
  redirectPath: PropTypes.string.isRequired,
};

export default translated(WaitForCard);
