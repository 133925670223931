import React from "react";
import * as PropTypes from "prop-types";

const Nav = ({ className }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14 8H16V10H14V8ZM10 0H17C18.11 0 19 0.89 19 2V16H20V18H0V16H8V2C8 0.89 8.89 0 10 0ZM10 2V16H17V2H10Z"
      fill="#2A2A72"
    />
  </svg>
);

Nav.propTypes = {
  className: PropTypes.string,
};

Nav.defaultProps = {
  className: null,
};

export default Nav;
