import { CLOSE_BURGER, OPEN_BURGER, TOGGLE_BURGER } from "./actionTypes";

const initialState = {
  opened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_BURGER:
      return { ...state, opened: true };
    case "user/logout":
    case "@@router/LOCATION_CHANGE":
    case CLOSE_BURGER:
      return { ...state, opened: false };
    case TOGGLE_BURGER:
      return { ...state, opened: !state.opened };
    default:
      return state;
  }
};
