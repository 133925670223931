/* eslint-disable max-len */
import * as messages from "../messages";

export default {
  [messages.TXT_OR]: "ou",
  [messages.CANCEL]: "Annuler",
  [messages.BACK]: "Retour",
  [messages.REGISTER_TITLE]: "M'inscrire",
  [messages.EMAIL_ADDRESS]: "Adresse e-mail",
  [messages.PASSWORD]: "Mot de passe",
  [messages.FIRSTNAME]: "Prénom",
  [messages.LASTNAME]: "Nom",
  [messages.REGISTER_FORM_SUBMITTED]:
    "Vous allez recevoir un e-mail avec un lien de confirmation !",
  [messages.LOADING]: "Chargement en cours...",
  [messages.TOKEN_NOT_RECOGNIZED]:
    "Impossible de valider l'email ou email déjà validé !",
  [messages.ACTIVATED_ACCOUNT]: "Votre compte a été activé !",
  [messages.UNKNOWN_TOKEN_TYPE]: "Type de jeton non reconnu !",
  [messages.REQUIRED_VALUE]: "Cette valeur est requise",
  [messages.REQUIRED_LABEL]: "obligatoire",
  [messages.INVALID_EMAIL_ADRESS_VALUE]:
    "Cette valeur doit être une adresse e-mail",
  [messages.REGISTRATION]: "Inscription",
  [messages.UNSUPPORTED_LOCALE]: "Language non supporté",
  [messages.PAGE_NOT_FOUND]: "La page est introuvable",
  [messages.LOGIN_TITLE]: "Connexion",
  [messages.FORGOT_PASSWORD_TITLE]: "Mot de passe oublié ?",
  [messages.FORGOT_PASSWORD_SENT]:
    "Un email avec un lien pour réinitialiser votre mot de passe vous a été envoyé.",
  [messages.FORGOT_PASSWORD_ACTION]: "Mot de passe oublié ?",
  [messages.FORGOT_PASSWORD_SUCCESS]:
    "Votre mot de passe a bien été réinitialisé !",
  [messages.LOGIN]: "Connexion",
  [messages.LOGIN_ACTION]: "Me connecter",
  [messages.REGISTER_ACTION]: "Créer un compte",
  [messages.FRIDGE_FORM_TITLE]: "Quel est votre frigo ?",
  [messages.OPEN_FRIDGE]: "Aller dans mon frigo",
  [messages.FRIDGE_NAME]: "Nom du frigo",
  [messages.FRIDGE_NOT_FOUND]: "Aïe, ce frigo n'existe pas ! Êtes-vous sûr ?",
  [messages.FRIDGE_UNAVAILABLE]:
    "Aïe, ce frigo est indisponible ! Êtes-vous sûr ?",
  [messages.SALE_NOT_AUTHORIZED]:
    "Echec de l'autorisation. Merci d’essayer un autre moyen de paiement.",
  [messages.SAVE_CARD_FAILED]:
    "Votre banque refuse l’enregistrement de votre carte. Merci d’essayer un autre moyen de paiement.",
  [messages.FRIDGE_W_NAME]: "Frigo %{name}",
  [messages.BACK_ACTION]: "Retour",
  [messages.OPEN_ACTION]: "Ouvrir la porte",
  [messages.WAIT_ACTION]: "Patientez SVP",
  [messages.RETRY]: "Réessayer",
  [messages.ERROR]: "Une erreur est survenue !",
  [messages.FRIDGE_IS_OPENING]: "Le frigo s'ouvre, veuillez patienter ...",
  [messages.FRIDGE_IS_OPENED_BUY]: "Le frigo est ouvert, servez-vous !",
  [messages.FRIDGE_IS_OPENED_DELIVER]:
    "Le frigo est ouvert, vous pouvez ajouter les produits !",
  [messages.FRIDGE_IS_OPENED_REMOVE]:
    "Le frigo est ouvert, vous pouvez retirer les produits !",
  [messages.FRIDGE_IS_OPENED_MAINTENANCE]: "Le frigo est ouvert !",
  [messages.ORDER]: "Commande",
  [messages.SUBMIT]: "Valider",
  [messages.ENJOY_YOUR_MEAL]: "Bon appétit !",
  [messages.DELIVERY_FINISHED]: "Livraison terminée !",
  [messages.MAINTENANCE_FINISHED]: "Maintenance terminée !",
  [messages.FINISHED]: "Terminé !",
  [messages.TOTAL]: "Total",
  [messages.WITH_TAXES]: "Taxes : %{tax}%",
  [messages.BILLED_AMOUNT]: "Facturation de",
  [messages.BILLED_ON_CARD]: "sur la carte",
  [messages.END_ACTION]: "Terminer",
  [messages.EMAIL_ALREADY_EXISTS]:
    "Un utilisateur avec cette adresse e-mail existe déjà",
  [messages.DOOR_TIMED_OUT]:
    "Oups ! Une erreur est survenue, merci de ressayer plus tard",
  [messages.FRIDGE_FORM_DELIVERY_TITLE]: "Quel frigo livrez-vous ?",
  [messages.DELIVER_FRIDGE]: "Livrer le frigo",
  [messages.MY_PAYMENT_CARDS]: "Mes cartes",
  [messages.MY_PURCHASES]: "Mes achats",
  [messages.CONTACT]: "Contact",
  [messages.ADD_PRODUCT_ACTION]: "Ajouter un produit",
  [messages.OUTDATED_PRODUCTS_ACTION]:
    "%{smart_count} produit périmé |||| %{smart_count} produits périmés",
  [messages.PRODUCT]: "Produit",
  [messages.SEE_OFFERS]: "Voir la liste des produits",
  [messages.FRIDGE_OFFERS]: "Dans le frigo",
  [messages.NO_OFFERS]: "Aucun produit disponible",
  [messages.LOGOUT]: "Déconnexion",
  [messages.ACCEPT_TERMS]:
    "en cliquant sur inscription, vous êtes d'accord avec les %{link}",
  [messages.TERMS]: "C.G.V.U.S",
  [messages.SEND_BY_MAIL]: "Envoyer par email",
  [messages.MY_PAYMENT_CARD]: "Ma carte de paiement",
  [messages.ADD_PAYMENT_CARD]: "Ajouter une carte de paiement",
  [messages.ADD_SECONDARY_PAYMENT_CARD]: "Ajouter un moyen de paiement",
  [messages.SECONDARY_CARD_CAN_BE_ADDED_LATER]:
    "Vous pourrez ensuite ajouter une carte restaurant",
  [messages.ADD]: "Ajouter",
  [messages.OUTDATED_PRODUCTS]: "Produits périmés",
  [messages.REMOVING_FINISHED]: "Produits enlevés !",
  [messages.CARD_ADD]: "Ajouter une carte de paiement",
  [messages.CARD_HOLDER]: "Titulaire de la carte",
  [messages.CARD_HOLDER_SUBMIT]: "Continuer",
  [messages.SAVE_CARD_INFO]:
    "Nous conservons vos données de carte bancaire pour vous éviter de les saisir de nouveau lors de vos futurs achats",
  [messages.SAVE_CARD]: "J'enregistre ma carte",
  [messages.NEW_CARD_PENDING]:
    "Vérification de la carte, veuillez patienter ...",
  [messages.CARD_DELETE]: "Supprimer ma carte",
  [messages.CARD_DELETE_CONFIRM]:
    "Êtes-vous sûr de vouloir supprimer votre carte n°%{number}",
  [messages.NO]: "Non",
  [messages.YES]: "Oui",
  [messages.EXPIRED_CARD]: "Carte expirée",
  [messages.VALID_CARD]: "Carte valide",
  [messages.PRIMARY_CARD_MISSING]:
    "Une carte bancaire complémentaire est requise",
  [messages.ADD_CARD_ERROR]:
    "Oh zut, une erreur est survenue! Réessayez plus tard",
  [messages.LANGS]: "Langages",
  [messages.FAVORITE_CARD]: "Ma carte préférée",
  [messages.OTHER_CARDS]: "Mes cartes complémentaires",
  [messages.SEE_YOU_SOON]: "À bientôt !",
  [messages.THANKS]: "Merci !",
  [messages.FRIDGE_IS_CLOSED_BUY]: "Nous préparons l'addition...",
  [messages.FRIDGE_IS_CLOSED_DELIVER]:
    "Le frigo est fermé, veuillez patienter ...",
  [messages.FRIDGE_IS_CLOSED_REMOVE]:
    "Le frigo est fermé, veuillez patienter ...",
  [messages.FRIDGE_IS_CLOSED_MAINTENANCE]:
    "Le frigo est fermé, veuillez patienter ...",
  [messages.MY_FRIDGE]: "Mon frigo",
  [messages.RECENT_FRIDGE]: "Frigos récents",
  [messages.EMPTY_RECENT_FRIDGE]: "Aucuns frigos récents",
  [messages.FRIDGE_STATUS_AVAILABLE]: "Disponible",
  [messages.FRIDGE_STATUS_BUSY]: "Utilisé",
  [messages.FRIDGE_STATUS_UNAVAILABLE]: "Indisponible",
  [messages.FRIDGE_IS_OPENED_BUY_LABEL]: "Frigo ouvert !",
  [messages.FRIDGE_IS_OPENED_BUY_LABEL_MORE]: "Tout est pour vous !",
  [messages.FRIDGE_IS_OPENED_BUY_TEXT]:
    "Prenez ce que vous voulez, vous serez facturé automatiquement en fermant la porte.",
  [messages.FOOTER_TEXT]: "Produit par %{link}",
  [messages.FOOTER_LINK]: "Eat Machine",
  [messages.LOAD_MORE]: "Voir plus",
  [messages.TUTORIAL_STEP_1_TITLE]: "Ouvrez",
  [messages.TUTORIAL_STEP_1_DESCRIPTION]:
    "Utilisez le bouton sur l’écran de votre téléphone pour déverrouiller la porte du frigo",
  [messages.TUTORIAL_STEP_2_TITLE]: "Choisissez",
  [messages.TUTORIAL_STEP_2_DESCRIPTION]:
    "Prenez ce qui vous fait envie dans le frigo",
  [messages.TUTORIAL_STEP_3_TITLE]: "Facturé !",
  [messages.TUTORIAL_STEP_3_DESCRIPTION]:
    "Une fois la porte fermée, le frigo détecte ce que vous avez pris et vous envoie la facture",
  [messages.TUTORIAL_CLOSE_ACTION]: "Fermer",
  [messages.TUTORIAL_NEXT_STEP_ACTION]: "Suivant",
  [messages.TUTOTIAL_START_ACTION]: "Commencer",
  [messages.UNKNOWN_PRODUCT]: "Produit inconnu",
  [messages.PRODUCTS_AVAILABLE]:
    "%{smart_count} disponible |||| %{smart_count} disponibles",
  [messages.IN_PROMOTION]:
    "%{smart_count} en promo |||| %{smart_count} en promo !",
  [messages.TAGS_IN_PROMOTION]: "Numéros produits concernés",
  [messages.SEE_PROMOTION]: "Voir",
  [messages.PROMOTION_DISCOUNT]: "Réduction promo",
  [messages.MENU]: "Menu",
  [messages.MENU_LG]: "Au menu aujourd'hui",
  [messages.OPEN_FRIDGE_WITH]: "Ouvrir le frigo",
  [messages.PAY_WITH_CARD_BTN]: "Cartes",
  [messages.PAY_WITH_CARD_BTN_2]: "pour ouvrir",
  [messages.PAY_WITH_CARD_BTN_3]: "Appuyer pour ouvrir",
  [messages.PAY_WITH_APP_BTN]: "Scannez",
  [messages.PAY_WITH_APP_BTN_2]: "pour ouvrir",
  [messages.PAY_WITH_CARD]: "Ouvrir le frigo avec la carte",
  [messages.PAY_WITH_APP]: "Ouvrir le frigo avec l'appli",
  [messages.AUTHORIZATION_DISCLAIMER]:
    "Attention ! Une caution est prélevée quand vous ouvrez la porte, elle vous est restituée à la fermeture. Cette somme peut apparaitre quelques instant sur votre relevé bancaire.",
  [messages.SCAN_QR_CODE]: "Scannez le QR Code",
  [messages.SCAN_QR_CODE_ALT]: " ou connectez-vous à l'adresse",
  [messages.NAME_OF_FRIDGE]: "Nom du frigo: %{name}",
  [messages.ACCEPTED_PAYMENT_METHODS]: "Moyens de paiements acceptés",
  [messages.SWIPE_CARD]: "Passer votre carte devant le lecteur",
  [messages.FRIDGE_STATUS_FRIDGE]: "Frigo: %{name}",
  [messages.POS_PAYMENT_WAITING_AUTHORIZATION]: "Autorisation",
  [messages.POS_PAYMENT_WAITING_AUTHORIZATION_DESC]:
    "Demande d’autorisation bancaire en cours... plus que quelques secondes à attendre.",
  [messages.POS_PAYMENT_AUTHORIZATION_FAILURE]: "Oups !",
  [messages.POS_PAYMENT_AUTHORIZATION_FAILURE_DESC]:
    "Cette carte a été refusée par la banque. Merci d’essayer un autre moyen de paiement.",
  [messages.NFC_PAYMENT_AUTHORIZATION_FAILURE]: "Oups !",
  [messages.NFC_PAYMENT_AUTHORIZATION_FAILURE_DESC]:
    "Désolé, ce badge n'est pas accepté.",
  [messages.TAKE_A_SCREEN_PICTURE_LINE_1]: "Photographiez l'écran",
  [messages.TAKE_A_SCREEN_PICTURE_LINE_2]: "pour conserver une facture",
  [messages.NOTIF_CLOSE_FRIDGE_TITLE]: "Oups le frigo est ouvert.",
  [messages.NOTIF_CLOSE_FRIDGE_DESC]: "Merci de fermez la porte rapidement.",
  [messages.BILLING_ADDRESS]: "Adresse de facturation",
  [messages.ADDRESS_LINE1]: "Adresse",
  [messages.ADDRESS_LINE2]: "Complément d'addresse",
  [messages.ADDRESS_POSTAL_CODE]: "Code postal",
  [messages.ADDRESS_CITY]: "Ville",
  [messages.ADDRESS_COUNTRY]: "Pays",
};
