import React from "react";
import PropTypes from "prop-types";
import styles from "./bigtext.module.css";

const BigText = ({ children }) => (
  <div className={styles["big-text"]}>
    <div className={styles["text-aligner"]}>{children}</div>
  </div>
);

BigText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BigText;
