import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import FridgeRoutes from "./FridgeRoutes";
import { ERROR, FRIDGE_NOT_FOUND, LOADING } from "../../services/i18n/messages";
import BigText from "../common/BigText";
import translated from "../../services/i18n/translated";
import FridgeView from "../fridge-view/FridgeView";
import ErrorPage from "../common/ErrorPage";

const FridgeRouter = ({
  restaurateur,
  currentSlug,
  slug,
  load,
  loading,
  fridge,
  error,
  translate,
  noInteraction,
  initTheme,
  initKiosk,
  clearKiosk,
}) => {
  // eslint-disable-next-line no-console
  console.log("render FridgeRouter");

  if (
    !loading &&
    (!error || currentSlug !== slug) &&
    (!fridge || currentSlug !== slug)
  ) {
    load({ restaurateurSlug: restaurateur.slug, slug });
  }

  useEffect(() => {
    if (noInteraction) {
      initKiosk({
        restaurateurSlug: restaurateur?.slug,
        slug: fridge?.slug,
      });
    } else {
      clearKiosk();
    }
    initTheme(fridge);
  }, [fridge, restaurateur, noInteraction, initKiosk, initTheme, clearKiosk]);

  if (error) {
    return (
      <ErrorPage
        message={error.message === FRIDGE_NOT_FOUND ? error.message : ERROR}
      />
    );
  }

  if (loading || !fridge) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  return (
    <>
      <FridgeView
        restaurateur={restaurateur}
        fridge={fridge}
        noInteraction={noInteraction}
      >
        <FridgeRoutes restaurateur={restaurateur} fridge={fridge} />
      </FridgeView>
    </>
  );
};

FridgeRouter.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  noInteraction: PropTypes.bool,
  load: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
  error: PropTypes.instanceOf(Error),
  currentSlug: PropTypes.string,
  initTheme: PropTypes.func.isRequired,
  initKiosk: PropTypes.func.isRequired,
  clearKiosk: PropTypes.func.isRequired,
};

FridgeRouter.defaultProps = {
  noInteraction: false,
  loading: false,
  fridge: null,
  error: null,
  currentSlug: null,
};

const mapStateToProps = (state) => ({
  loading: state.fridge.loading,
  fridge: state.fridge.data,
  error: state.fridge.error,
  currentSlug: state.fridge.slug,
});

const mapDispatchToProps = (dispatch) => ({
  load: dispatch.fridge.load,
  initKiosk: dispatch.kiosk.init,
  clearKiosk: dispatch.kiosk.clear,
  initTheme: dispatch.theme.init,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translated(FridgeRouter));
