import React, { Component } from "react";
import * as PropTypes from "prop-types";
import translated from "../services/i18n/translated";
import ErrorPage from "./common/ErrorPage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err, info) {
    this.setState({ hasError: true });

    // eslint-disable-next-line no-console
    console.error(err);
  }

  render() {
    const {
      state: { hasError },
      props: { children },
    } = this;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default translated(ErrorBoundary);
