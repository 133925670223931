export default {
  state: {
    backButton: false,
    backPath: null,
    visible: true,
    menuButton: true,
  },
  reducers: {
    showBackButton: (state) => ({ ...state, backButton: true }),
    hideBackButton: (state) => ({ ...state, backButton: false }),
    setBackPath: (state, payload) => ({ ...state, backPath: payload }),
    show: (state) => ({ ...state, visible: true }),
    hide: (state) => ({ ...state, visible: false }),
    showMenuButton: (state) => ({ ...state, menuButton: true }),
    hideMenuButton: (state) => ({ ...state, menuButton: false }),
  },
};
