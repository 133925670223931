import React from "react";
import PropTypes from "prop-types";
import styles from "./fridgestatus.module.css";
import {
  FRIDGE_STATUS_AVAILABLE,
  FRIDGE_STATUS_BUSY,
  FRIDGE_STATUS_FRIDGE,
  FRIDGE_STATUS_UNAVAILABLE,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import Available from "../common/icons/Available";
import UnAvailable from "../common/icons/UnAvailable";

const FridgeStatus = ({ fridge, restaurateur, translate, noInteraction }) => {
  const available = fridge.available && fridge.enabled;
  return (
    <div className={styles.container}>
      <div className={styles["fridge-status"]}>
        <div className={styles.info}>
          {!noInteraction && <span>{restaurateur.name}</span>}
          <span>
            {noInteraction
              ? translate(FRIDGE_STATUS_FRIDGE, { name: fridge.name })
              : fridge.name}
          </span>
        </div>
        <div
          className={[
            styles.status,
            available ? styles.open : styles.closed,
          ].join(" ")}
        >
          <span className={styles.text}>
            {available && !fridge.opened && translate(FRIDGE_STATUS_AVAILABLE)}
            {available && fridge.opened && translate(FRIDGE_STATUS_BUSY)}
            {!available && translate(FRIDGE_STATUS_UNAVAILABLE)}
          </span>
          {available ? (
            <Available className={styles.icon} />
          ) : (
            <UnAvailable className={styles.icon} />
          )}
        </div>
      </div>
    </div>
  );
};

FridgeStatus.defaultProps = {
  noInteraction: false,
};

FridgeStatus.propTypes = {
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    opened: PropTypes.bool.isRequired,
  }).isRequired,
  restaurateur: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  noInteraction: PropTypes.bool,
};

export default translated(FridgeStatus);
