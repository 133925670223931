import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./fridge.module.scss";
import {
  ERROR,
  LOADING,
  MENU_LG,
  NO_OFFERS,
  OPEN_FRIDGE_WITH,
  TXT_OR,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import BigText from "../common/BigText";
import Button from "../common/buttons/Button";
import { offersToProductList } from "../../services/helpers/offer";
import ProductList from "../common/ProductList";
import Page, { PageContent } from "../common/Page";
import redirect from "../../services/routing/redirect";
import Notification from "../fridge-popup/Notification";
import { usePaymentStatus } from "../../services/PaymentAuthorization";
import {
  PaymentMethodCard,
  PaymentMethodApp,
} from "../fridge-popup/PaymentMethodPopup";
import FridgeMedias from "../fridge-medias/FridgeMedias";
import ProductPopup from "../fridge-popup/ProductPopup";
import PaymentMethodButton from "../common/buttons/PaymentMethodButton";
import client from "../../services/client";

const NoInteractionFridge = ({
  translate,
  theme,
  restaurateur,
  fridge,
  loading,
  error,
  products,
}) => {
  // eslint-disable-next-line no-console
  console.log("render NoInteractionFridge", products);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const [dismissed, setDismissed] = React.useState(false);

  const {
    nfcAuthorisationFailure,
    posAuthorisationFailure,
    posCardPresented,
  } = usePaymentStatus(fridge);

  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    if (!fridge.opened) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log("fridge opened, redirection to wait door");

    redirect(
      `/${restaurateur.slug}/${fridge.slug}/orders/current/wait_for_door/no-interaction`
    );
  }, [fridge.opened, fridge.slug, restaurateur.slug]);

  useEffect(() => {
    if (
      nfcAuthorisationFailure ||
      posAuthorisationFailure ||
      posCardPresented
    ) {
      setSelectedPaymentMethod(null);
      setDismissed(false);
    }
  }, [nfcAuthorisationFailure, posAuthorisationFailure, posCardPresented]);

  const onSelectCard = useCallback(() => {
    client.activateTerminal(fridge.slug);
    setSelectedPaymentMethod("card");
  }, [fridge.slug, setSelectedPaymentMethod]);

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  if (products.length === 0) {
    return (
      <>
        <FridgeMedias fridge={fridge} />
        <Page>
          <PageContent>
            <div className={styles.container}>
              <div className={styles.buttons}>
                <Button primary disabled>
                  {translate(NO_OFFERS)}
                </Button>
              </div>
            </div>
          </PageContent>
        </Page>
      </>
    );
  }

  const QRCodeUrl = `${window.location.protocol}//${window.location.host}/${restaurateur.slug}/${fridge.slug}`;

  return (
    <>
      <FridgeMedias fridge={fridge} />
      {nfcAuthorisationFailure && !dismissed && (
        <Notification
          showClose
          type="nfcRefused"
          onClick={() => setDismissed(true)}
        />
      )}
      {posAuthorisationFailure && !dismissed && (
        <Notification
          showClose
          type="posRefused"
          onClick={() => setDismissed(true)}
        />
      )}
      {posCardPresented && <Notification type="posWaiting" />}
      {selectedPaymentMethod === "card" && (
        <PaymentMethodCard onClose={() => setSelectedPaymentMethod(null)} />
      )}
      {selectedPaymentMethod === "app" && (
        <PaymentMethodApp
          url={QRCodeUrl}
          restaurateur={restaurateur}
          fridge={fridge}
          onClose={() => setSelectedPaymentMethod(null)}
        />
      )}

      {!selectedPaymentMethod && selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}

      {!fridge.terminal &&
        !fridge.hideQRCode &&
        theme?.enableHomePaymentMethodsHelp && (
          <div className={styles.qrcode}>
            <PaymentMethodButton
              type="app"
              restaurateur={restaurateur}
              fridge={fridge}
              onClick={() => setSelectedPaymentMethod("app")}
            />
          </div>
        )}

      {fridge.terminal &&
        !fridge.hideQRCode &&
        theme?.enableHomePaymentMethodsHelp && (
          <h3 className={styles.paymentMethodsTitle}>
            {translate(OPEN_FRIDGE_WITH)}
          </h3>
        )}

      {fridge.terminal &&
        fridge.hideQRCode &&
        theme?.enableHomePaymentMethodsHelp && (
          <div className={styles.qrcode}>
            <PaymentMethodButton
              type="card"
              restaurateur={restaurateur}
              fridge={fridge}
              onClick={() => onSelectCard()}
            />
          </div>
        )}

      {fridge.terminal &&
        theme?.enableHomePaymentMethodsHelp &&
        !fridge.hideQRCode && (
          <div className={`${styles.buttons} ${styles.paymentMethods}`}>
            <div className={styles.paymentMethodsBtn}>
              <PaymentMethodButton
                type="app"
                restaurateur={restaurateur}
                fridge={fridge}
                onClick={() => setSelectedPaymentMethod("app")}
              />
            </div>
            <div className={styles.paymentMethodsSeparator}>
              <hr />
              <div className={styles.paymentMethodsOr}>{translate(TXT_OR)}</div>
              <hr />
            </div>
            <div className={styles.paymentMethodsBtn}>
              <PaymentMethodButton
                type="card"
                restaurateur={restaurateur}
                fridge={fridge}
                onClick={() => onSelectCard()}
              />
            </div>
          </div>
        )}

      {products.length > 0 && (
        <ProductList
          title={translate(MENU_LG)}
          products={products}
          onProductClick={(p, target) => {
            if (target === "picture" || target === "info") {
              setSelectedProduct(p);
            }
          }}
          noInteraction={true}
        />
      )}
    </>
  );
};

NoInteractionFridge.propTypes = {
  theme: PropTypes.shape({
    enableHomePaymentMethodsHelp: PropTypes.bool,
  }),
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    opened: PropTypes.bool.isRequired,
    terminal: PropTypes.bool.isRequired,
    hideQRCode: PropTypes.bool.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

NoInteractionFridge.defaultProps = {
  theme: null,
  products: [],
  loading: false,
  error: null,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  products: state.offers.data
    ? offersToProductList(state.offers.data["hydra:member"])
    : [],
  loading: state.offers.loading,
  error: state.offers.error,
  lastOpeningErrorCode: PropTypes.string,
  lastOpeningErrorDate: PropTypes.string,
});

export default connect(mapStateToProps)(translated(NoInteractionFridge));
