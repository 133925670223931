import React from "react";
import PropTypes from "prop-types";
import styles from "./button.module.scss";

const MinimalistButton = ({ children, disabled, ...rest }) => {
  return (
    <button
      type="button"
      className={[styles.button, styles.button_minimalist].join(" ")}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

MinimalistButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

MinimalistButton.defaultProps = {
  disabled: false,
};

export default MinimalistButton;
