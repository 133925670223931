export const TXT_OR = "TXT_OR";
export const CANCEL = "CANCEL";
export const BACK = "BACK";
export const REGISTER_TITLE = "REGISTER_TITLE";
export const EMAIL_ADDRESS = "EMAIL_ADDRESS";
export const PASSWORD = "PASSWORD";
export const FIRSTNAME = "FIRSTNAME";
export const LASTNAME = "LASTNAME";
export const REGISTER_FORM_SUBMITTED = "REGISTER_FORM_SUBMITTED";
export const LOADING = "LOADING";
export const TOKEN_NOT_RECOGNIZED = "TOKEN_NOT_RECOGNIZED";
export const ACTIVATED_ACCOUNT = "ACTIVATED_ACCOUNT";
export const UNKNOWN_TOKEN_TYPE = "UNKNOW_TOKEN_TYPE";
export const REQUIRED_VALUE = "REQUIRED_VALUE";
export const REQUIRED_LABEL = "REQUIRED_LABEL";
export const INVALID_EMAIL_ADRESS_VALUE = "INVALID_EMAIL_ADRESS_VALUE";
export const REGISTRATION = "REGISTRATION";
export const UNSUPPORTED_LOCALE = "UNSUPPORTED_LOCALE";
export const PAGE_NOT_FOUND = "PAGE_NOT_FOUND";
export const FRIDGE_FORM_TITLE = "FRIDGE_FORM_TITLE";
export const OPEN_FRIDGE = "OPEN_FRIDGE";
export const FRIDGE_NAME = "FRIDGE_NAME";
export const FRIDGE_NOT_FOUND = "FRIDGE_NOT_FOUND";
export const FRIDGE_UNAVAILABLE = "FRIDGE_UNAVAILABLE";
export const SALE_NOT_AUTHORIZED = "SALE_NOT_AUTHORIZED";
export const SAVE_CARD_FAILED = "SAVE_CARD_FAILED";
export const LOGIN_TITLE = "LOGIN_TITLE";
export const LOGIN = "LOGIN";
export const FORGOT_PASSWORD_TITLE = "FORGOT_PASSWORD_TITLE";
export const FORGOT_PASSWORD_SENT = "FORGOT_PASSWORD_SENT";
export const FORGOT_PASSWORD_ACTION = "FORGOT_PASSWORD_ACTION";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FRIDGE_W_NAME = "FRIDGE_W_NAME";
export const LOGIN_ACTION = "LOGIN_ACTION";
export const REGISTER_ACTION = "REGISTER_ACTION";
export const OPEN_ACTION = "OPEN_ACTION";
export const WAIT_ACTION = "WAIT_ACTION";
export const BACK_ACTION = "BACK_ACTION";
export const RETRY = "RETRY";
export const ERROR = "ERROR";
export const FRIDGE_IS_OPENING = "FRIDGE_IS_OPENING";
export const FRIDGE_IS_OPENED_BUY = "FRIDGE_IS_OPENED_BUY";
export const FRIDGE_IS_OPENED_DELIVER = "FRIDGE_IS_OPENED_DELIVER";
export const FRIDGE_IS_OPENED_REMOVE = "FRIDGE_IS_OPENED_REMOVE";
export const FRIDGE_IS_OPENED_MAINTENANCE = "FRIDGE_IS_OPENED_MAINTENANCE";
export const ORDER = "ORDER";
export const SUBMIT = "SUBMIT";
export const ENJOY_YOUR_MEAL = "ENJOY_YOUR_MEAL";
export const DELIVERY_FINISHED = "DELIVERY_FINISHED";
export const MAINTENANCE_FINISHED = "MAINTENANCE_FINISHED";
export const FINISHED = "FINISHED";
export const TOTAL = "TOTAL";
export const WITH_TAXES = "WITH_TAXES";
export const BILLED_AMOUNT = "BILLED_AMOUNT";
export const BILLED_ON_CARD = "BILLED_ON_CARD";
export const END_ACTION = "END_ACTION";
export const EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS";
export const DOOR_TIMED_OUT = "DOOR_TIMED_OUT";
export const FRIDGE_FORM_DELIVERY_TITLE = "FRIDGE_FORM_DELIVERY_TITLE";
export const DELIVER_FRIDGE = "DELIVER_FRIDGE";
export const MY_PAYMENT_CARDS = "MY_PAYMENT_CARDS";
export const MY_PURCHASES = "MY_PURCHASES";
export const CONTACT = "CONTACT";
export const ADD_PRODUCT_ACTION = "ADD_PRODUCT_ACTION";
export const OUTDATED_PRODUCTS_ACTION = "OUTDATED_PRODUCTS_ACTION";
export const PRODUCT = "PRODUCT";
export const SEE_OFFERS = "SEE_OFFERS";
export const FRIDGE_OFFERS = "FRIDGE_OFFERS";
export const NO_OFFERS = "NO_OFFERS";
export const LOGOUT = "LOGOUT";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const TERMS = "TERMS";
export const SEND_BY_MAIL = "SEND_BY_MAIL";
export const MY_PAYMENT_CARD = "MY_PAYMENT_CARD";
export const ADD_PAYMENT_CARD = "ADD_PAYMENT_CARD";
export const ADD_SECONDARY_PAYMENT_CARD = "ADD_SECONDARY_PAYMENT_CARD";
export const SECONDARY_CARD_CAN_BE_ADDED_LATER =
  "SECONDARY_CARD_CAN_BE_ADDED_LATER";
export const CARD_NAME = "CARD_NAME";
export const CARD_HOLDER = "CARD_HOLDER";
export const CARD_HOLDER_SUBMIT = "CARD_HOLDER_SUBMIT";
export const SAVE_CARD_INFO = "SAVE_CARD_INFO";
export const SAVE_CARD = "SAVE_CARD";
export const NEW_CARD_PENDING = "NEW_CARD_PENDING";
export const ADD = "ADD";
export const OUTDATED_PRODUCTS = "OUTDATED_PRODUCTS";
export const REMOVING_FINISHED = "REMOVING_FINISHED";
export const CARD_ADD = "CARD_ADD";
export const CARD_DELETE = "CARD_DELETE";
export const CARD_DELETE_CONFIRM = "CARD_DELETE_CONFIRM";
export const NO = "NO";
export const YES = "YES";
export const EXPIRED_CARD = "EXPIRED_CARD";
export const VALID_CARD = "VALID_CARD";
export const PRIMARY_CARD_MISSING = "PRIMARY_CARD_MISSING";
export const ADD_CARD_ERROR = "ADD_CARD_ERROR";
export const LANGS = "LANGS";
export const FAVORITE_CARD = "FAVORITE_CARD";
export const OTHER_CARDS = "OTHER_CARDS";
export const SEE_YOU_SOON = "SEE_YOU_SOON";
export const THANKS = "THANKS";
export const FRIDGE_IS_CLOSED_BUY = "FRIDGE_IS_CLOSED_BUY";
export const FRIDGE_IS_CLOSED_DELIVER = "FRIDGE_IS_CLOSED_DELIVER";
export const FRIDGE_IS_CLOSED_REMOVE = "FRIDGE_IS_CLOSED_REMOVE";
export const FRIDGE_IS_CLOSED_MAINTENANCE = "FRIDGE_IS_CLOSED_MAINTENANCE";
export const MY_FRIDGE = "MY_FRIDGE";
export const RECENT_FRIDGE = "RECENT_FRIDGE";
export const EMPTY_RECENT_FRIDGE = "EMPTY_RECENT_FRIDGE";
export const FRIDGE_STATUS_AVAILABLE = "FRIDGE_STATUS_AVAILABLE";
export const FRIDGE_STATUS_BUSY = "FRIDGE_STATUS_BUSY";
export const FRIDGE_STATUS_UNAVAILABLE = "FRIDGE_STATUS_UNAVAILABLE";
export const FRIDGE_IS_OPENED_BUY_LABEL = "FRIDGE_IS_OPENED_BUY_LABEL";
export const FRIDGE_IS_OPENED_BUY_LABEL_MORE =
  "FRIDGE_IS_OPENED_BUY_LABEL_MORE";
export const FRIDGE_IS_OPENED_BUY_TEXT = "FRIDGE_IS_OPENED_BUY_TEXT";
export const FOOTER_TEXT = "FOOTER_TEXT";
export const FOOTER_LINK = "FOOTER_LINK";
export const LOAD_MORE = "LOAD_MORE";
export const TUTORIAL_STEP_1_TITLE = "TUTORIAL_STEP_1_TITLE";
export const TUTORIAL_STEP_1_DESCRIPTION = "TUTORIAL_STEP_1_DESCRIPTION";
export const TUTORIAL_STEP_2_TITLE = "TUTORIAL_STEP_2_TITLE";
export const TUTORIAL_STEP_2_DESCRIPTION = "TUTORIAL_STEP_2_DESCRIPTION";
export const TUTORIAL_STEP_3_TITLE = "TUTORIAL_STEP_3_TITLE";
export const TUTORIAL_STEP_3_DESCRIPTION = "TUTORIAL_STEP_3_DESCRIPTION";
export const TUTORIAL_CLOSE_ACTION = "TUTORIAL_CLOSE_ACTION";
export const TUTORIAL_NEXT_STEP_ACTION = "TUTORIAL_NEXT_STEP_ACTION";
export const TUTOTIAL_START_ACTION = "TUTOTIAL_START_ACTION";
export const UNKNOWN_PRODUCT = "UNKNOWN_PRODUCT";
export const PROMOTION_DISCOUNT = "PROMOTION_DISCOUNT";
export const PRODUCTS_AVAILABLE = "PRODUCTS_AVAILABLE";
export const IN_PROMOTION = "IN_PROMOTION";
export const SEE_PROMOTION = "SEE_PROMOTION";
export const TAGS_IN_PROMOTION = "TAGS_IN_PROMOTION";
export const MENU = "MENU";
export const MENU_LG = "MENU_LG";
export const FRIDGE_STATUS_FRIDGE = "FRIDGE_STATUS_FRIDGE";
export const OPEN_FRIDGE_WITH = "OPEN_FRIDGE_WITH";
export const PAY_WITH_CARD_BTN = "PAY_WITH_CARD_BTN";
export const PAY_WITH_CARD_BTN_2 = "PAY_WITH_CARD_BTN_2";
export const PAY_WITH_CARD_BTN_3 = "PAY_WITH_CARD_BTN_3";
export const PAY_WITH_APP_BTN = "PAY_WITH_APP_BTN";
export const PAY_WITH_APP_BTN_2 = "PAY_WITH_APP_BTN_2";
export const PAY_WITH_CARD = "PAY_WITH_CARD";
export const PAY_WITH_APP = "PAY_WITH_APP";
export const SCAN_QR_CODE = "SCAN_QR_CODE";
export const SCAN_QR_CODE_ALT = "SCAN_QR_CODE_ALT";
export const NAME_OF_FRIDGE = "NAME_OF_FRIDGE";
export const ACCEPTED_PAYMENT_METHODS = "ACCEPTED_PAYMENT_METHODS";
export const AUTHORIZATION_DISCLAIMER = "AUTHORIZATION_DISCLAIMER";
export const SWIPE_CARD = "SWIPE_CARD";
export const POS_PAYMENT_WAITING_AUTHORIZATION =
  "POS_PAYMENT_WAITING_AUTHORIZATION";
export const POS_PAYMENT_WAITING_AUTHORIZATION_DESC =
  "POS_PAYMENT_WAITING_AUTHORIZATION_DESC";
export const POS_PAYMENT_AUTHORIZATION_FAILURE =
  "POS_PAYMENT_AUTHORIZATION_FAILURE";
export const POS_PAYMENT_AUTHORIZATION_FAILURE_DESC =
  "POS_PAYMENT_AUTHORIZATION_FAILURE_DESC";
export const NFC_PAYMENT_AUTHORIZATION_FAILURE =
  "NFC_PAYMENT_AUTHORIZATION_FAILURE";
export const NFC_PAYMENT_AUTHORIZATION_FAILURE_DESC =
  "NFC_PAYMENT_AUTHORIZATION_FAILURE_DESC";
export const TAKE_A_SCREEN_PICTURE_LINE_1 = "TAKE_A_SCREEN_PICTURE_LINE_1";
export const TAKE_A_SCREEN_PICTURE_LINE_2 = "TAKE_A_SCREEN_PICTURE_LINE_2";
export const NOTIF_CLOSE_FRIDGE_TITLE = "NOTIF_CLOSE_FRIDGE_TITLE";
export const NOTIF_CLOSE_FRIDGE_DESC = "NOTIF_CLOSE_FRIDGE_DESC";
export const BILLING_ADDRESS = "BILLING_ADDRESS";
export const ADDRESS_LINE1 = "ADDRESS_LINE1";
export const ADDRESS_LINE2 = "ADDRESS_LINE2";
export const ADDRESS_POSTAL_CODE = "ADDRESS_POSTAL_CODE";
export const ADDRESS_CITY = "ADDRESS_CITY";
export const ADDRESS_COUNTRY = "ADDRESS_COUNTRY";
