/* eslint-disable max-len */
import React from "react";
import * as PropTypes from "prop-types";

const Delete = ({ className }) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4.17 8.17C4.26251 8.0773 4.3724 8.00375 4.49338 7.95357C4.61435 7.90339 4.74403 7.87756 4.875 7.87756C5.00597 7.87756 5.13565 7.90339 5.25662 7.95357C5.3776 8.00375 5.48749 8.0773 5.58 8.17L7 9.59L8.42 8.17C8.51258 8.07742 8.62249 8.00398 8.74346 7.95387C8.86442 7.90377 8.99407 7.87798 9.125 7.87798C9.25593 7.87798 9.38558 7.90377 9.50654 7.95387C9.62751 8.00398 9.73742 8.07742 9.83 8.17C9.92258 8.26258 9.99602 8.37249 10.0461 8.49346C10.0962 8.61442 10.122 8.74407 10.122 8.875C10.122 9.00593 10.0962 9.13558 10.0461 9.25654C9.99602 9.37751 9.92258 9.48742 9.83 9.58L8.41 11L9.83 12.42C9.92258 12.5126 9.99602 12.6225 10.0461 12.7435C10.0962 12.8644 10.122 12.9941 10.122 13.125C10.122 13.2559 10.0962 13.3856 10.0461 13.5065C9.99602 13.6275 9.92258 13.7374 9.83 13.83C9.73742 13.9226 9.62751 13.996 9.50654 14.0461C9.38558 14.0962 9.25593 14.122 9.125 14.122C8.99407 14.122 8.86442 14.0962 8.74346 14.0461C8.62249 13.996 8.51258 13.9226 8.42 13.83L7 12.41L5.58 13.83C5.48742 13.9226 5.37751 13.996 5.25654 14.0461C5.13558 14.0962 5.00593 14.122 4.875 14.122C4.74407 14.122 4.61442 14.0962 4.49346 14.0461C4.37249 13.996 4.26258 13.9226 4.17 13.83C4.07742 13.7374 4.00398 13.6275 3.95387 13.5065C3.90377 13.3856 3.87798 13.2559 3.87798 13.125C3.87798 12.9941 3.90377 12.8644 3.95387 12.7435C4.00398 12.6225 4.07742 12.5126 4.17 12.42L5.59 11L4.17 9.58C4.0773 9.48749 4.00375 9.3776 3.95357 9.25662C3.90339 9.13565 3.87756 9.00597 3.87756 8.875C3.87756 8.74403 3.90339 8.61435 3.95357 8.49338C4.00375 8.3724 4.0773 8.26251 4.17 8.17ZM10.5 1L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1H10.5Z"
      fill="none"
    />
  </svg>
);

Delete.propTypes = {
  className: PropTypes.string,
};

Delete.defaultProps = {
  className: null,
};

export default Delete;
