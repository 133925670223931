import React from "react";
import * as PropTypes from "prop-types";
import Button from "./Button";
import redirect from "../../../services/routing/redirect";

const ForgotPasswordButton = ({
  restaurateur: { slug },
  children,
  ...rest
}) => (
  <Button onClick={() => redirect(`/${slug}/forgot-password`)} {...rest}>
    {children}
  </Button>
);

ForgotPasswordButton.propTypes = {
  children: PropTypes.node.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default ForgotPasswordButton;
