import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Step1 } from "../../assets/step1.svg";
import { ReactComponent as Step2 } from "../../assets/step2.svg";
import { ReactComponent as Step3 } from "../../assets/step3.svg";
import Loader from "../../assets/fridge.gif";

import styles from "./doorsteps.module.scss";
import { useIsMedium } from "../hooks";

const DoorSteps = ({ step, children }) => {
  const isMedium = useIsMedium();

  return (
    <div className={styles.container}>
      <div className={styles.steps}>
        {(isMedium || step === 1) && (
          <Step1 className={step === 1 ? styles.active : null} />
        )}
        {(isMedium || step === 2) && (
          <Step2 className={step === 2 ? styles.active : null} />
        )}
        {isMedium && step !== 3 && (
          <Step3 className={step === 3 ? styles.active : null} />
        )}
        {isMedium && step === 3 && (
          <img className={styles.active} src={Loader} alt="fridge animation" />
        )}
      </div>
      {children && <div className={styles.body}>{children}</div>}
    </div>
  );
};

DoorSteps.propTypes = {
  step: PropTypes.number.isRequired,
  children: PropTypes.node,
};

DoorSteps.defaultProps = {
  children: null,
};

export default DoorSteps;
