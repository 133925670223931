import React from "react";
import PropTypes from "prop-types";
import BigText from "../common/BigText";
import { PAGE_NOT_FOUND } from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";

const NotFound = ({ translate }) => (
  <BigText>{translate(PAGE_NOT_FOUND)}</BigText>
);

NotFound.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translated(NotFound);
