import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { usePromise } from "../hooks";
import client from "../../services/client";
import {
  LOADING,
  ERROR,
  ENJOY_YOUR_MEAL,
  END_ACTION,
  DOOR_TIMED_OUT,
  SEND_BY_MAIL,
  DELIVERY_FINISHED,
  REMOVING_FINISHED,
  SEE_YOU_SOON,
  FINISHED,
  MAINTENANCE_FINISHED,
  TAKE_A_SCREEN_PICTURE_LINE_1,
  TAKE_A_SCREEN_PICTURE_LINE_2,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import Button from "../common/buttons/Button";
import BigText from "../common/BigText";
import Page, { PageContent } from "../common/Page";
import {
  BUY_TYPES,
  orderLinesToProductList,
  TYPE_BUY,
  TYPE_DELIVER,
  TYPE_MAINTENANCE,
  TYPE_NFC_BUY,
  TYPE_REMOVE,
} from "../../services/helpers/order";
import PromiseButton from "../common/buttons/PromiseButton";
import BottomContainer from "../common/BottomContainer";
import redirect from "../../services/routing/redirect";
import OrderDetails from "../common/OrderDetails";
import camera from "../../assets/camera.png";
import styles from "./order.module.css";
import ErrorPage from "../common/ErrorPage";

const Order = ({
  id,
  translate,
  restaurateur,
  fallbackSlug,
  interactions,
  refreshFridge,
}) => {
  // eslint-disable-next-line no-console
  console.log("render Order");
  const [loading, data, error] = usePromise(() => client.getOrder(id));

  useEffect(() => {
    if (interactions || (!data && !error)) {
      return;
    }

    const fridgeSlug = data?.fridgeSlug || fallbackSlug;
    let redirectTimeout = null;

    if (fridgeSlug) {
      refreshFridge();
      redirectTimeout = setTimeout(() => {
        redirect(`/${restaurateur.slug}/${fridgeSlug}/no-interaction`);
      }, 15000);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [
    interactions,
    data,
    restaurateur.slug,
    fallbackSlug,
    error,
    refreshFridge,
  ]);

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  const timedOut = Object.keys(data.status).includes("door_timed_out");
  const endAction = () =>
    redirect(
      `/${restaurateur.slug}/${data.fridgeSlug}${
        interactions ? "" : "/no-interaction"
      }`
    );

  if (timedOut) {
    return <ErrorPage message={DOOR_TIMED_OUT} />;
  }

  if (BUY_TYPES.includes(data.type) && data.lines.length === 0) {
    return (
      <Page>
        <PageContent>
          <BigText>{translate(SEE_YOU_SOON)}</BigText>
        </PageContent>
        <BottomContainer>
          <Button onClick={endAction} primary>
            {translate(END_ACTION)}
          </Button>
        </BottomContainer>
      </Page>
    );
  }

  if (data.type === TYPE_MAINTENANCE) {
    return (
      <Page>
        <PageContent>
          <BigText>{translate(MAINTENANCE_FINISHED)}</BigText>
        </PageContent>
        <BottomContainer>
          <Button onClick={endAction} primary>
            {translate(END_ACTION)}
          </Button>
        </BottomContainer>
      </Page>
    );
  }

  const title =
    {
      [TYPE_DELIVER]: translate(DELIVERY_FINISHED),
      [TYPE_BUY]: translate(ENJOY_YOUR_MEAL),
      [TYPE_NFC_BUY]: translate(ENJOY_YOUR_MEAL),
      [TYPE_REMOVE]: translate(REMOVING_FINISHED),
    }[data.type] || translate(FINISHED);
  const products = orderLinesToProductList(data.lines);

  return (
    <Page>
      <PageContent>
        <OrderDetails
          title={title}
          products={products}
          showTotal={BUY_TYPES.includes(data.type)}
          paymentInfo={data.paymentInfo}
        />
      </PageContent>
      {interactions ? (
        <BottomContainer>
          <Button onClick={endAction} primary>
            {translate(END_ACTION)}
          </Button>
          {BUY_TYPES.includes(data.type) && (
            <PromiseButton promise={() => client.sendOrderByMail(id)}>
              {translate(SEND_BY_MAIL)}
            </PromiseButton>
          )}
        </BottomContainer>
      ) : (
        <>
          <div className={styles["order-bill-tip"]}>
            <div>
              <img
                src={camera}
                alt="Camera"
                className={styles["order-camera"]}
              />
            </div>
            <span>
              {translate(TAKE_A_SCREEN_PICTURE_LINE_1)}
              <br />
              {translate(TAKE_A_SCREEN_PICTURE_LINE_2)}
            </span>
          </div>
          <BottomContainer>
            <Button onClick={endAction} primary>
              {translate(END_ACTION)}
            </Button>
          </BottomContainer>
        </>
      )}
    </Page>
  );
};

Order.propTypes = {
  id: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  interactions: PropTypes.bool.isRequired,
  fallbackSlug: PropTypes.string,
  refreshFridge: PropTypes.func.isRequired,
};

Order.defaultProps = {
  fallbackSlug: null,
};

const mapStateToProps = (state) => ({
  fallbackSlug: state.fridge.data?.slug || state.kiosk?.slug,
});

const mapDispatchToProps = (dispatch) => ({
  refreshFridge: dispatch.fridge.refresh,
});

export default connect(mapStateToProps, mapDispatchToProps)(translated(Order));
