import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import Button from "../common/buttons/Button";
import styles from "../common/buttons/button.module.scss";
import Loading from "../common/icons/Loading";

const SubmitButton = ({ formik: { isSubmitting }, children, ...rest }) => (
  <Button type="submit" disabled={isSubmitting} {...rest}>
    {children}
    {isSubmitting && (
      <Loading className={[styles.chevron, styles.loading].join(" ")} />
    )}
  </Button>
);

SubmitButton.propTypes = {
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.string.isRequired,
};

export default connect(SubmitButton);
