import React from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import * as PropTypes from "prop-types";
import styles from "./button.module.scss";
import Chevron from "../icons/Chevron";
import redirectToRoute from "../../../services/routing/redirect";

const BackButton = ({ redirectPath, color, redirect, ...rest }) => (
  <button
    type="button"
    className={styles["back-button"]}
    onClick={redirect(redirectPath)}
    style={color ? { color } : null}
    {...rest}
  >
    <Chevron className={styles.chevron} />
  </button>
);

BackButton.propTypes = {
  redirectPath: PropTypes.string,
  redirect: PropTypes.func.isRequired,
  color: PropTypes.string,
};

BackButton.defaultProps = {
  redirectPath: undefined,
  color: null,
};

const mapDispatchToProps = (dispatch) => ({
  redirect: (redirectPath) => () =>
    redirectPath ? redirectToRoute(redirectPath) : dispatch(goBack()),
});

export default connect(null, mapDispatchToProps)(BackButton);
