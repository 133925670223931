import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import "normalize.css";
import "./global.scss";
import App from "./components/App";
import { version, sentryDsn, sentryEnv } from "./config";

Sentry.init({
  dsn: sentryDsn,
  environment: sentryEnv,
  integrations: [
    new CaptureConsole({
      levels: ["error", "assert"],
    }),
  ],
  release: version,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}
