import React from "react";
import { differenceInSeconds, parseJSON } from "date-fns";

export const DELAY_DISPLAY_WAITING_AUTH = 30;
export const DELAY_DISPLAY_PAYMENT_REFUSED = 15;

function isPosCardPresented(context) {
  if (context?.details?.status !== "CardPresented") return false;
  return Boolean(
    differenceInSeconds(new Date(), parseJSON(context?.time)) <
      DELAY_DISPLAY_WAITING_AUTH
  );
}

function isPosAuthorisationFailure(details) {
  if (details?.status !== "AuthorizationFailure") return false;
  return (
    differenceInSeconds(new Date(), parseJSON(details.authorizationFailureAt)) <
    DELAY_DISPLAY_PAYMENT_REFUSED
  );
}

function isNfcAuthorisationFailure(fridge) {
  if (fridge?.lastOpeningErrorCode !== "sale_is_not_authorized") return false;

  return (
    differenceInSeconds(new Date(), parseJSON(fridge.lastOpeningErrorDate)) <
    DELAY_DISPLAY_PAYMENT_REFUSED
  );
}

export function usePaymentStatus(fridge) {
  const [paymentStatus, setPaymentStatus] = React.useState({
    nfcAuthorisationFailure: false,
    posAuthorisationFailure: false,
    posCardPresented: false,
  });

  React.useEffect(() => {
    const refresh = () => {
      const newStatus = {
        nfcAuthorisationFailure: isNfcAuthorisationFailure(fridge),
        posAuthorisationFailure: isPosAuthorisationFailure(
          fridge?.context?.details
        ),
        posCardPresented: isPosCardPresented(fridge?.context),
      };
      if (
        newStatus.nfcAuthorisationFailure !==
          paymentStatus.nfcAuthorisationFailure ||
        newStatus.posAuthorisationFailure !==
          paymentStatus.posAuthorisationFailure ||
        newStatus.posCardPresented !== paymentStatus.posCardPresented
      ) {
        setPaymentStatus(newStatus);
      }
    };
    refresh();
    const interval = setInterval(refresh, 1000);
    return () => clearInterval(interval);
  }, [
    fridge,
    paymentStatus.nfcAuthorisationFailure,
    paymentStatus.posAuthorisationFailure,
    paymentStatus.posCardPresented,
  ]);

  return paymentStatus;
}
