import { createPolyglotMiddleware } from "redux-polyglot";
import locales from "./locales";
import { CHANGE_LOCALE } from "./actionTypes";

const FALLBACK_LOCALE = "fr";

export default createPolyglotMiddleware(
  [CHANGE_LOCALE, "persist/REHYDRATE"],
  (action) => {
    const locale =
      (action.payload ? action.payload.locale : undefined) ||
      navigator.language.slice(0, 2);
    return Object.keys(locales).includes(locale) ? locale : FALLBACK_LOCALE;
  },
  (locale) => Promise.resolve(locales[locale])
);
