import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { usePromise } from "../hooks";
import client from "../../services/client";
import {
  ADD_PRODUCT_ACTION,
  ERROR,
  LOADING,
  OPEN_ACTION,
  OUTDATED_PRODUCTS_ACTION,
  WAIT_ACTION,
} from "../../services/i18n/messages";
import styles from "./fridge.module.scss";
import Button from "../common/buttons/Button";
import redirect from "../../services/routing/redirect";
import translated from "../../services/i18n/translated";
import BigText from "../common/BigText";
import { TYPE_MAINTENANCE } from "../../services/helpers/order";

const DelivererFridge = ({ fridge, translate, restaurateur, openDoor }) => {
  const [loading, outdated, error] = usePromise(() =>
    client.fridgeOffers(fridge.slug, { outdated: true })
  );

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  const outdatedProducts = outdated["hydra:member"].length;
  const canOpen = fridge.available && fridge.enabled && !fridge.opened;

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <Button
          onClick={() =>
            redirect(`/${restaurateur.slug}/${fridge.slug}/choose_product`)
          }
          primary
          disabled={!canOpen}
        >
          {translate(ADD_PRODUCT_ACTION)}
        </Button>
        <Button
          onClick={() =>
            redirect(`/${restaurateur.slug}/${fridge.slug}/outdated_products`)
          }
          error={outdatedProducts > 0}
        >
          {translate(OUTDATED_PRODUCTS_ACTION, {
            smart_count: outdatedProducts,
          })}
        </Button>
        <Button
          onClick={() => {
            openDoor({
              type: TYPE_MAINTENANCE,
              restaurateurSlug: restaurateur.slug,
              slug: fridge.slug,
            });
            redirect(`/${restaurateur.slug}/${fridge.slug}/open/maintenance`);
          }}
          primary
          disabled={!canOpen}
        >
          {canOpen && translate(OPEN_ACTION)}
          {!canOpen && translate(WAIT_ACTION)}
        </Button>
      </div>
    </div>
  );
};

DelivererFridge.propTypes = {
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    opened: PropTypes.bool.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  openDoor: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openDoor: dispatch.door.open,
});

export default connect(null, mapDispatchToProps)(translated(DelivererFridge));
