import React, { useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import client from "../../services/client";
import { usePromise } from "../hooks";
import Page, { PageContent } from "../common/Page";
import translated from "../../services/i18n/translated";
import BigText from "../common/BigText";
import redirect from "../../services/routing/redirect";
import Paper from "../common/Paper";
import {
  ADD_PAYMENT_CARD,
  ERROR,
  LOADING,
  FIRSTNAME,
  LASTNAME,
  CARD_HOLDER,
  CARD_HOLDER_SUBMIT,
  SECONDARY_CARD_CAN_BE_ADDED_LATER,
  BILLING_ADDRESS,
  ADDRESS_LINE1,
  ADDRESS_LINE2,
  ADDRESS_POSTAL_CODE,
  ADDRESS_CITY,
  ADDRESS_COUNTRY,
} from "../../services/i18n/messages";
import ListView from "../common/list/ListView";
import MasterCard from "../common/icons/MasterCard";
import CBCard from "../common/icons/CBCard";
import VisaCard from "../common/icons/VisaCard";
import Appetiz from "../common/icons/Appetiz";
import PassRestaurant from "../common/icons/PassRestaurant";
import UpDejeuner from "../common/icons/UpDejeuner";
import RestoFlash from "../common/icons/RestoFlash";
import Swile from "../common/icons/Swile";
import styles from "./cardsadd.module.scss";
import MinimalistButton from "../common/buttons/MinimalistButton";
import Form from "../form/Form";
import { TextInput } from "../form/Input";
import SubmitButton from "../form/SubmitButton";
import Edenred from "../common/icons/Edenred";
import { hasOneValidPrimaryCard } from "../../services/helpers/card";
import PaygreenAddInstrumentForm from "./PaygreenAddInstrumentForm";
import { restaurateur } from "../../services/models";

const PaymentMethodChoice = ({ paymentMethod }) => {
  return (
    <div className={styles.paymentType}>
      <span>{paymentMethod.name}</span>
      {paymentMethod.platform === "bank_card" && (
        <div>
          <MasterCard id={1} />
          <VisaCard id={2} />
          <CBCard id={3} />
        </div>
      )}
      {paymentMethod.platform === "EDENRED" && (
        <div>
          <Edenred />
        </div>
      )}
      {paymentMethod.platform === "conecs" && (
        <div>
          <Appetiz />
          <PassRestaurant />
          <UpDejeuner />
        </div>
      )}
      {paymentMethod.platform === "restoflash" && (
        <div>
          <RestoFlash />
        </div>
      )}
      {paymentMethod.platform === "swile" && (
        <div>
          <Swile />
        </div>
      )}
    </div>
  );
};

PaymentMethodChoice.propTypes = {
  paymentMethod: PropTypes.shape({
    platform: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const CardsAddPaygreen = ({
  translate,
  locale,
  restaurateur: { slug, appPaymentProviders },
  fridgeSlug,
}) => {
  const [pspError, setPspError] = useState();
  const [cards, setCards] = useState([]);
  const [defaultHolder, setDefaultHolder] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentConfiguration, setPaymentConfiguration] = useState();
  const [buyer, setBuyer] = useState();
  const [billingAddress, setBillingAddress] = useState();

  usePromise(
    () => client.cards(appPaymentProviders),
    (data) => {
      setCards(data["hydra:member"]);
      const holders = data["hydra:member"]
        .sort((card1, card2) => card1.id - card2.id)
        .filter((card) => card.holder)
        .map((card) => card.holder);
      if (holders.length) setDefaultHolder(holders[0]);
      else setDefaultHolder(null);
    }
  );

  const primaryCardMissing = !hasOneValidPrimaryCard(cards);

  const selectPaymentMethod = useCallback(
    (paymentMethod, holderInformations) => {
      setSelectedPaymentMethod(paymentMethod);

      if (!paymentMethod) return;

      if (!paymentMethod.holderInformationsRequired || holderInformations) {
        if (paymentMethod.platform === "EDENRED") {
          client.getEdenredAuthorizationUrl(slug, fridgeSlug).then((data) => {
            // eslint-disable-next-line no-console
            window.location.href = data;
          });
        } else if (holderInformations) {
          const payload = {
            firstname: holderInformations.firstname,
            lastname: holderInformations.lastname,
          };

          setPaymentConfiguration({});
          client.paygreenBuyer(payload).then((data) => {
            setBuyer(data);
          });
        } else {
          // eslint-disable-next-line no-param-reassign
          paymentMethod.holderInformationsRequired = true;
        }
      }
    },
    [fridgeSlug, slug]
  );

  const saveBillingAddress = useCallback(
    (address) => {
      const payload = {
        city: address.city,
        line1: address.line1,
        line2: address.line2 === "" ? null : address.line2,
        postalCode: address.postalCode,
        country: address.country,
      };

      client.saveBillingAddress(buyer, payload).then((data) => {
        setBillingAddress(payload);
      });
    },
    [buyer]
  );

  const [loading, , error] = usePromise(
    () => client.getPaygreenAvailablePaymentMethods(slug),
    (data) => setPaymentMethods(data)
  );

  useEffect(() => {
    if (!paymentMethods || paymentMethods.length === 0) {
      setPaymentConfiguration(null);
    } else if (paymentMethods && paymentMethods.length === 1) {
      selectPaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods, selectPaymentMethod, setPaymentConfiguration]);

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  if (pspError) {
    return <BigText>{translate(pspError.message)}</BigText>;
  }

  if (
    paymentConfiguration &&
    selectedPaymentMethod &&
    buyer &&
    billingAddress
  ) {
    return (
      <Page>
        <PageContent>
          <PaygreenAddInstrumentForm
            buyer={buyer}
            selectedPaymentMethod={selectedPaymentMethod}
            onSuccess={(data) => {
              if (data && data.success) {
                redirect(
                  data.pid
                    ? `/${slug}/cards/wait_for_card/${data.pid}`
                    : `/${slug}/cards/new`
                );
              } else {
                setPspError({
                  message: data && data.message ? data.message : ERROR,
                });
                setSelectedPaymentMethod(null);
              }
            }}
          />

          {primaryCardMissing && (
            <div className={styles.secondaryPaymentMethodsHint}>
              {translate(SECONDARY_CARD_CAN_BE_ADDED_LATER)}
            </div>
          )}
          {primaryCardMissing && (
            <div className={styles.secondaryPaymentMethods}>
              <Appetiz />
              <PassRestaurant />
              <UpDejeuner />
              <Edenred />
              <RestoFlash />
              <Swile />
            </div>
          )}
        </PageContent>
      </Page>
    );
  }

  if (buyer && !billingAddress) {
    return (
      <Page>
        <PageContent>
          <Form
            initialValues={{
              city: buyer.billing_address?.city ?? "",
              country: buyer.billing_address?.country ?? "",
              line1: buyer.billing_address?.line1 ?? "",
              line2: buyer.billing_address?.line2 ?? "",
              postalCode: buyer.billing_address?.postalCode ?? "",
            }}
            validationRules={{
              city: ["required"],
              country: ["required"],
              line1: ["required"],
              postalCode: ["required"],
            }}
            sendRequest={(data) => {
              saveBillingAddress(data);
            }}
            onSuccess={(data) => {}}
          >
            <Paper title={translate(CARD_HOLDER)} titleOutside transparent>
              <h4>{translate(BILLING_ADDRESS)}</h4>

              <TextInput
                name="line1"
                label={translate(ADDRESS_LINE1)}
                minlength={2}
                required
              />
              <TextInput
                name="line2"
                label={translate(ADDRESS_LINE2)}
                minlength={2}
              />
              <TextInput
                name="postalCode"
                label={translate(ADDRESS_POSTAL_CODE)}
                minlength={2}
                required
              />
              <TextInput
                name="city"
                label={translate(ADDRESS_CITY)}
                minlength={2}
                required
              />
              <TextInput
                name="country"
                label={translate(ADDRESS_COUNTRY)}
                minlength={2}
                required
              />

              <SubmitButton primary>
                {translate(CARD_HOLDER_SUBMIT)}
              </SubmitButton>
            </Paper>
          </Form>
        </PageContent>
      </Page>
    );
  }

  if (
    selectedPaymentMethod &&
    selectedPaymentMethod.holderInformationsRequired
  ) {
    return (
      <Page>
        <PageContent>
          <Form
            initialValues={{
              firstname: defaultHolder ? defaultHolder.first_name : "",
              lastname: defaultHolder ? defaultHolder.last_name : "",
            }}
            validationRules={{
              firstname: ["required"],
              lastname: ["required"],
            }}
            sendRequest={({ firstname, lastname }) =>
              selectPaymentMethod(selectedPaymentMethod, {
                firstname,
                lastname,
              })
            }
            onSuccess={(data) => {}}
          >
            <Paper title={translate(CARD_HOLDER)} titleOutside transparent>
              <TextInput
                name="firstname"
                label={translate(FIRSTNAME)}
                minlength={2}
                required
              />
              <TextInput
                name="lastname"
                label={translate(LASTNAME)}
                minlength={2}
                required
              />
              <SubmitButton primary>
                {translate(CARD_HOLDER_SUBMIT)}
              </SubmitButton>
            </Paper>
          </Form>
        </PageContent>
      </Page>
    );
  }

  if (paymentMethods.length === 1) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (paymentMethods.length > 1) {
    return (
      <Page>
        <PageContent>
          <Paper title={translate(ADD_PAYMENT_CARD)} titleOutside transparent>
            <ListView>
              {paymentMethods.map((paymentMethod) => (
                <MinimalistButton
                  onClick={() => selectPaymentMethod(paymentMethod)}
                  key={paymentMethod.platform}
                >
                  <PaymentMethodChoice paymentMethod={paymentMethod} />
                </MinimalistButton>
              ))}
            </ListView>
          </Paper>
        </PageContent>
      </Page>
    );
  }

  return <BigText>{translate(ERROR)}</BigText>;
};

CardsAddPaygreen.propTypes = {
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    appPaymentProviders: PropTypes.array.isRequired,
  }).isRequired,
  fridgeSlug: PropTypes.string,
};

CardsAddPaygreen.defaultProps = {
  fridgeSlug: null,
};

const mapStateToProps = (state) => ({
  fridgeSlug: state.fridge.slug,
});

export default connect(mapStateToProps)(translated(CardsAddPaygreen));
