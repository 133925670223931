import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Page, { PageContent } from "./Page";
import BigText from "./BigText";
import { BACK, ERROR } from "../../services/i18n/messages";
import BottomContainer from "./BottomContainer";
import Button from "./buttons/Button";
import translated from "../../services/i18n/translated";
import redirect from "../../services/routing/redirect";

const ErrorPage = ({ translate, message, returnUrl }) => {
  return (
    <Page>
      <PageContent>
        <BigText>{translate(message)}</BigText>
      </PageContent>
      <BottomContainer>
        <Button onClick={() => redirect(returnUrl)} primary>
          {translate(BACK)}
        </Button>
      </BottomContainer>
    </Page>
  );
};

ErrorPage.propTypes = {
  translate: PropTypes.func.isRequired,
  returnUrl: PropTypes.string.isRequired,
  message: PropTypes.string,
};

ErrorPage.defaultProps = {
  message: ERROR,
};

const mapStateToProps = (state) => {
  let returnUrl = null;
  if (state.kiosk.slug && state.kiosk.restaurateurSlug) {
    returnUrl = `/${state.kiosk.restaurateurSlug}/${state.kiosk.slug}/no-interaction`;
  } else if (state.fridge.data && state.restaurateur.data) {
    returnUrl = `/${state.restaurateur.data.slug}/${state.fridge.data.slug}`;
  } else if (state.restaurateur.data) {
    returnUrl = `/${state.restaurateur.data.slug}`;
  } else {
    returnUrl = "/";
  }

  return {
    returnUrl,
  };
};

export default connect(mapStateToProps)(translated(ErrorPage));
