import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { usePromise } from "../hooks";
import client from "../../services/client";
import BigText from "../common/BigText";
import {
  CARD_ADD,
  ERROR,
  FRIDGE_OFFERS,
  LOADING,
  NO_OFFERS,
  OPEN_ACTION,
  WAIT_ACTION,
} from "../../services/i18n/messages";
import { hasOneValidPrimaryCard } from "../../services/helpers/card";
import { offersToProductList } from "../../services/helpers/offer";
import styles from "./fridge.module.scss";
import Button from "../common/buttons/Button";
import redirect from "../../services/routing/redirect";
import ProductList from "../common/ProductList";
import translated from "../../services/i18n/translated";
import ProductPopup from "../fridge-popup/ProductPopup";
import { TYPE_BUY } from "../../services/helpers/order";

const ClientFridge = ({
  translate,
  restaurateur,
  fridge,
  loading,
  error,
  products,
  openDoor,
}) => {
  const [loadingCards, cards, errorCards] = usePromise(() =>
    client.cards(restaurateur.appPaymentProviders)
  );
  const [selectedProduct, setSelectedProduct] = useState();

  if (loading || loadingCards) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error || errorCards) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  const hasValidCards = hasOneValidPrimaryCard(cards["hydra:member"]);
  const ref = React.createRef();
  const canOpen = fridge.available && fridge.enabled && !fridge.opened;

  return (
    <>
      {selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
      <div className={styles.container}>
        <div className={styles.buttons}>
          {!hasValidCards ? (
            <Button
              onClick={() => redirect(`/${restaurateur.slug}/cards/add`)}
              primary
            >
              {translate(CARD_ADD)}
            </Button>
          ) : (
            <Button
              ref={ref}
              onClick={() => {
                openDoor({
                  type: TYPE_BUY,
                  restaurateurSlug: restaurateur.slug,
                  slug: fridge.slug,
                });
                redirect(`/${restaurateur.slug}/${fridge.slug}/open`);
              }}
              primary
              disabled={!canOpen || products.length === 0}
            >
              {products.length === 0 && translate(NO_OFFERS)}
              {products.length > 0 && !canOpen && translate(WAIT_ACTION)}
              {products.length > 0 && canOpen && translate(OPEN_ACTION)}
            </Button>
          )}
        </div>
      </div>
      {products.length > 0 && (
        <ProductList
          title={translate(FRIDGE_OFFERS)}
          products={products}
          onProductClick={(product) => {
            setSelectedProduct(product);
            if (!ref.current) {
              return;
            }
            ref.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }}
        />
      )}
    </>
  );
};

ClientFridge.propTypes = {
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    opened: PropTypes.bool.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    appPaymentProviders: PropTypes.array,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  openDoor: PropTypes.func.isRequired,
};

ClientFridge.defaultProps = {
  products: [],
  loading: false,
  error: null,
};

const mapStateToProps = (state) => ({
  products: state.offers.data
    ? offersToProductList(state.offers.data["hydra:member"])
    : [],
  loading: state.offers.loading,
  error: state.offers.error,
});

const mapDispatchToProps = (dispatch) => ({
  openDoor: dispatch.door.open,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translated(ClientFridge));
