import React from "react";
import * as PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import configure from "../../services/routing/configure";
import FridgeForm from "../fridge-form/FridgeForm";
import RegisterForm from "../register/RegisterForm";
import LoginForm from "../login/LoginForm";
import ForgotPasswordStep1 from "../forgot-password/ForgotPasswordStep1";
import ForgotPasswordStep2 from "../forgot-password/ForgotPasswordStep2";
import HandleToken from "../mail-token/HandleToken";
import Order from "../order/Order";
import Purchases from "../purchases/Purchases";
import Purchase from "../purchases/Purchase";
import CardsAdd from "../cards/CardsAdd";
import CardsList from "../cards/CardsList";
import CardsDelete from "../cards/CardsDelete";
import Langs from "../langs/Langs";
import FridgeRedirect from "../fridge-redirect/FridgeRedirect";
import FridgeRouter from "./FridgeRouter";
import RecentFridges from "../recent-fridges/RecentFridges";
import ActivateUser from "../activate-user/ActivateUser";
import {
  CurrentOrderWaitForDoor,
  SpecificOrderWaitForDoor,
} from "../wait-for-door/WaitForDoor";
import WaitForCard from "../cards/WaitForCard";

const RestaurateurRoutes = ({ restaurateur }) => (
  <Switch>
    <Route
      exact
      path={`/${restaurateur.slug}`}
      component={configure(FridgeForm, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/register`}
      component={configure(RegisterForm, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/login`}
      component={configure(LoginForm, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/fridge`}
      component={configure(FridgeRedirect, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/recent-fridges`}
      component={configure(RecentFridges, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/forgot-password`}
      component={configure(ForgotPasswordStep1, {
        properties: { restaurateur },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/forgot-password/:token`}
      component={configure(ForgotPasswordStep2, {
        properties: { restaurateur },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/handle-token/:token`}
      component={configure(HandleToken, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/activate-user/:token`}
      component={configure(ActivateUser, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/orders/:id/details`}
      component={configure(Order, {
        properties: { restaurateur, interactions: true },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/orders/:id/details/no-interaction`}
      component={configure(Order, {
        menuButton: false,
        properties: { restaurateur, interactions: false },
        footerLink: false,
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/purchases`}
      component={configure(Purchases, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/purchases/:id/details`}
      component={configure(Purchase, {
        backButton: true,
        properties: { restaurateur },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/cards/add`}
      component={configure(CardsAdd, {
        backButton: true,
        backPath: `/${restaurateur.slug}/cards`,
        properties: { restaurateur },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/cards`}
      component={configure(CardsList, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/cards/new`}
      component={configure(CardsList, {
        properties: { restaurateur, newCard: true },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/cards/error`}
      component={configure(CardsList, {
        properties: { restaurateur, newCardError: true },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/cards/wait_for_card/:newCardToken`}
      component={configure(WaitForCard, {
        properties: {
          restaurateur,
          redirectPath: `/${restaurateur.slug}/cards/new`,
        },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/cards/delete/:cardId`}
      component={configure(CardsDelete, {
        backButton: true,
        backPath: `/${restaurateur.slug}/cards`,
        properties: { restaurateur },
      })}
    />
    <Route
      exact
      strict
      path={`/${restaurateur.slug}/langs`}
      component={configure(Langs, { properties: { restaurateur } })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/:slug/orders/current/wait_for_door`}
      component={configure(CurrentOrderWaitForDoor, {
        menuButton: false,
        properties: { restaurateur, interactions: true },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/:slug/orders/current/wait_for_door/no-interaction`}
      component={configure(CurrentOrderWaitForDoor, {
        menuButton: false,
        properties: { restaurateur, interactions: false },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/:slug/orders/:id/wait_for_door`}
      component={configure(SpecificOrderWaitForDoor, {
        properties: { restaurateur, interactions: true },
      })}
    />
    <Route
      exact
      path={`/${restaurateur.slug}/:slug/no-interaction`}
      component={configure(FridgeRouter, {
        menuButton: false,
        properties: { restaurateur, noInteraction: true },
        footerLink: false,
      })}
    />
    <Route
      path={`/${restaurateur.slug}/:slug`}
      component={configure(FridgeRouter, {
        properties: { restaurateur },
      })}
    />
  </Switch>
);

RestaurateurRoutes.propTypes = {
  restaurateur: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    appPaymentProvider: PropTypes.string,
  }).isRequired,
};

export default RestaurateurRoutes;
