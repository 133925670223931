import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as NutriScoreA } from "../../assets/Nutri-score-A.svg";
import { ReactComponent as NutriScoreB } from "../../assets/Nutri-score-B.svg";
import { ReactComponent as NutriScoreC } from "../../assets/Nutri-score-C.svg";
import { ReactComponent as NutriScoreD } from "../../assets/Nutri-score-D.svg";
import { ReactComponent as NutriScoreE } from "../../assets/Nutri-score-E.svg";
import styles from "./nutriscore.module.scss";

const NutriScore = ({ nutriScore }) => {
  return (
    <div className={styles.container}>
      {nutriScore === "A" && <NutriScoreA />}
      {nutriScore === "B" && <NutriScoreB />}
      {nutriScore === "C" && <NutriScoreC />}
      {nutriScore === "D" && <NutriScoreD />}
      {nutriScore === "E" && <NutriScoreE />}
    </div>
  );
};

NutriScore.propTypes = {
  nutriScore: PropTypes.string.isRequired,
};

export default NutriScore;
