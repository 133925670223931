import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import createValidator from "./createValidator";

const onSubmit = (sendRequest, onSuccess) => (
  values,
  { setSubmitting, setErrors }
) => {
  setSubmitting(true);
  sendRequest(values)
    .then((response) => {
      setSubmitting(false);
      onSuccess(response);
    })
    .catch((error) => {
      setSubmitting(false);
      setErrors(error.violations || {});
    });
};

const Form = ({
  children,
  initialValues,
  validationRules,
  sendRequest,
  onSuccess,
  className,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    validate={createValidator(validationRules)}
    onSubmit={onSubmit(sendRequest, onSuccess)}
  >
    {({ handleSubmit }) => (
      <form
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        className={className}
      >
        {children}
      </form>
    )}
  </Formik>
);

Form.propTypes = {
  initialValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  validationRules: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  sendRequest: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Form.defaultProps = {
  className: undefined,
};

export default Form;
