import React from "react";
import * as PropTypes from "prop-types";
import Page, { PageContent } from "../common/Page";
import { usePromise } from "../hooks";
import client from "../../services/client";
import { ERROR, LOADING } from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import { orderLinesToProductList } from "../../services/helpers/order";
import { date } from "../../services/formatters";
import BigText from "../common/BigText";
import OrderDetails from "../common/OrderDetails";

const Purchase = ({ id, translate, locale }) => {
  const [loading, order, error] = usePromise(() => client.getOrder(id));

  if (loading) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  const products = orderLinesToProductList(order.lines);
  const dateFormatter = date(locale);

  return (
    <Page>
      <PageContent>
        <OrderDetails
          title={`${order.fridgeName}, ${dateFormatter.format(
            new Date(order.date)
          )}`}
          products={products}
          paymentInfo={order.paymentInfo}
          showTotal
        />
      </PageContent>
    </Page>
  );
};

Purchase.propTypes = {
  id: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default translated(Purchase);
